import { NationFlag, extractAddress } from '@strise/app-shared'
import { IconBirthdayCake, Typography } from '@strise/ui-components'
import type { ReactNode } from 'react'
import type { PersonBaseFragment } from '~/graphqlTypes'

interface PersonReviewInfoProps {
  person: PersonBaseFragment
}

const extractBirthDate = (person: PersonBaseFragment): string | null => {
  return person.birthDateV2
}

export const PersonReviewInfo = ({ person }: PersonReviewInfoProps): ReactNode => {
  const birthDate = extractBirthDate(person)

  return (
    <Typography variant='aLabelSmall' className='flex items-center gap-2 text-text-secondary'>
      {person.address && (
        <span className='flex items-center gap-2'>
          <NationFlag className='size-4' countryIsoCode={person.primaryCountry?.isoAlpha2Code} />
          {extractAddress(person.address)}
        </span>
      )}

      {birthDate && (
        <span className='flex items-center gap-2 whitespace-nowrap'>
          <IconBirthdayCake size='md' className='shrink-0' />
          {birthDate}
        </span>
      )}
    </Typography>
  )
}
