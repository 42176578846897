import { t } from '@lingui/macro'
import { NationFlag, extractAddress, hasAddressValues } from '@strise/app-shared'
import { DataTable, Divider, cn, createColumnHelper } from '@strise/ui-components'
import { Fragment, type ReactNode, useMemo } from 'react'
import { CopyButton } from '~/components/CopyButton'
import { EntityLink } from '~/components/EntityLink/EntityLink'
import { DataSources } from '~/components/Sidepanel/DataSources'
import { SidepanelCard } from '~/components/Sidepanel/SidepanelCard'
import { TruncatedList } from '~/components/TruncatedList/TruncatedList'
import { type SidepanelPersonFragment } from '~/graphqlTypes'

const columnHelper = createColumnHelper<SidepanelPersonFragment>()

const extractColumns = () => [
  columnHelper.accessor((person) => person.personalNumber, {
    id: 'personalNumber',
    header: t`Personal number`,
    cell: ({ getValue }) => {
      const personalNumber = getValue()
      if (!personalNumber) return null
      return (
        <div className='flex justify-between items-center'>
          <span>{personalNumber}</span>
          <CopyButton label='Personal number' value={personalNumber} />
        </div>
      )
    }
  }),
  columnHelper.accessor((person) => person.nationality, {
    id: 'nationality',
    header: t`Nationality`,
    cell: ({ getValue }) =>
      getValue().map((country) => (
        <div key={country.id}>
          <NationFlag countryIsoCode={country.isoAlpha2Code} />
          <span>{country.countryName}</span>
        </div>
      ))
  }),
  columnHelper.accessor((person) => person.citizenships, {
    id: 'citizenships',
    header: t`Citizenship`,
    cell: ({ getValue }) =>
      getValue().map((country) => (
        <div key={country.id}>
          <NationFlag countryIsoCode={country.isoAlpha2Code} />
          <span>{country.countryName}</span>
        </div>
      ))
  }),
  columnHelper.accessor((person) => person.residence, {
    id: 'residence',
    header: t`Residence`,
    cell: ({ getValue }) =>
      getValue().map((country) => (
        <div key={country.id}>
          <NationFlag countryIsoCode={country.isoAlpha2Code} />
          <span>{country.countryName}</span>
        </div>
      ))
  }),
  columnHelper.accessor((company) => company.address, {
    id: 'address',
    header: t`Address`,
    cell: ({ getValue }) => {
      const address = getValue()
      if (!address) return '-'
      return extractAddress(address)
    }
  }),
  columnHelper.accessor((person) => person.industries, {
    id: 'industries',
    header: t`Industries`,
    cell: ({ getValue }) => (
      <TruncatedList
        className='px-0'
        context='Person industries'
        items={getValue().map((name) => ({ name }))}
        truncateAfter={3}
      >
        {(item, index, isLast) => (
          <Fragment key={item.name}>
            <div className={cn('py-3', isLast && 'pt-3 pb-2', index === 0 && 'pt-0')}>{item.name}</div>
            {!isLast && <Divider />}
          </Fragment>
        )}
      </TruncatedList>
    ),
    meta: {
      cellClassName: 'pb-0'
    }
  }),
  columnHelper.accessor((person) => person.relatedPersons, {
    id: 'relatedPersons',
    header: t`Related persons`,
    cell: ({ getValue }) => (
      <TruncatedList className='px-0' context='Related persons' items={getValue().edges} truncateAfter={3}>
        {({ companies, node }, index, isLast) => (
          <Fragment key={node.id}>
            <div
              className={cn('py-3 grid grid-cols-2 gap-2 items-start', isLast && 'pt-3 pb-2', index === 0 && 'pt-0')}
            >
              <EntityLink entity={node} />
              <div className='flex flex-col gap-2'>
                {companies.edges.map(({ node: company }, companyIndex) => (
                  <Fragment key={company.id}>
                    <EntityLink entity={company} />
                    {companyIndex !== companies.edges.length - 1 && <Divider />}
                  </Fragment>
                ))}
              </div>
            </div>
            {!isLast && <Divider />}
          </Fragment>
        )}
      </TruncatedList>
    ),
    meta: {
      cellClassName: 'pb-0'
    }
  })
]

export const PersonDetailsCard = ({ person }: { person: SidepanelPersonFragment }): ReactNode => {
  const columns = useMemo(() => extractColumns(), [person])

  const columnVisibility = {
    personalNumber: !!person.personalNumber,
    nationality: !!person.nationality.length,
    citizenships: !!person.citizenships.length,
    residence: !!person.residence.length,
    address: !!person.address && hasAddressValues(person.address),
    industries: !!person.industries.length,
    relatedPersons: !!person.relatedPersons.edges.length
  }

  return (
    <SidepanelCard title={t`Person information`}>
      <div className='px-4'>
        <DataTable
          columns={columns}
          data={[person]}
          orientation='vertical'
          className='table-fixed w-full'
          headerCellClassName='text-text-secondary text-md !font-book !px-0 w-1/3 max-w-1/3 p-3'
          bodyCellClassName='text-md font-book w-2/3 max-w-2/3 p-3'
          getRowClassName={() => 'border-divider hover:bg-white'}
          options={{
            state: {
              columnVisibility
            }
          }}
        />
      </div>
      <DataSources dataSources={person.dataSources.baseInfo} />
    </SidepanelCard>
  )
}
