import { t } from '@lingui/macro'
import { type ButtonProps, IconButton, IconCopy, cn } from '@strise/ui-components'
import { copyString } from '~/utils/hooks'

export const CopyButton = ({ className, label, value, ...props }: { label: string; value: string } & ButtonProps) => {
  return (
    <IconButton
      className={cn('text-text-secondary', className)}
      variant='ghost'
      palette='tertiary'
      onClick={copyString(value)}
      aria-label={`${t`Copy`} ${label}`}
      data-track={`Copy ${label}`}
      {...props}
    >
      <IconCopy />
    </IconButton>
  )
}
