import { t } from '@lingui/macro'
import { Checkbox, Skeleton, Typography } from '@strise/ui-components'
import { type ReactNode } from 'react'
import { type RegistryDocumentType } from '~/components/RegistryCheck/RegistryCheckDialog'
import { TruncatedList } from '~/components/TruncatedList/TruncatedList'

const DocumentDownloadSection = ({
  registryDocuments,
  registryDocumentsLoading,
  selectedDocuments,
  setSelectedDocuments
}: {
  registryDocuments: RegistryDocumentType[]
  registryDocumentsLoading: boolean
  selectedDocuments: RegistryDocumentType[]
  setSelectedDocuments: (documents: RegistryDocumentType[]) => void
}): ReactNode => {
  return (
    <div className='bg-white rounded-sm p-4'>
      <Typography variant='subtitle2' className='pb-4'>{t`Request documentation`}</Typography>
      {registryDocumentsLoading ? (
        <div className='flex flex-col gap-2'>
          <Skeleton className='h-8 w-full' />
          <Skeleton className='h-8 w-full' />
          <Skeleton className='h-8 w-full' />
          <Skeleton className='h-8 w-full' />
        </div>
      ) : (
        <TruncatedList items={registryDocuments} truncateAfter={10} context='Registry Check / Select Document'>
          {(document) => (
            <Checkbox
              key={document.id}
              id={document.id}
              label={document.name}
              palette='secondary'
              checked={selectedDocuments.some((doc) => doc.id === document.id)}
              onCheckedChange={(checked) => {
                setSelectedDocuments(
                  checked ? [...selectedDocuments, document] : selectedDocuments.filter((doc) => doc.id !== document.id)
                )
              }}
              labelProps={{ className: 'text-sm' }}
              containerProps={{ className: 'pb-1' }}
              truncateLabel
              data-track={`Registry Check / Select Document / ${document.id}`}
            />
          )}
        </TruncatedList>
      )}
    </div>
  )
}

export default DocumentDownloadSection
