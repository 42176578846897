import { uuid } from '@jsplumb/browser-ui'
import { t } from '@lingui/macro'
import { toast, useCurrentUser } from '@strise/app-shared'
import { type SetStateFn } from '@strise/react-utils'
import { type CreateEntityDispositionInput, EntityDispositionStatusKind, ReviewSectionKind } from '@strise/types'
import { Button, DialogFooter, cn } from '@strise/ui-components'
import type { ReactNode } from 'react'
import { useState } from 'react'
import { PepDispositionReset } from '~/features/PepAndSanctions/PepDispositionReset'
import { dialogBgColor } from '~/features/PepAndSanctions/dispositionDialogUtils'
import { useCreateEntityDisposition, useDeletePepDispositions } from '~/features/PepAndSanctions/pepDispositionUtils'
import { type PepInfoFragment, type PersonBaseFragment, type PrivatePersonBaseFragment } from '~/graphqlTypes'
import { refreshReviewSections } from '~/state'

export const PepDispositionDialogActions = ({
  extractInputs,
  onCompleted,
  pepInfos,
  person,
  setIsAuditTrailOpen
}: {
  extractInputs: (status: EntityDispositionStatusKind) => CreateEntityDispositionInput[]
  onCompleted: () => void
  pepInfos: PepInfoFragment[]
  person: PersonBaseFragment | PrivatePersonBaseFragment
  setIsAuditTrailOpen: SetStateFn<boolean>
}): ReactNode => {
  const currentUser = useCurrentUser()
  const [statusLoading, setStatusLoading] = useState<EntityDispositionStatusKind | null>(null)

  const handleUpdateReviewCache = () => {
    const currentNonce = refreshReviewSections()[ReviewSectionKind.PepsAndSanctions] ?? 0
    refreshReviewSections({ [ReviewSectionKind.PepsAndSanctions]: currentNonce + 1 })
  }

  const handleCompleted = (): void => {
    onCompleted()
    setStatusLoading(null)
    handleUpdateReviewCache()
    toast.success(t`PEP verfication saved`)
  }

  const { createDisposition } = useCreateEntityDisposition(handleCompleted)

  const { deleteLoading, handleDelete } = useDeletePepDispositions({ person })

  const handleSave = (status: EntityDispositionStatusKind) => async (): Promise<void> => {
    const inputs = extractInputs(status)

    if (!inputs.length) {
      toast.error(t`No PEPs selected`)
      return
    }

    setStatusLoading(status)

    const nextPepInfos: PepInfoFragment[] = pepInfos.map((pepInfo) => {
      const matchingInput = inputs.find((input) => input.externalId === pepInfo.id)

      return {
        ...pepInfo,
        disposition: matchingInput
          ? {
              id: uuid(),
              status: matchingInput.status,
              createdBy: currentUser,
              created: new Date().toISOString(),
              comment: matchingInput.comment ?? null,
              __typename: 'EntityDisposition'
            }
          : null
      }
    })

    await createDisposition({ inputs, nextPepInfos, person })

    // TODO: Company network cache update
    // TODO: Risk summary cache update
  }

  const disabled = !!statusLoading || deleteLoading

  return (
    <DialogFooter>
      <div className={cn('flex w-full gap-4 items-center', dialogBgColor)}>
        <PepDispositionReset onDelete={handleDelete} deleteLoading={deleteLoading} disabled={disabled} />
        <Button
          variant='ghost'
          onClick={() => setIsAuditTrailOpen(true)}
          disabled={disabled}
          data-track='Pep disposition dialog / Open audit trail'
        >
          {t`See audit trail`}
        </Button>
        <Button
          className='ml-auto'
          variant='contained'
          palette='primary'
          onClick={handleSave(EntityDispositionStatusKind.ConfirmedTrue)}
          loading={statusLoading === EntityDispositionStatusKind.ConfirmedTrue}
          disabled={disabled}
          data-track='Pep disposition dialog / Confirm PEP status'
        >
          {t`Confirm PEP status`}
        </Button>
        <Button
          variant='contained'
          palette='secondary'
          onClick={handleSave(EntityDispositionStatusKind.ConfirmedFalse)}
          loading={statusLoading === EntityDispositionStatusKind.ConfirmedFalse}
          disabled={disabled}
          data-track='Pep disposition dialog / Mark as false positive(s)'
        >
          {t`Mark as false positive(s)`}
        </Button>
      </div>
    </DialogFooter>
  )
}
