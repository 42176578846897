import { t } from '@lingui/macro'
import { NationFlag, formatDate } from '@strise/app-shared'
import {
  Button,
  Checkbox,
  DataTable,
  IconArrowDown,
  IconButton,
  IconGarbage,
  IconPenBox,
  IconPerson,
  Popover,
  Typography,
  createColumnHelper
} from '@strise/ui-components'
import { useCallback, useMemo, useState } from 'react'
import { useDebounceValue } from 'usehooks-ts'
import { EntityLink } from '~/components/EntityLink/EntityLink'
import { SubHeaderStringFilter } from '~/components/Filter/SubHeaderStringFilter'
import { DeletePrivatePersonDialog } from '~/features/PrivatePersons/DeletePrivatePersonDialog'
import { CreatePrivatePersonDialog } from '~/features/PrivatePersons/PrivatePersonForm/CreatePrivatePersonDialog'
import { UpdatePrivatePersonDialog } from '~/features/PrivatePersons/PrivatePersonForm/UpdatePrivatePersonDialog'
import { AuditRiskChip } from '~/features/Risk/AuditRiskChip'
import { CustomRiskLevelChip } from '~/features/Risk/CustomRiskLevelChip'
import { usePrivatePersonSearchQuery } from '~/graphqlOperations'
import { type PrivatePersonBaseFragment, type ReviewPrivatePersonFragment } from '~/graphqlTypes'

const columnHelper = createColumnHelper<ReviewPrivatePersonFragment>()

/**
 * Helper function to create table columns for private persons
 * @param privatePersons - Array of private person data
 * @param handleOpenDeleteDialog - Callback function to handle person deletion
 * @returns Array of column definitions
 */
const extractColumns = (
  handleOpenDeleteDialog: (person: PrivatePersonBaseFragment) => void,
  handleOpenUpdateDialog: (person: PrivatePersonBaseFragment) => void
) => {
  return [
    columnHelper.display({
      id: 'icon',
      cell: () => (
        <div className='flex items-center justify-center'>
          <IconPerson size='lg' />
        </div>
      )
    }),
    columnHelper.accessor((info) => info.name, {
      id: 'name',
      header: t`Name`,
      cell: ({ row }) => <EntityLink entity={row.original} noFlags />
    }),
    columnHelper.accessor((info) => info.birthDate, {
      id: 'birthDate',
      header: t`Date of birth`,
      cell: ({ row }) => {
        const date = row.original.birthDate
        return date ? formatDate(date) : (row.original.birthYear ?? '-')
      }
    }),
    columnHelper.accessor((info) => info.addresses, {
      id: 'location',
      header: t`Located in`,
      cell: ({ getValue }) => {
        const filteredAddresses = getValue().filter(({ city, countryV2 }) => city && countryV2)
        if (!filteredAddresses.length) return '-'
        return filteredAddresses.map(({ city, countryV2 }) => (
          <div className='flex items-center' key={countryV2?.isoAlpha2Code}>
            <NationFlag countryIsoCode={countryV2?.isoAlpha2Code} />
            {city && <Typography className='ml-2'>{city}</Typography>}
          </div>
        ))
      }
    }),
    columnHelper.accessor((info) => info.citizenship, {
      id: 'citizenship',
      header: t`Citizenship`,
      cell: ({ getValue }) => {
        const country = getValue()
        if (!country) return '-'
        return (
          <div className='flex items-center'>
            <NationFlag countryIsoCode={country.isoAlpha2Code} />
            <Typography className='ml-2'>{country.englishName}</Typography>
          </div>
        )
      }
    }),
    columnHelper.accessor((info) => info.reviews, {
      id: 'risk',
      header: t`Risk assessment`,
      cell: ({ getValue }) => {
        const lastReview = getValue().at(0)
        if (!lastReview) return '-'
        return lastReview.customRiskLevel ? (
          <CustomRiskLevelChip customRiskLevel={lastReview.customRiskLevel} />
        ) : (
          <AuditRiskChip risk={lastReview.risk} />
        )
      }
    }),
    columnHelper.accessor((info) => info.createdAt, {
      id: 'createdAt',
      header: () => (
        <div className='flex items-center'>
          {t`Date added`}
          <IconArrowDown size='xl' className='ml-2' />
        </div>
      ),
      cell: ({ getValue }) => {
        const date = getValue()
        return date ? formatDate(date, { time: true }) : '-'
      }
    }),
    columnHelper.display({
      id: 'actions',
      header: t`Actions`,
      cell: ({ row }) => (
        <div className='flex gap-2'>
          <IconButton
            title={t`Edit private person`}
            data-track='Private person portfolio / Edit private person'
            onClick={() => handleOpenUpdateDialog(row.original)}
          >
            <IconPenBox size='md' />
          </IconButton>
          <IconButton
            title={t`Delete private person`}
            onClick={() => handleOpenDeleteDialog(row.original)}
            data-track='Private person portfolio / Delete private person / Open dialog'
          >
            <IconGarbage size='md' />
          </IconButton>
        </div>
      )
    })
  ]
}

const initialVisibility = {
  icon: true,
  name: true,
  birthDate: true,
  location: true,
  citizenship: true,
  risk: true,
  actions: true
}

export const PortfolioPrivatePersonView = () => {
  // State for table pagination
  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const offset = pageIndex * pageSize

  // State for column management
  const [columnOrder, setColumnOrder] = useState<string[]>([])

  // State for delete dialog
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [personToDelete, setPersonToDelete] = useState<PrivatePersonBaseFragment | null>(null)

  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false)
  const [personToUpdate, setPersonToUpdate] = useState<PrivatePersonBaseFragment | null>(null)

  // Initialize column visibility settings
  const [columnVisibility, setColumnVisibility] = useState<Record<string, boolean>>(initialVisibility)

  const [nameFilter, setNameFilter] = useState('')
  const [debouncedNameFilter] = useDebounceValue(nameFilter, 500)

  // Fetch private persons data with pagination
  const { data, loading } = usePrivatePersonSearchQuery({
    variables: {
      searchInput: {
        query: debouncedNameFilter
      },
      pageInfo: {
        limit: pageSize,
        offset
      }
    }
  })

  const privatePersons = data?.privatePersonSearch.edges.map((edge) => edge.node) ?? []

  const handleOpenDeleteDialog = useCallback((person: PrivatePersonBaseFragment) => {
    setPersonToDelete(person)
    setIsDeleteDialogOpen(true)
  }, [])

  const handleOpenUpdateDialog = useCallback((person: PrivatePersonBaseFragment) => {
    setPersonToUpdate(person)
    setIsUpdateDialogOpen(true)
  }, [])

  const columns = useMemo(
    () => extractColumns(handleOpenDeleteDialog, handleOpenUpdateDialog),
    [handleOpenDeleteDialog, handleOpenUpdateDialog]
  )

  /**
   * Handler for toggling column visibility
   */
  const handleColumnVisibilityChange = useCallback((columnId: string, isVisible: boolean) => {
    setColumnVisibility((prev) => ({
      ...prev,
      [columnId]: isVisible
    }))
  }, [])

  return (
    <>
      <div className='flex justify-end min-h-[theme(height.sub-header)] items-stretch'>
        <SubHeaderStringFilter
          filter={nameFilter}
          setFilter={setNameFilter}
          placeholder={t`Filter private persons in Portfolio`}
          dataTrackPrefix='Portfolio'
          inputClassName='w-[328px]'
        />
        <Popover
          className='bg-background-paper text-gray-90 rounded-sm px-1 shadow-md shadow-gray-20'
          content={
            <div className='p-4 space-y-2'>
              <Typography variant='subtitle2'>{t`Column Visibility`}</Typography>
              {columns
                .filter((col) => col.id !== 'icon')
                .map((col) => {
                  const label = col.header ?? col.id
                  return (
                    <Checkbox
                      key={col.id}
                      id={`col-${col.id}`}
                      label={String(label)}
                      checked={col.id ? columnVisibility[col.id] : true}
                      onCheckedChange={(checked) => {
                        if (!col.id) return
                        handleColumnVisibilityChange(col.id, !!checked)
                      }}
                      data-track='Portfolio / Toggle column visibility'
                    />
                  )
                })}
            </div>
          }
        >
          <Button variant='contained' palette='tertiary' data-track='Portfolio / Edit columns view' className='h-auto'>
            <IconPenBox className='size-4 mr-2' />
            {t`Edit columns`}
          </Button>
        </Popover>
        <CreatePrivatePersonDialog />
        {personToUpdate && (
          <UpdatePrivatePersonDialog
            isOpen={isUpdateDialogOpen}
            onClose={() => setIsUpdateDialogOpen(false)}
            privatePerson={personToUpdate}
          />
        )}
      </div>
      <div className='bg-background-paper'>
        <DataTable
          bodyCellClassName='text-md align-middle'
          columns={columns}
          data={privatePersons}
          enablePagination
          pageIndex={pageIndex}
          pageSize={pageSize}
          onPageChange={setPageIndex}
          onPageSizeChange={setPageSize}
          variant='backplate'
          emptyStateText={t`No private persons available`}
          loading={loading}
          loadingRows={pageSize}
          options={{
            state: {
              columnOrder,
              columnVisibility
            },
            getRowId: (row) => row.id,
            onColumnOrderChange: setColumnOrder
          }}
          rowsPerPageText={t`Rows per page:`}
          aria-label={t`Private persons`}
        />
        {personToDelete && (
          <DeletePrivatePersonDialog
            privatePerson={personToDelete}
            open={isDeleteDialogOpen}
            onOpenChange={setIsDeleteDialogOpen}
          />
        )}
      </div>
    </>
  )
}
