import { Typography, cn } from '@strise/ui-components'
import { type ReactNode } from 'react'

const BaseTag = ({
  button,
  children,
  label,
  value
}: {
  button?: ReactNode
  children?: ReactNode
  label?: string
  value: string
}): ReactNode => {
  return (
    <div className='inline-flex flex-col border border-gray-15 rounded-sm h-fit max-w-min'>
      <div className={cn('inline-flex items-center', { 'border-b border-gray-15': children })}>
        {button}
        <Typography variant='aLabel' className='bg-blue-5 px-1.5 py-0.5 whitespace-nowrap border-r border-gray-15'>
          {value}
        </Typography>
        {label && (
          <Typography variant='aLabel' className='bg-white text-text-secondary px-1.5 py-0.5  w-max whitespace-nowrap'>
            {label}
          </Typography>
        )}
      </div>
      <div>{children}</div>
    </div>
  )
}

export default BaseTag
