import { cn } from '@strise/ui-components'
import type { ReactNode } from 'react'
import { EntityMetaItem } from '~/components/EntityMeta/EntityMetaItem'
import { useEntityKeyMetaItems } from '~/components/EntityMeta/entityKeyMetaUtils'
import { type BaseEntityLikeFragment } from '~/graphqlTypes'

export const EntityMetaItems = ({
  column,
  entity,
  maxItems,
  noTooltip
}: {
  column?: boolean
  entity: BaseEntityLikeFragment
  maxItems?: number
  noTooltip?: boolean
}): ReactNode => {
  const items = useEntityKeyMetaItems(entity, maxItems)

  if (!items?.length) return null

  return (
    <div className={cn('items-center', column ? 'block' : 'flex')}>
      {items.map((item, index) => {
        const isLast = index === items.length - 1
        return (
          <EntityMetaItem
            key={index}
            item={item}
            noTooltip={noTooltip}
            className={cn(column && isLast ? 'mb-0' : 'mb-1', !column && isLast ? 'mr-0' : 'mr-4')}
          />
        )
      })}
    </div>
  )
}
