import {
  type CreateCustomPepInput,
  type CreateEntityDispositionInput,
  EntityDispositionKind,
  EntityDispositionStatusKind,
  ReviewSectionKind
} from '@strise/types'
import { groupBy } from 'lodash-es'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import {
  useCreateCustomPersonPepMutation,
  useCreateCustomPrivatePersonPepMutation,
  useCreatePersonDispositionsMutation,
  useCreatePrivatePersonDispositionMutation,
  useDeletePersonDispositionsMutation,
  useDeletePrivatePersonDispositionsMutation
} from '~/graphqlOperations'
import type { PepInfoFragment, PersonBaseFragment, PrivatePersonBaseFragment } from '~/graphqlTypes'
import { refreshReviewSections } from '~/state'

export const systemSuggestedTruePepPredicate = (pepInfo: PepInfoFragment): boolean =>
  !!pepInfo.matchInfoAnalysis?.suggestedTruePositive && !pepInfo.disposition

export const systemSuggestedFalsePepPredicate = (pepInfo: PepInfoFragment): boolean =>
  !pepInfo.matchInfoAnalysis?.suggestedTruePositive && !pepInfo.disposition

export const userConfirmedFalsePepPredicate = (pepInfo: PepInfoFragment): boolean =>
  pepInfo.disposition?.status === EntityDispositionStatusKind.ConfirmedFalse

export const userConfirmedTruePepPredicate = (pepInfo: PepInfoFragment): boolean =>
  pepInfo.disposition?.status === EntityDispositionStatusKind.ConfirmedTrue

export const refreshPepAndSanctionsReviewCache = () => {
  const currentNonce = refreshReviewSections()[ReviewSectionKind.PepsAndSanctions] ?? 0
  refreshReviewSections({ [ReviewSectionKind.PepsAndSanctions]: currentNonce + 1 })
}

export const useDeletePepDispositions = ({
  person
}: {
  person: PersonBaseFragment | PrivatePersonBaseFragment
}): {
  deleteLoading: boolean
  handleDelete: () => Promise<void>
} => {
  const isPrivatePerson = person.__typename === 'PrivatePerson'
  const features = useCurrentUserFeatures()

  const [removePersonDispositions, { loading: deletePersonDispositionsLoading }] = useDeletePersonDispositionsMutation({
    onCompleted: refreshPepAndSanctionsReviewCache
  })

  const [removePrivatePersonDispositions, { loading: deletePrivatePersonDispositionsLoading }] =
    useDeletePrivatePersonDispositionsMutation({
      onCompleted: refreshPepAndSanctionsReviewCache
    })

  const handleDelete = async (): Promise<void> => {
    const commonVariables = {
      includePepV1: !!features.PEP_AND_SANCTIONS_V2 && !features.PEP_DISPOSITIONING,
      includePepV2: !!features.PEP_AND_SANCTIONS_V2 && !!features.PEP_DISPOSITIONING,
      kind: EntityDispositionKind.Pep
    }

    await (isPrivatePerson
      ? removePrivatePersonDispositions({
          variables: {
            ...commonVariables,
            privatePerson: person.id
          }
        })
      : removePersonDispositions({
          variables: {
            ...commonVariables,
            entity: person.id
          }
        }))
  }

  return {
    handleDelete,
    deleteLoading: isPrivatePerson ? deletePrivatePersonDispositionsLoading : deletePersonDispositionsLoading
  }
}

export const useCreateEntityDisposition = (handleCompleted: () => void) => {
  const features = useCurrentUserFeatures()

  const [createPersonDisposition] = useCreatePersonDispositionsMutation({ onCompleted: handleCompleted })
  const [createPrivatePersonDisposition] = useCreatePrivatePersonDispositionMutation({ onCompleted: handleCompleted })

  const createDisposition = async ({
    inputs,
    nextPepInfos,
    person
  }: {
    inputs: CreateEntityDispositionInput[]
    nextPepInfos: PepInfoFragment[]
    person: PersonBaseFragment | PrivatePersonBaseFragment
  }) => {
    const isPrivatePerson = person.__typename === 'PrivatePerson'
    const commonVariables = {
      inputs,
      includePepV1: !!features.PEP_AND_SANCTIONS_V2 && !features.PEP_DISPOSITIONING,
      includePepV2: !!features.PEP_AND_SANCTIONS_V2 && !!features.PEP_DISPOSITIONING
    }

    if (isPrivatePerson) {
      return await createPrivatePersonDisposition({
        variables: {
          ...commonVariables,
          privatePerson: person.id
        },
        optimisticResponse: {
          __typename: 'Mutation',
          createPrivatePersonDispositions: {
            __typename: 'MutationQuery',
            privatePerson: {
              ...person,
              pepInfos: nextPepInfos
            }
          }
        }
      })
    }

    return await createPersonDisposition({
      variables: {
        ...commonVariables,
        entity: person.id
      },
      optimisticResponse: {
        __typename: 'Mutation',
        createEntityDispositions: {
          __typename: 'MutationQuery',
          person: {
            ...person,
            pepInfos: nextPepInfos
          }
        }
      }
    })
  }

  return { createDisposition }
}

export const useCreateCustomPep = (handleCompleted: () => void) => {
  const [createPersonPep, { loading: createPersonPepLoading }] = useCreateCustomPersonPepMutation({
    onCompleted: handleCompleted
  })
  const [createPrivatePersonPep, { loading: createPrivatePersonPepLoading }] = useCreateCustomPrivatePersonPepMutation({
    onCompleted: handleCompleted
  })

  const createCustomPep = async ({
    input,
    person
  }: {
    input: CreateCustomPepInput
    person: PersonBaseFragment | PrivatePersonBaseFragment
  }) => {
    const isPrivatePerson = person.__typename === 'PrivatePerson'

    if (isPrivatePerson) {
      return await createPrivatePersonPep({
        variables: {
          privatePerson: person.id,
          input
        }
      })
    }

    return await createPersonPep({
      variables: {
        entity: person.id,
        input
      }
    })
  }

  return { createCustomPep, createCustomPepLoading: createPersonPepLoading || createPrivatePersonPepLoading }
}

export interface GroupedPepEntities<T> {
  confirmedPeps: T[]
  noPeps: T[]
  potentialPeps: T[]
}

export const groupPepsByConfidence = <T extends { peps?: PepInfoFragment[] }>(entities: T[]): GroupedPepEntities<T> => {
  const grouped = groupBy(entities, (entity) => {
    const hasSuggestedTrue = entity.peps?.some((info) => info.matchInfoAnalysis?.suggestedTruePositive)
    const hasSuggestedFalse = entity.peps?.some((info) => info.matchInfoAnalysis?.suggestedTruePositive === false)

    if (hasSuggestedTrue) return 'confirmedPeps'
    if (hasSuggestedFalse) return 'potentialPeps'
    return 'noPeps'
  })

  return {
    confirmedPeps: grouped.confirmedPeps ?? [],
    potentialPeps: grouped.potentialPeps ?? [],
    noPeps: grouped.noPeps ?? []
  }
}
