import { t } from '@lingui/macro'
import { languageToLocale, toast } from '@strise/app-shared'
import { ContentType, triggerDownload } from '@strise/react-utils'
import { Button, DatePickerRange, type DateRange, IconDownload, LoaderRound, Typography } from '@strise/ui-components'
import '@strise/ui-components/src/react-day-picker.css'
import { endOfDay, startOfDay } from 'date-fns'
import type { ReactNode } from 'react'
import { useState } from 'react'
import { useDisplayLanguage } from '~/contexts/DisplayLanguageContext/displayLanguageContextUtils'
import { useTeamUsageReportCsvLazyQuery } from '~/graphqlOperations'
import { type TeamUsageReportCsvQuery } from '~/graphqlTypes'

interface TeamUsageReportProps {
  onClose: () => void
  teamId: string
  teamName: string
}

export const TeamUsageReport = ({ onClose, teamId, teamName }: TeamUsageReportProps): ReactNode => {
  const [dateRange, setDateRange] = useState<DateRange | undefined>()
  const displayLanguage = useDisplayLanguage()
  const locale = languageToLocale[displayLanguage]

  const handleDownload = (data: TeamUsageReportCsvQuery) => {
    try {
      if (!data.teamUsageReportCsv) {
        toast.error('No data to download')
        return
      }
      if (!dateRange?.from || !dateRange.to) {
        toast.error('Please select a date range')
        return
      }
      const teamUsageReportCsvTitle = `${teamName}_from:${dateRange.from.toISOString()}_to:${dateRange.to.toISOString()}`
      triggerDownload(data.teamUsageReportCsv, teamUsageReportCsvTitle, ContentType.CSV)
      setDateRange(undefined)
      toast.success(t`Report downloaded successfully`)
      onClose()
    } catch (error) {
      console.error(error)
      toast.error(t`Error downloading report`)
    }
  }

  const [fetchCsv, { loading: csvLoading }] = useTeamUsageReportCsvLazyQuery({
    onCompleted: handleDownload,
    fetchPolicy: 'no-cache'
  })

  const handleConfirm = async (): Promise<void> => {
    if (!dateRange?.from || !dateRange.to) {
      toast.error(t`Please select a date range`)
      return
    }
    const from = startOfDay(dateRange.from).toISOString()
    const to = endOfDay(dateRange.to).toISOString()

    await fetchCsv({
      variables: {
        teamUsageReportCsvId: teamId,
        from,
        to
      }
    })
  }

  return (
    <div>
      <DatePickerRange
        placeholder={t`Select the date range for the report`}
        value={dateRange}
        onChange={setDateRange}
        locale={locale}
        disabled={csvLoading}
      />
      {dateRange && (
        <Typography variant='aLabelSmall'>
          {t`Confirm the date range is correct before generating the report`}
        </Typography>
      )}
      <div className='flex justify-end mt-4'>
        <Button
          variant='contained'
          palette='primary'
          disabled={!dateRange || csvLoading}
          onClick={handleConfirm}
          data-track='Export Report / Generate team usage report'
        >
          {csvLoading && <LoaderRound palette='primary' size='sm' className='absolute left-1/2' />}
          {t`Download report`} <IconDownload className='size-4 ml-2' />
        </Button>
      </div>
    </div>
  )
}
