import { t } from '@lingui/macro'
import { toast } from '@strise/app-shared'
import { ContentType, triggerBase64Download } from '@strise/react-utils'
import { objectEntries, objectFromEntries } from '@strise/ts-utils'
import { PortfolioExportFieldKind } from '@strise/types'
import {
  Button,
  Checkbox,
  type ColumnDef,
  DataTable,
  Divider,
  IconDownload,
  LoaderRound,
  Skeleton,
  Typography
} from '@strise/ui-components'
import { format } from 'date-fns'
import { type ReactNode, useEffect, useState } from 'react'
import { getReportSections } from '~/features/PortfolioHealth/utils/dataFieldsUtils'
import { useExportPortfolioFileMutation, useFileExportLazyQuery, useFileExportsQuery } from '~/graphqlOperations'
import { type FileExportFragment, type FileExportQuery } from '~/graphqlTypes'

interface PortfolioHealthExportSectionProps {
  teamId: string
}

const renderActionCell = (
  row: { original: FileExportFragment },
  onDownload: (id: string) => void,
  isDownloading: boolean
) => {
  const isCompleted = !!row.original.completedAt
  return (
    <div className='flex justify-start'>
      {isCompleted ? (
        <Button
          className='flex flex-row items-center gap-2 text-text-link p-0 hover:bg-transparent'
          data-track='Export Report / Download'
          variant='ghost'
          palette='secondary'
          onClick={() => onDownload(row.original.id)}
          disabled={isDownloading}
          aria-label={t`Download ${row.original.name}`}
        >
          {isDownloading ? (
            <LoaderRound palette='tertiary' size='sm' className='ml-1' />
          ) : (
            <>
              {t`Download`} <IconDownload className='size-4 text-text-link' />
            </>
          )}
        </Button>
      ) : (
        <Typography variant='aLabelBold' className='text-text-secondary flex flex-row items-center gap-2'>
          {t`Pending`} <LoaderRound palette='tertiary' size='sm' className='ml-1' />
        </Typography>
      )}
    </div>
  )
}

export const GenerateExportPortfolioDataFields = ({ teamId }: PortfolioHealthExportSectionProps): ReactNode => {
  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const offset = pageIndex * pageSize
  const reportOptions = getReportSections().flatMap((section) => section.options)
  const {
    data: portfolioData,
    loading: portfolioLoading,
    startPolling,
    stopPolling
  } = useFileExportsQuery({
    variables: { teamId, pageInfo: { offset, size: pageSize } }
  })
  // Effect to manage polling based on the status of the files
  useEffect(() => {
    if (!portfolioData?.fileExports.results) {
      return
    }

    const hasPendingFiles = portfolioData.fileExports.results.some((file) => !file.completedAt)

    if (!hasPendingFiles) {
      stopPolling()
      return
    }

    // Start polling every 5 seconds if there are pending files
    startPolling(5000)

    // Clean up polling when the component is unmounted
    return () => {
      stopPolling()
    }
  }, [portfolioData?.fileExports.results, startPolling, stopPolling])

  const [exportPortfolioFile, { loading: generatingReport }] = useExportPortfolioFileMutation({
    refetchQueries: ['FileExports']
  })

  const [selectedOptions, setSelectedOptions] = useState<Record<PortfolioExportFieldKind, boolean>>(() =>
    objectFromEntries(reportOptions.map((option) => [option.id, false]))
  )

  const isAllSelected = Object.values(selectedOptions).every(Boolean)
  const isDisabledGenerateReport = !Object.values(selectedOptions).some(Boolean) || generatingReport

  const handleSelectAll = (checked: boolean) => {
    setSelectedOptions(objectFromEntries(Object.values(PortfolioExportFieldKind).map((key) => [key, checked])))
  }

  const handleOptionChange = (key: PortfolioExportFieldKind, checked: boolean) => {
    setSelectedOptions((prev) => ({ ...prev, [key]: checked }))
  }

  const handleGenerateReport = async () => {
    try {
      const selectedFields = objectEntries(selectedOptions)
        .filter(([_, isSelected]) => isSelected)
        .map(([field]) => field)

      await exportPortfolioFile({
        variables: {
          teamId: teamId,
          input: selectedFields
        }
      })

      toast.success(t`Report sent to queue for generation`)

      setSelectedOptions(
        objectFromEntries<PortfolioExportFieldKind, boolean>(reportOptions.map((option) => [option.id, false]))
      )
    } catch (error) {
      console.error(error)
      toast.error(t`Error generating report`)
    }
  }

  const handleDownloadSuccess = (data: FileExportQuery) => {
    try {
      if (!data.fileExport.file) {
        toast.error(t`No data to download`)
        return
      }
      triggerBase64Download(data.fileExport.file, data.fileExport.name, ContentType.CSV)
      toast.success(t`Report downloaded successfully`)
    } catch (error) {
      console.error(error)
      toast.error(t`Error downloading report`)
    }
  }

  const [fetchFileExport, { loading: downloadLoading }] = useFileExportLazyQuery({
    onCompleted: handleDownloadSuccess,
    fetchPolicy: 'no-cache'
  })

  const handleDownloadReport = (fileExportId: string) => {
    fetchFileExport({
      variables: {
        fileExportId: fileExportId
      }
    })
  }

  const columns: ColumnDef<FileExportFragment>[] = [
    {
      accessorKey: 'createdAt',
      header: t`Date`,
      cell: ({ row }) => format(new Date(row.original.createdAt), 'dd/MM/yyyy HH:mm')
    },
    {
      accessorKey: 'name',
      header: t`Name`
    },
    {
      id: 'actions',
      header: t`Action`,
      cell: ({ row }) => renderActionCell(row, handleDownloadReport, downloadLoading)
    }
  ]

  return (
    <>
      <div>
        <div className='pb-2'>
          <Typography variant='aLabelBold'>{t`Select report options`}</Typography>
        </div>
        <Checkbox
          id='select-all'
          label={t`All`}
          checked={isAllSelected}
          onCheckedChange={handleSelectAll}
          data-track='Export Report / Select All'
        />
        <div className='grid grid-cols-3 gap-x-8 py-2'>
          {getReportSections().map((section) => (
            <div key={section.title} className='space-y-2'>
              <Typography variant='aLabelBold' className='text-text-secondary'>
                {section.title}
              </Typography>

              {section.options.map((option) => (
                <Checkbox
                  key={option.id}
                  id={option.id}
                  label={option.label}
                  checked={selectedOptions[option.id]}
                  onCheckedChange={(checked) => handleOptionChange(option.id, checked)}
                  data-track={`Export Report / option / ${option.label}`}
                />
              ))}
            </div>
          ))}
        </div>
        <div className='flex justify-end'>
          <Button
            variant='contained'
            palette='primary'
            disabled={isDisabledGenerateReport}
            onClick={handleGenerateReport}
            data-track='Portfolio Health / Generate team usage report'
          >
            {generatingReport ? (
              <>
                {t`Generating`} <LoaderRound palette='primary' size='sm' className='ml-2' />
              </>
            ) : (
              <>
                {t`Generate report`} <IconDownload className='size-4 ml-2' />
              </>
            )}
          </Button>
        </div>
      </div>
      <Divider className='my-4' />
      <div className='space-y-4'>
        <Typography variant='aLabelBold'>{t`History of generated reports`}</Typography>
        {portfolioLoading && <Skeleton className='h-[50vh]' />}
        {portfolioData && (
          <DataTable
            columns={columns}
            data={portfolioData.fileExports.results}
            emptyStateText={t`No reports generated yet`}
            variant='default'
            enablePagination
            pageIndex={pageIndex}
            pageSize={pageSize}
            onPageChange={setPageIndex}
            onPageSizeChange={setPageSize}
            totalCount={portfolioData.fileExports.totalCount ?? 0}
            rowsPerPageText={t`Rows per page:`}
          />
        )}
      </div>
    </>
  )
}
