import { type DivProps } from '@strise/react-utils'
import type { ReactNode } from 'react'
import { forwardRef } from 'react'
import { EntityKeyMetaItem } from '~/components/EntityMeta/EntityKeyMetaItem'
import { useEntityKeyMetaItems } from '~/components/EntityMeta/entityKeyMetaUtils'
import { type BaseEntityLikeFragment } from '~/graphqlTypes'

interface EntityKeyMetaItemsProps extends DivProps {
  entity: BaseEntityLikeFragment
  expanded: boolean
  itemProps?: DivProps
  size?: 'sm' | 'md'
}

export const EntityKeyMetaItems = forwardRef<HTMLDivElement, EntityKeyMetaItemsProps>(
  ({ className, entity, expanded, itemProps, size = 'md', ...props }: EntityKeyMetaItemsProps, ref): ReactNode => {
    const items = useEntityKeyMetaItems(entity)

    if (!items?.length) return null

    return (
      <div className={className} ref={ref} {...props}>
        {items.map((item, index) => (
          <EntityKeyMetaItem
            item={item}
            key={index}
            expanded={expanded}
            dataTrack={`${entity.__typename} / ${index} copied`}
            size={size}
            {...itemProps}
          />
        ))}
      </div>
    )
  }
)
