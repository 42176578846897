import { type ReactNode, forwardRef } from 'react'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { PepsAndSanctionsCardContent } from '~/features/PepAndSanctions/PepsAndSanctionsCardContent'
import { useEntitySanctionInfo } from '~/features/PepAndSanctions/sanctions'
import { usePrivatePersonPepInfoQuery } from '~/graphqlOperations'
import { type SidepanelPrivatePersonFragment } from '~/graphqlTypes'

interface SidepanelPrivatePersonPepCardProps {
  person: SidepanelPrivatePersonFragment
}

export const SidepanelPrivatePersonPepsAndSanctionsCard = forwardRef<
  HTMLDivElement,
  SidepanelPrivatePersonPepCardProps
>(({ person }, ref): ReactNode => {
  const features = useCurrentUserFeatures()

  const { data: privatePersonPepData, loading: privatePersonPepLoading } = usePrivatePersonPepInfoQuery({
    variables: {
      privatePerson: person.id,
      includePepV1: !!features.PEP_AND_SANCTIONS_V2 && !features.PEP_DISPOSITIONING,
      includePepV2: !!features.PEP_AND_SANCTIONS_V2 && !!features.PEP_DISPOSITIONING
    },
    skip: !features.PEP_AND_SANCTIONS_V2
  })

  const { loading: sanctionsLoading, sanctionInfo } = useEntitySanctionInfo(person.id)

  if (!features.PEP_AND_SANCTIONS_V2) return null

  const pepInfos = privatePersonPepData?.privatePerson?.pepInfo ?? privatePersonPepData?.privatePerson?.pepInfos

  return (
    <PepsAndSanctionsCardContent
      ref={ref}
      person={person}
      pepInfos={pepInfos}
      pepLoading={privatePersonPepLoading}
      sanctionInfo={sanctionInfo}
      sanctionsLoading={sanctionsLoading}
    />
  )
})
