import { Trans, t } from '@lingui/macro'
import { formatDate } from '@strise/app-shared'
import { Alert } from '@strise/ui-components'
import { OpenChatLink } from '~/components/ContactStrise'
import { type SupportedSidepanelEntityFragment } from '~/components/Sidepanel/utils/sidepanelUtils'
import type { EntityLikeMetaFragment } from '~/graphqlTypes'
import { hasEntityAccess, isEntityActive } from '~/utils/entity'

export const SidepanelInactiveAlert = ({
  entity
}: {
  entity: EntityLikeMetaFragment & {
    __typename: 'Company'
  }
}) => {
  if (!entity.lifeStatus.lifeStatus) return null
  if (isEntityActive(entity)) return null

  return (
    <Alert variant='danger' className='w-full'>
      {entity.lifeStatus.lifeStatus} {entity.lifeStatus.date && formatDate(entity.lifeStatus.date, { relative: false })}
    </Alert>
  )
}

export const SidepanelHasAccessAlert = ({ entity }: { entity: SupportedSidepanelEntityFragment }) => {
  if (hasEntityAccess(entity)) return null
  return (
    <div>
      <Alert variant='warning' className='w-full'>
        {entity.access.description}
        <OpenChatLink msg={t`I need more countries in my plan.`}>
          <Trans>Contact Strise</Trans>
        </OpenChatLink>
      </Alert>
    </div>
  )
}
