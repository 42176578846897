import { type ReactNode } from 'react'
import BaseOwnerControlDetails from '~/features/Ownerships/ControlCard/BaseOwnerControlDetails'
import { type CompanyControlEntityFragment } from '~/graphqlTypes'

const BeneficialOwnerControlDetails = ({
  className,
  owner
}: {
  className?: string,
  owner: CompanyControlEntityFragment
}): ReactNode => {
  return (
    <BaseOwnerControlDetails
      owner={owner}
      isBeneficialOwner={true}
      keyTags={owner.keyTags.map((tag) => ({ label: tag.label, value: tag.value }))}
      className={className}
    />
  )
}

export default BeneficialOwnerControlDetails
