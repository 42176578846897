import { formatDate } from '@strise/app-shared'
import { Typography, cn } from '@strise/ui-components'
import type { ReactNode } from 'react'
import { EntityFlag } from '~/components/EntityFlag'
import { EntityLink } from '~/components/EntityLink/EntityLink'
import { SelectCompanyAssignee } from '~/features/Assignee/SelectCompanyAssignee'
import { SelectCompaniesStatus } from '~/features/CompanyStatus/SelectCompaniesStatus'
import { companyInPortfolio } from '~/features/CompanyStatus/companyStatusUtils'
import { toReadableFigure } from '~/features/Financials/financials'
import { type PortfolioEnabledColumns, type PortfolioField } from '~/features/Portfolio/utils/portfolioTableColumns'
import { InputCompanyTags } from '~/features/Tags/InputCompanyTags'
import { type PortfolioCompanyFragment } from '~/graphqlTypes'
import { isEntityActive } from '~/utils/entity'
import { TestIDs } from '~/utils/testIDs'

type PlainValue = string | number
type PortfolioRow = Record<
  PortfolioField,
  | {
      plainValue: PlainValue | (() => PlainValue)
      renderValue: ReactNode
    }
  | undefined
>

const companyToRevenue = (
  company: PortfolioCompanyFragment
): { operatingRevenue: number | undefined; readableOperatingRevenue: () => string } => {
  const financials = company.groupFinancials ?? company.financials
  return {
    operatingRevenue: financials?.operatingRevenue,
    readableOperatingRevenue: () =>
      financials?.operatingRevenue
        ? `${toReadableFigure(financials.operatingRevenue / 1000, null, true)} M${financials.currency}`
        : '-'
  }
}

export const renderTableRow = (
  company: PortfolioCompanyFragment,
  enabledColumns: PortfolioEnabledColumns,
  index: number
): PortfolioRow => {
  const inPortfolio = companyInPortfolio(company.statusV2.status)
  const inactive = !isEntityActive(company)
  const { readableOperatingRevenue } = companyToRevenue(company)

  return {
    name: {
      plainValue: company.name ?? '',
      renderValue: (
        <EntityLink
          entity={company}
          noFlags
          className='hyphens-auto text-text-primary [overflow-wrap:anywhere]'
          noTooltip
          data-id={TestIDs.Portfolio.companyLink(index)}
        />
      )
    },
    flags: {
      plainValue: company.flags.edges.length,
      renderValue: (
        <EntityFlag
          entityOrId={company}
          data-id={TestIDs.Portfolio.flagTooltip(index)}
          chipProps={{ 'data-id': TestIDs.Portfolio.flag(index) }}
        />
      )
    },
    employees: enabledColumns.employees
      ? {
          plainValue: company.employees ?? '',
          renderValue: (
            <Typography component='span' className={cn(inactive && 'text-text-secondary')}>
              {company.employees ?? '-'}
            </Typography>
          )
        }
      : undefined,
    revenue: enabledColumns.revenue
      ? {
          plainValue: readableOperatingRevenue,
          renderValue: (
            <Typography component='span' className={cn(inactive && 'text-text-secondary')}>
              {readableOperatingRevenue()}
            </Typography>
          )
        }
      : undefined,
    hq: enabledColumns.hq
      ? {
          plainValue: company.headquartersLocation?.name ?? '',
          renderValue: company.headquartersLocation ? company.headquartersLocation.name : '-'
        }
      : undefined,
    statusModified: enabledColumns.statusModified
      ? {
          plainValue: () =>
            company.statusV2.modifiedAt
              ? formatDate(company.statusV2.modifiedAt, {
                  relative: false,
                  time: true
                })
              : '',
          renderValue: company.statusV2.modifiedAt ? formatDate(company.statusV2.modifiedAt) : '-'
        }
      : undefined,
    status: enabledColumns.status
      ? {
          plainValue: company.statusV2.status,
          renderValue: (
            <SelectCompaniesStatus
              companies={[company]}
              triggerIconVisibility='hover'
              className='size-full min-h-[60px] px-4 py-2'
              variant='ghost'
              data-track-context='portfolio'
              data-id={TestIDs.Portfolio.selectCompanyStatusTrigger}
            />
          )
        }
      : undefined,
    tags: enabledColumns.tags
      ? {
          plainValue: (company.tags?.edges.map(({ node }) => node.name) ?? []).join(', '),
          renderValue: (
            <InputCompanyTags
              tags={inPortfolio ? (company.tags?.edges ?? []) : []}
              disabled={!inPortfolio}
              companyIds={[company.id]}
              cacheKeys={company.tags ? { [company.id]: company.tags.key } : {}}
              className='h-full'
              data-track-context='portfolio'
            />
          )
        }
      : undefined,
    assignees: enabledColumns.assignees
      ? {
          plainValue: company.assignee?.edges.map(({ node }) => node.name).join(', ') ?? '',
          renderValue: (
            <SelectCompanyAssignee
              disabled={!inPortfolio}
              assignees={inPortfolio ? (company.assignee?.edges ?? []) : []}
              companyIds={[company.id]}
              className='h-full'
              data-track-context='portfolio'
            />
          )
        }
      : undefined
  }
}
