import { t } from '@lingui/macro'
import { Typography, cn } from '@strise/ui-components'
import { TruncatedList } from '~/components/TruncatedList/TruncatedList'
import { PepDispositionBadge } from '~/features/PepAndSanctions/PepDispositionBadge'
import { PepRelation } from '~/features/PepAndSanctions/PepRelation'
import { PepRole } from '~/features/PepAndSanctions/PepRole'
import type { PepInfoFragment, PersonBaseFragment, PrivatePersonBaseFragment } from '~/graphqlTypes'

export const PepRolesAndRelations = ({
  className,
  pepInfosToShow,
  person
}: {
  className?: string
  pepInfosToShow: PepInfoFragment[]
  person: PersonBaseFragment | PrivatePersonBaseFragment | null
}) => {
  if (!pepInfosToShow.length) return null

  const pepInfosWithPep = pepInfosToShow.filter((info) => info.pep)
  const pepInfosWithRca = pepInfosToShow.filter((info) => info.rca)

  return (
    <div className={cn('flex flex-col gap-4', className)}>
      {!!pepInfosWithPep.length && (
        <div>
          <div className='flex flex-col gap-2'>
            {pepInfosWithPep.map((pepInfo) => (
              <div key={pepInfo.id}>
                {!pepInfo.roles.length && <Typography>{t`PEP due to unknown roles`}</Typography>}

                {!!pepInfo.roles.length && (
                  <TruncatedList
                    items={pepInfo.roles}
                    truncateAfter={3}
                    context='Pep roles'
                    className='flex flex-col gap-2 p-0'
                  >
                    {(role, index) => <PepRole key={index} role={role} />}
                  </TruncatedList>
                )}

                <PepDispositionBadge pepInfo={pepInfo} person={person} />
              </div>
            ))}
          </div>
        </div>
      )}

      {!!pepInfosWithRca.length && (
        <div>
          <div className='flex flex-col gap-4'>
            {pepInfosWithRca.map((pepInfo) => {
              return (
                <div key={pepInfo.id}>
                  {!pepInfo.relations.length && <Typography>{t`RCA due to unknown relationships`}</Typography>}

                  {pepInfo.relations.map((relation, index) => (
                    <PepRelation key={index} relation={relation} />
                  ))}

                  <PepDispositionBadge pepInfo={pepInfo} person={person} />
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}
