import { hasKeyMetaExtra, hasKeyMetaV2 } from '~/components/EntityMeta/entityMetaUtils'
import { useEntityQuery } from '~/graphqlOperations'
import type { BaseEntityLikeFragment, KeyEntityMetaFragment } from '~/graphqlTypes'

export const useEntityKeyMetaItems = (
  entity: BaseEntityLikeFragment,
  maxItems?: number
): KeyEntityMetaFragment[] | null => {
  const { data } = useEntityQuery({ variables: { id: entity.id }, skip: hasKeyMetaV2(entity) })

  if (hasKeyMetaV2(entity)) return entity.keyMetaV2

  if (!data?.entity) return null

  if (!hasKeyMetaV2(data.entity)) return null

  const entityKeyMetaItems = data.entity.keyMetaV2

  const withExtra = (hasKeyMetaExtra(entity) ? entity.keyMetaExtra : entityKeyMetaItems).slice(0, maxItems)

  return withExtra
}
