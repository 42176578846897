import { t } from '@lingui/macro'
import { type SetStateFn } from '@strise/react-utils'
import { Button, IconChevronUpSmall } from '@strise/ui-components'
import type { ReactNode } from 'react'
import { EntityMeta } from '~/components/EntityMeta/EntityMeta'
import { type ReviewState } from '~/features/Review/reviewUtils'
import { type BaseReviewCompanyFragment } from '~/graphqlTypes'
import { TestIDs } from '~/utils/testIDs'

export const ReviewCardHeader = ({
  company,
  filterHeight,
  reviewState,
  setReviewState
}: {
  company: BaseReviewCompanyFragment
  filterHeight: number | undefined
  reviewState: ReviewState
  setReviewState: SetStateFn<ReviewState>
}): ReactNode => {
  return (
    <div
      className='sticky z-[4] overflow-hidden justify-between flex bg-background-paper items-center px-4 py-2'
      style={{ top: filterHeight }}
      data-id={TestIDs.Review.Card.Header}
    >
      <EntityMeta variant='large' entity={company} link hideStatus />
      {reviewState.opened && !reviewState.minimized && (
        <Button
          variant='ghost'
          size='sm'
          className='text-sm'
          endIcon={<IconChevronUpSmall className='ml-1' />}
          data-track='Review / Minimize'
          onClick={() => setReviewState({ ...reviewState, minimized: true })}
        >
          {t`Minimize`}
        </Button>
      )}
    </div>
  )
}
