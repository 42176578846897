import { t } from '@lingui/macro'
import { IconInfo, Tooltip, Typography } from '@strise/ui-components'
import { type ReactNode } from 'react'
import { type GroupedPepEntities } from '~/features/PepAndSanctions/pepDispositionUtils'

interface GroupedPepsProps<T> {
  grouped: GroupedPepEntities<T>
  renderItem: (item: T, index: number) => ReactNode
}

export const GroupedPeps = <T,>({ grouped, renderItem }: GroupedPepsProps<T>) => {
  return (
    <div className='space-y-6'>
      {grouped.confirmedPeps.length > 0 && (
        <div className='space-y-2'>
          <div className='flex items-center gap-2'>
            <Typography variant='aLabel'>{t`PEP hits`}</Typography>
            <Tooltip
              content={t`We’ve identified a match that we believe is accurate. These persons has been flagged as a PEP/RCA, which may require further review or action based on your compliance policies.`}
            >
              <IconInfo size='sm' className='text-text-secondary' />
            </Tooltip>
          </div>
          {/* eslint-disable-next-line @typescript-eslint/promise-function-async */}
          {grouped.confirmedPeps.map((item, index) => renderItem(item, index))}
        </div>
      )}

      {grouped.potentialPeps.length > 0 && (
        <div className='space-y-2'>
          <div className='flex items-center gap-2'>
            <Typography variant='aLabel'>{t`Potential PEP hits`}</Typography>
            <Tooltip
              content={t`We’ve found a potential match, but it’s likely not relevant. These persons may share similarities with a PEP or RCA, but after analysis, we don’t believe it’s a true match. You can still review this result if needed.`}
            >
              <IconInfo size='sm' className='text-text-secondary' />
            </Tooltip>
          </div>
          {/* eslint-disable-next-line @typescript-eslint/promise-function-async */}
          {grouped.potentialPeps.map((item, index) => renderItem(item, index))}
        </div>
      )}

      {grouped.noPeps.length > 0 && (
        <div className='space-y-2'>
          <div className='flex items-center gap-2'>
            <Typography variant='aLabel'>{t`No PEP hits`}</Typography>
            <Tooltip
              content={t`No matches were found for these persons. They don’t appear to be a PEP/RCA based on our current data. However, you can still manually override if additional information becomes available.`}
            >
              <IconInfo size='sm' className='text-text-secondary' />
            </Tooltip>
          </div>
          {/* eslint-disable-next-line @typescript-eslint/promise-function-async */}
          {grouped.noPeps.map((item, index) => renderItem(item, index))}
        </div>
      )}
    </div>
  )
}
