import { Trans, t } from '@lingui/macro'
import { toast } from '@strise/app-shared'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  LoaderRound
} from '@strise/ui-components'
import { type ReactNode, useState } from 'react'
import { useCreateCustomPep } from '~/features/PepAndSanctions/pepDispositionUtils'
import { type PepInfoFragment, type PersonBaseFragment, type PrivatePersonBaseFragment } from '~/graphqlTypes'

export const DeleteCustomPepDialog = ({
  children,
  pepInfo,
  person
}: {
  children: ReactNode
  pepInfo: PepInfoFragment
  person: PersonBaseFragment | PrivatePersonBaseFragment
}): ReactNode => {
  const [isOpen, setIsOpen] = useState(false)

  const handleCompleted = () => {
    toast.success(t`PEP deleted`)
    setIsOpen(false)
  }

  const { createCustomPep, createCustomPepLoading } = useCreateCustomPep(handleCompleted)

  const handleConfirm = () => {
    createCustomPep({
      input: {
        name: pepInfo.name,
        isPep: !!pepInfo.pep,
        isRca: !!pepInfo.rca,
        comment: pepInfo.disposition?.comment,
        archived: true
      },
      person
    })
  }

  return (
    <AlertDialog
      open={isOpen}
      onOpenChange={(value) => {
        setIsOpen(value)
      }}
    >
      <AlertDialogTrigger>{children}</AlertDialogTrigger>
      <AlertDialogContent className='overflow-visible min-w-[600px]'>
        <AlertDialogHeader>
          <AlertDialogTitle>
            <Trans>Delete custom PEP</Trans>
          </AlertDialogTitle>
        </AlertDialogHeader>

        <AlertDialogFooter>
          {!createCustomPepLoading && (
            <AlertDialogCancel onClick={() => setIsOpen(false)}>
              <Trans>Cancel</Trans>
            </AlertDialogCancel>
          )}
          <AlertDialogAction onClick={handleConfirm} disabled={createCustomPepLoading} className='min-w-[100px]'>
            {createCustomPepLoading ? <LoaderRound size='sm' /> : <Trans>Confirm</Trans>}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
