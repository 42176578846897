import { t } from '@lingui/macro'
import { useUserId } from '@strise/app-shared'
import { NotificationSettingStatus } from '@strise/types'
import { IconNotificationAll, IconNotificationLess } from '@strise/ui-components'
import { DropdownMenu } from '~/components/DropdownMenu'
import { useChangeCompanyNotificationSetting } from '~/features/Settings/UserSettings/Notifications/notificationHook'
import { type SidepanelCompanyFragment } from '~/graphqlTypes'

type SidepanelNotificationEntity = SidepanelCompanyFragment

export const SidepanelNotificationSettings = ({
  notificationEntity
}: {
  notificationEntity: SidepanelNotificationEntity
}) => {
  const userId = useUserId()

  const isAssignedToUser = !!notificationEntity.assignee.edges.some(
    ({ node: assignedUser }) => assignedUser.id === userId
  )

  const { loading, update } = useChangeCompanyNotificationSetting()

  const notificationSettingStatus = notificationEntity.notificationSetting?.status ?? NotificationSettingStatus.Enabled
  const isEnabled = notificationSettingStatus === NotificationSettingStatus.Enabled

  const handleTurnOff = async (): Promise<void> => {
    await update({
      variables: {
        entity: notificationEntity.id,
        status: NotificationSettingStatus.Disabled
      }
    })
  }
  const handleTurnOn = async (): Promise<void> => {
    await update({
      variables: {
        entity: notificationEntity.id,
        status: NotificationSettingStatus.Enabled
      }
    })
  }

  const menuItems = [
    {
      startIcon: <IconNotificationLess />,
      title: t`Turn off for ${notificationEntity.name}`,
      onClick: handleTurnOff,
      hide: !isEnabled
    },
    {
      startIcon: <IconNotificationAll />,
      title: t`Turn on for ${notificationEntity.name}`,
      onClick: handleTurnOn,
      hide: isEnabled
    }
  ]

  return (
    <DropdownMenu
      menuItems={menuItems}
      icon={isEnabled && isAssignedToUser ? <IconNotificationAll /> : <IconNotificationLess />}
      buttonProps={{
        className: 'p-2 ml-2',
        variant: 'ghost',
        palette: 'tertiary',
        loading,
        disabled: !isAssignedToUser
      }}
      disabledText={t`You have to be assigned to edit notification settings`}
      paperProps={{ className: 'min-w-[300px]' }}
      dataTrack='Sidepanel / Toggle company notifications dropdown'
    />
  )
}
