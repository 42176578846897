import { t } from '@lingui/macro'
import { Card, CardContent, Typography } from '@strise/ui-components'
import type { ReactNode } from 'react'
import { GenerateExportModal } from '~/components/GenerateExport/GenerateExportModal'
import { useIsTeamManager } from '~/contexts/TeamContext/TeamContext'

interface PortfolioHealthHeaderCardProps {
  teamId: string
  teamName: string
  userName: string
}

export const PortfolioHealthHeaderCard = ({
  teamId,
  teamName,
  userName
}: PortfolioHealthHeaderCardProps): ReactNode => {
  const isManager = useIsTeamManager()
  const getGreeting = (): string => {
    const currentHour = new Date().getHours()

    if (currentHour < 12) {
      return t`Good morning`
    }
    if (currentHour < 18 && currentHour >= 12) {
      return t`Good afternoon`
    }
    return t`Good evening`
  }

  return (
    <Card className='border-none bg-transparent px-0 md:pt-8' size='sm'>
      <CardContent className='py-0'>
        <div>
          <Typography variant='h3'>
            {getGreeting()}, {userName}
          </Typography>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 content-end gap-2'>
          <div>
            <Typography>Team</Typography>
            <Typography variant='subtitle1'>{teamName}</Typography>
          </div>
          <div className='md:justify-self-end md:self-end'>
            {isManager && <GenerateExportModal teamId={teamId} teamName={teamName} />}
          </div>
        </div>
      </CardContent>
    </Card>
  )
}
