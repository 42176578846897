import { t } from '@lingui/macro'
import { objectEntries } from '@strise/ts-utils'
import { Card, CardContent, Typography } from '@strise/ui-components'
import type { ReactNode } from 'react'
import {
  PortfolioHealthRiskFactorsCard,
  type RiskFactorKeys
} from '~/features/PortfolioHealth/PortfolioHealthRiskFactorsCard'
import { type RiskFactorFragment, type RiskFactorsFragment } from '~/graphqlTypes'

interface PortfolioHealthRiskFactorsSectionProps {
  riskFactors: RiskFactorsFragment['riskFactors']
}

export const PortfolioHealthRiskFactorsSection = ({
  riskFactors
}: PortfolioHealthRiskFactorsSectionProps): ReactNode => {
  const factors = objectEntries(riskFactors).filter(([key]) => key !== '__typename') as Array<
    [RiskFactorKeys, RiskFactorFragment]
  >
  return (
    <Card size='sm' className='p-0 hover:border-gray-10 active:border-gray-10' palette='white'>
      <CardContent className='px-4 py-6'>
        <Typography variant='subtitle2' className='pb-4'>{t`Risk factors in portfolio`}</Typography>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
          {factors.map(([kind, { count }]) => (
            <PortfolioHealthRiskFactorsCard key={kind} riskFactor={{ kind, count }} />
          ))}
        </div>
      </CardContent>
    </Card>
  )
}
