import { Button, type ButtonProps } from '@strise/ui-components'
import type { ReactNode } from 'react'

export const ReviewCompanyStartButton = ({
  children,
  fetchReviewCompany,
  ...props
}: {
  fetchReviewCompany: () => void
} & ButtonProps): ReactNode => {
  const handleClick = (): void => {
    fetchReviewCompany()
  }

  return (
    <Button
      variant='contained'
      palette='primary'
      onClick={handleClick}
      data-track={props['data-track']}
      className='mt-4'
      {...props}
    >
      {children}
    </Button>
  )
}
