import { type MessageDescriptor } from '@lingui/core'
import { defineMessage, t } from '@lingui/macro'
import { toast } from '@strise/app-shared'
import { ContentType, triggerBase64Download } from '@strise/react-utils'
import {
  type AmsEventFeedbackInput,
  type ReviewAmsHitsInput,
  ReviewRiskKind,
  ReviewSectionKind,
  type ReviewSettingInput,
  ReviewSettingKind
} from '@strise/types'
import { differenceInHours } from 'date-fns'
import {
  usePrivatePersonReviewFileLazyQuery,
  usePrivatePersonReviewFileNameQuery,
  useReviewFileLazyQuery,
  useReviewFileNameQuery
} from '~/graphqlOperations'
import {
  type PrivatePersonReviewFragment,
  type ReviewCompanyDataFragment,
  type ReviewFragment,
  type SimpleUserFragment
} from '~/graphqlTypes'

// Beware of refactoring of this, as it is used in local storage
export enum ReviewCardContentView {
  BEFORE_REVIEW = 'before_review',
  IN_REVIEW = 'in_review',
  AFTER_REVIEW = 'after_review',
  MINIMIZED = 'minimized'
}

export interface ReviewInlineCommentState {
  createdAt: string
  createdBy: SimpleUserFragment
  id: string
  message: string
}

export type ReviewCheckedMap = Partial<Record<string, boolean>>

export type ReviewInlineCommentsStateMap = Partial<Record<string, ReviewInlineCommentState[]>>

export interface ReviewState {
  amsEventFeedback: AmsEventFeedbackInput[]
  checkedMap: ReviewCheckedMap
  comment: string
  inlineCommentsMap: ReviewInlineCommentsStateMap
  lastModifiedAt: number | undefined
  minimized: boolean | undefined
  opened: boolean
  openedAt: number | undefined
}

export const reviewSectionKeyToTitle: Partial<Record<ReviewSectionKind, MessageDescriptor>> = {
  [ReviewSectionKind.CompanyInformation]: defineMessage({
    message: 'Company information'
  }),
  [ReviewSectionKind.PepsAndSanctions]: defineMessage({
    message: 'Peps and sanctions'
  }),
  [ReviewSectionKind.ManagementAndRightsHolders]: defineMessage({
    message: 'General roles'
  }),
  [ReviewSectionKind.Ams]: defineMessage({
    message: 'Adverse media screening'
  }),
  [ReviewSectionKind.Esg]: defineMessage({ message: 'ESG' })
}

export const reviewRowKindToDescription: Partial<Record<ReviewSettingKind, MessageDescriptor>> = {
  [ReviewSettingKind.EnableInlineComments]: defineMessage({
    message: 'Comments will be included in the Review PDF as nodes.'
  }),
  [ReviewSettingKind.EnableComment]: defineMessage({
    message: 'Comment will be included on the first page of the Review PDF.'
  }),
  [ReviewSettingKind.EnableRiskAssessmentValue]: defineMessage({
    message:
      'Enables the possibility to assess the risk of a reviewed entity as none, low, medium, or high. Will be included in the Review PDF.'
  }),
  [ReviewSettingKind.EnableCheckAll]: defineMessage({
    message: 'Enables a check all checkbox in Review for checking every checkbox.'
  })
}

export const reviewRowKindToTitle: Partial<Record<ReviewSettingKind, MessageDescriptor>> = {
  [ReviewSettingKind.EnableInlineComments]: defineMessage({
    message: 'Comments on checkpoints in Review'
  }),
  [ReviewSettingKind.EnableComment]: defineMessage({
    message: 'Final comment of Review'
  }),
  [ReviewSettingKind.EnableRiskAssessmentValue]: defineMessage({
    message: 'Risk assessment in Review'
  }),
  [ReviewSettingKind.EnableCheckAll]: defineMessage({
    message: 'Check all in Review'
  }),
  [ReviewSettingKind.Countries]: defineMessage({ message: 'HQ/Country' }),
  [ReviewSettingKind.LegalForms]: defineMessage({ message: 'Legal form' }),
  [ReviewSettingKind.Industries]: defineMessage({ message: 'Industries' }),
  [ReviewSettingKind.CorporatePurpose]: defineMessage({
    message: 'Corporate purpose'
  }),
  [ReviewSettingKind.FlaggedEvents]: defineMessage({ message: 'Flagged events' }),
  [ReviewSettingKind.Registers]: defineMessage({ message: 'Registers' }),
  [ReviewSettingKind.CompanySanctions]: defineMessage({
    message: 'Company sanctions'
  }),
  [ReviewSettingKind.CreditScore]: defineMessage({ message: 'Credit score' }),
  [ReviewSettingKind.Ccjs]: defineMessage({ message: "CCJ's" }),
  [ReviewSettingKind.Owners]: defineMessage({
    id: 'Owner (Innehaver)',
    message: 'Owner'
  }),
  [ReviewSettingKind.Partners]: defineMessage({ message: 'Partners' }),
  [ReviewSettingKind.Ceos]: defineMessage({ message: 'CEO' }),
  [ReviewSettingKind.Chairpersons]: defineMessage({ message: 'Chairperson' }),
  [ReviewSettingKind.OtherBoardMembers]: defineMessage({
    message: 'Other board members'
  }),
  [ReviewSettingKind.BeneficialOwners]: defineMessage({
    message: 'Beneficial owners'
  }),
  [ReviewSettingKind.AlternativeBeneficialOwners]: defineMessage({
    message: 'Alternative beneficial owners'
  }),
  [ReviewSettingKind.Peps]: defineMessage({ message: 'PEPs' }),
  [ReviewSettingKind.Sanctions]: defineMessage({ message: 'Sanctions' }),
  [ReviewSettingKind.OtherOwners]: defineMessage({ message: 'Other owners' }),
  [ReviewSettingKind.OtherRoles]: defineMessage({ message: 'Other roles' }),
  [ReviewSettingKind.ShareClasses]: defineMessage({ message: 'Share classes' }),
  [ReviewSettingKind.CompanyAdverseMediaScreening]: defineMessage({
    message: 'Company AMS'
  }),
  [ReviewSettingKind.RolesAdverseMediaScreening]: defineMessage({
    message: 'Management and Rights holders AMS'
  }),
  [ReviewSettingKind.BoardGenderDiversity]: defineMessage({
    message: 'Board gender diversity'
  }),
  [ReviewSettingKind.SubjectToTransparencyAct]: defineMessage({
    message: 'Subject to the Transparency Act'
  }),
  [ReviewSettingKind.Idv]: defineMessage({
    message: 'IDV'
  })
}

export const reviewSectionsMapper = (
  settings: ReviewSettingInput[]
): Partial<Record<ReviewSectionKind, ReviewSettingInput[]>> => ({
  [ReviewSectionKind.CompanyInformation]: settings.filter((setting) =>
    [
      ReviewSettingKind.Countries,
      ReviewSettingKind.LegalForms,
      ReviewSettingKind.Industries,
      ReviewSettingKind.CorporatePurpose,
      ReviewSettingKind.FlaggedEvents,
      ReviewSettingKind.Registers,
      ReviewSettingKind.CreditScore,
      ReviewSettingKind.Ccjs
    ].includes(setting.kind)
  ),
  [ReviewSectionKind.PepsAndSanctions]: settings.filter((setting) =>
    [ReviewSettingKind.Peps, ReviewSettingKind.Sanctions].includes(setting.kind)
  ),
  [ReviewSectionKind.ManagementAndRightsHolders]: settings.filter((setting) =>
    [
      ReviewSettingKind.Owners,
      ReviewSettingKind.Partners,
      ReviewSettingKind.Ceos,
      ReviewSettingKind.Chairpersons,
      ReviewSettingKind.OtherBoardMembers,
      ReviewSettingKind.BeneficialOwners,
      ReviewSettingKind.AlternativeBeneficialOwners,
      ReviewSettingKind.OtherOwners,
      ReviewSettingKind.OtherRoles,
      ReviewSettingKind.Idv,
      ReviewSettingKind.ShareClasses
    ].includes(setting.kind)
  ),
  [ReviewSectionKind.Ams]: settings.filter((setting) =>
    [ReviewSettingKind.CompanyAdverseMediaScreening, ReviewSettingKind.RolesAdverseMediaScreening].includes(
      setting.kind
    )
  ),
  [ReviewSectionKind.Esg]: settings.filter((setting) =>
    [ReviewSettingKind.BoardGenderDiversity, ReviewSettingKind.SubjectToTransparencyAct].includes(setting.kind)
  )
})

export const roleRiskKindToTitle: Partial<Record<ReviewRiskKind, MessageDescriptor>> = {
  [ReviewRiskKind.HasNoValues]: defineMessage({
    message: 'No persons/companies in this role'
  }),
  [ReviewRiskKind.HasFlagsOutsideCompany]: defineMessage({
    message: 'The role has a person/company with flags outside the company'
  }),
  [ReviewRiskKind.IsCompany]: defineMessage({
    message: 'The role is assigned to a company'
  }),
  [ReviewRiskKind.HasMultipleValues]: defineMessage({
    message: 'The role has multiple persons/companies'
  })
}

export const REVIEW_CHECKBOX_COLUMN_WIDTH_CLASSES = 'legacy-xs:w-[200px] legacy-lg:w-[250px]'

const REVIEW_FILE_POLLING_MS = 2000

const isReviewFileOver1HourOld = (review: ReviewFragment | PrivatePersonReviewFragment | null | undefined): boolean =>
  review ? differenceInHours(new Date(), new Date(review.created)) > 1 : false

const handleDownloadError = (e: unknown, id: string): void => {
  console.error(`Error downloading review ${id}:`, e)
  toast.error(t`Error downloading file`)
}

export const useDownloadReview = () => {
  const [fetch, { loading }] = useReviewFileLazyQuery()

  const downloadReview = async (id: string, onCompleted?: () => void): Promise<void> => {
    try {
      const { data } = await fetch({
        variables: { id },
        onCompleted
      })
      const { file, fileName } = data?.review || {}
      if (!file || !fileName) {
        console.error(`No file for review ${id}`)
        return
      }

      triggerBase64Download(file, fileName, ContentType.PDF)
    } catch (e) {
      handleDownloadError(e, id)
    }
  }

  return { downloadReview, loading }
}

const handleFilePolling = (
  review: ReviewFragment | PrivatePersonReviewFragment | null | undefined,
  hasFileName: boolean,
  startPolling: (ms: number) => void,
  stopPolling: () => void
) => {
  const isOver1HourOld = isReviewFileOver1HourOld(review)

  if (!review) return { hasFile: false, isOver1HourOld }

  const hasFile = !!(hasFileName || review.fileName)

  if (hasFile || isOver1HourOld) {
    stopPolling()
  } else {
    startPolling(REVIEW_FILE_POLLING_MS)
  }

  return { hasFile, isOver1HourOld }
}

export const useHasReviewFile = (review: ReviewFragment | null | undefined) => {
  const { data, startPolling, stopPolling } = useReviewFileNameQuery({
    variables: { id: review?.id ?? '' },
    skip: !review || !!review.fileName || isReviewFileOver1HourOld(review)
  })

  return handleFilePolling(review, !!data?.review.fileName, startPolling, stopPolling)
}

export const useDownloadPrivatePersonReview = () => {
  const [fetch, { loading }] = usePrivatePersonReviewFileLazyQuery()

  const downloadReview = async (id: string, onCompleted?: () => void): Promise<void> => {
    try {
      const { data } = await fetch({
        variables: { id },
        onCompleted
      })
      const { file, fileName } = data?.privatePersonReview ?? {}
      if (!file || !fileName) {
        console.error(`No file for review ${id}`)
        return
      }

      triggerBase64Download(file, fileName, ContentType.PDF)
    } catch (e) {
      handleDownloadError(e, id)
    }
  }

  return { downloadReview, loading }
}

export const useHasPrivatePersonReviewFile = (review: PrivatePersonReviewFragment | null | undefined) => {
  const { data, startPolling, stopPolling } = usePrivatePersonReviewFileNameQuery({
    variables: { id: review?.id ?? '' },
    skip: !review || !!review.fileName || isReviewFileOver1HourOld(review)
  })

  return handleFilePolling(review, !!data?.privatePersonReview.fileName, startPolling, stopPolling)
}

export const extractTextColorClass = (
  hasError: boolean,
  checked: boolean
): 'text-semantic-danger-main' | 'text-text-secondary' | 'text-text-primary' => {
  if (hasError) return 'text-semantic-danger-main'
  return checked ? 'text-text-secondary' : 'text-text-primary'
}

// TODO - write test for this function
export const extractEntityAmsHits = (reviewCompanyData: ReviewCompanyDataFragment): ReviewAmsHitsInput[] => {
  const companyAms: ReviewAmsHitsInput = {
    entity: reviewCompanyData.id,
    hits: (reviewCompanyData.companyAms?.filter((ams) => ams.value) ?? []).length
  }

  const personsAms: ReviewAmsHitsInput[] =
    reviewCompanyData.personsAms?.map((section) => {
      return { entity: section.entity.id, hits: section.events.filter((event) => event.value).length }
    }) ?? []

  const entityAmsHits = [companyAms, ...personsAms]

  return entityAmsHits
}

export const defaultReviewState: ReviewState = {
  opened: false,
  minimized: false,
  checkedMap: {},
  inlineCommentsMap: {},
  comment: '',
  amsEventFeedback: [],
  lastModifiedAt: undefined,
  openedAt: undefined
}

export const defaultOpenedReviewState: ReviewState = {
  ...defaultReviewState,
  opened: true,
  lastModifiedAt: Date.now(),
  openedAt: Date.now()
}
