import { zodResolver } from '@hookform/resolvers/zod'
import { Trans } from '@lingui/macro'
import {
  CountryKind,
  type CreatePrivatePersonInput,
  CreatePrivatePersonInputSchema,
  Gender,
  type PrivatePersonUpdateInput,
  PrivatePersonUpdateInputSchema
} from '@strise/types'
import {
  Button,
  DatePicker,
  Divider,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Label,
  Radio,
  Typography
} from '@strise/ui-components'
import '@strise/ui-components/src/react-day-picker.css'
import { format } from 'date-fns'
import { forwardRef, useImperativeHandle } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { CountryCombobox } from '~/features/PrivatePersons/PrivatePersonForm/CountryCombobox'
import { YearSelect } from '~/features/PrivatePersons/PrivatePersonForm/YearSelect'
import { useEnrichPrivatePersonLazyQuery } from '~/graphqlOperations'
import { extractGender } from '~/utils/gender'

const createPrivatePersonSchema = CreatePrivatePersonInputSchema()
const updatePrivatePersonSchema = PrivatePersonUpdateInputSchema()
interface PrivatePersonFormProps {
  handleClose: () => void
  initialValues?: PrivatePersonUpdateFormData & { id: string }
  onSubmit: SubmitHandler<CreatePrivatePersonInput | PrivatePersonUpdateInput>
}

interface CreatePrivatePersonFormData extends CreatePrivatePersonInput {
  birthDateDay?: string
  birthDateMonth?: string
}

interface PrivatePersonUpdateFormData extends PrivatePersonUpdateInput {
  birthDateDay?: string
  birthDateMonth?: string
}

export interface CreatePrivatePersonFormRef {
  submit: () => void
}

export const PrivatePersonForm = forwardRef<CreatePrivatePersonFormRef, PrivatePersonFormProps>(
  ({ initialValues, onSubmit }, ref) => {
    const isEdit = !!initialValues
    const form = useForm<CreatePrivatePersonFormData | PrivatePersonUpdateFormData>({
      resolver: zodResolver(isEdit ? updatePrivatePersonSchema : createPrivatePersonSchema),
      defaultValues: initialValues ?? {
        address: [{ addressLine: null, zipCode: null, city: null, country: null }],
        birthDate: null,
        birthYear: null,
        citizenship: null,
        country: 'NO',
        gender: null,
        name: '',
        nin: ''
      }
    })

    const [enrichPrivatePerson, { loading: enrichPrivatePersonLoading }] = useEnrichPrivatePersonLazyQuery()

    const handleEnrich = async () => {
      const nin = form.getValues('nin')
      const country = form.getValues('country')

      if (!nin || !country) {
        return
      }

      const { data } = await enrichPrivatePerson({
        variables: {
          nin,
          country
        }
      })

      const enrichedPrivatePerson = data?.enrichPrivatePerson
      const birthDate = enrichedPrivatePerson?.birthDate ? new Date(enrichedPrivatePerson.birthDate) : null

      form.reset({
        name: enrichedPrivatePerson?.name ?? '',
        citizenship: enrichedPrivatePerson?.citizenship?.isoAlpha2Code ?? '',
        birthDateDay: birthDate ? format(birthDate, 'dd') : '',
        birthDateMonth: birthDate ? format(birthDate, 'MM') : '',
        birthYear: enrichedPrivatePerson?.birthYear ?? null,
        birthDate: birthDate?.toISOString() ?? null,
        gender: enrichedPrivatePerson?.gender ?? null,
        address: enrichedPrivatePerson?.addresses.map((address) => ({
          ...address,
          country: address.countryV2?.isoAlpha2Code ?? ''
        })),
        // Keep the NIN and country from the user input
        nin,
        country
      })
    }

    useImperativeHandle(ref, () => ({
      submit: () => {
        form.handleSubmit(onSubmit)()
      }
    }))

    const handleDateUpdate = (type: 'full' | 'day' | 'month' | 'year', value: Date | string | number | undefined) => {
      if (type === 'full') {
        const date = value as Date | undefined
        if (!date) {
          form.setValue('birthDate', null)
          form.setValue('birthDateDay', '')
          form.setValue('birthDateMonth', '')
          return
        }

        const day = format(date, 'dd')
        const month = format(date, 'MM')
        const year = format(date, 'yyyy')

        form.setValue('birthDate', date.toISOString())
        form.setValue('birthDateDay', day)
        form.setValue('birthDateMonth', month)
        form.setValue('birthYear', Number(year))
        return
      }

      // Update the specific field
      const currentValues = {
        day: form.getValues('birthDateDay'),
        month: form.getValues('birthDateMonth'),
        year: form.getValues('birthYear')?.toString()
      }

      const updatedValues = {
        ...currentValues,
        [type]: value
      }

      // Update the specific field
      if (type === 'year' && value) {
        form.setValue('birthYear', Number(value))
      }

      // If all three fields are present, create the complete date
      if (updatedValues.day && updatedValues.month && updatedValues.year) {
        const dateString = `${updatedValues.year}-${updatedValues.month}-${updatedValues.day}`
        const newDate = new Date(dateString)

        if (!Number.isNaN(newDate.getTime())) {
          form.setValue('birthDate', newDate.toISOString())
          return
        }
      }

      form.setValue('birthDate', null)
    }

    return (
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className='space-y-4 p-4 bg-background-paper mb-4'>
            <Typography variant='subtitle3' className='font-bold'>
              <Trans>Personal Identification</Trans>
            </Typography>
            <div className='grid grid-cols-[minmax(0,1fr)_minmax(0,1fr)_auto] gap-x-2 items-end'>
              <FormField
                control={form.control}
                name='country'
                render={({ field }) => (
                  <FormItem required>
                    <FormLabel>
                      <Trans>National Registry</Trans>
                    </FormLabel>
                    <FormControl>
                      <CountryCombobox
                        allowedCountries={[CountryKind.No, CountryKind.Se, CountryKind.Fi, CountryKind.Dk]}
                        value={field.value}
                        onChange={(items) => field.onChange(items[0]?.value ?? '')}
                        variant='outlined'
                        data-track='Create Private Person / Country'
                        indicatorVariant='show'
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {!isEdit && (
                <FormField
                  control={form.control}
                  name='nin'
                  render={({ field }) => (
                    <FormItem required>
                      <FormLabel>
                        <Trans>National ID number</Trans>
                      </FormLabel>
                      <FormControl>
                        <Input {...field} value={field.value ?? ''} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}
              {/* Only enabled for Norway for now */}
              {!isEdit && form.getValues('country') === 'NO' && (
                <Button
                  variant='contained'
                  onClick={handleEnrich}
                  loading={enrichPrivatePersonLoading}
                  palette='primary'
                  data-track='Create Private Person / Enrich'
                  type='button'
                >
                  <Trans>Search</Trans>
                </Button>
              )}
            </div>
          </div>
          <div className='space-y-4 p-4 bg-background-paper'>
            <Typography variant='subtitle3' className='font-bold'>
              <Trans>Personal Information</Trans>
            </Typography>
            <FormField
              control={form.control}
              name='name'
              render={({ field }) => (
                <FormItem required>
                  <FormLabel>
                    <Trans>Name</Trans>
                  </FormLabel>
                  <FormControl>
                    <Input {...field} value={field.value ?? ''} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className='flex flex-row gap-x-2 items-center w-full'>
              <FormField
                control={form.control}
                name='birthDateDay'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <Trans>Birth date day (DD)</Trans>
                    </FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        onChange={(e) => {
                          field.onChange(e)
                          handleDateUpdate('day', e.target.value)
                        }}
                        maxLength={2}
                        placeholder='DD'
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name='birthDateMonth'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <Trans>Birth date month (MM)</Trans>
                    </FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        onChange={(e) => {
                          field.onChange(e)
                          handleDateUpdate('month', e.target.value)
                        }}
                        maxLength={2}
                        placeholder='MM'
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className='w-48'>
                <FormField
                  control={form.control}
                  name='birthYear'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        <Trans>Birth date year (YYYY)</Trans>
                      </FormLabel>
                      <FormControl>
                        <YearSelect
                          variant='outlined'
                          palette='tertiary'
                          className='border w-full'
                          onValueChange={(value) => {
                            if (!value) {
                              field.onChange(null)
                              handleDateUpdate('year', '')
                              return
                            }
                            const yearValue = Number.parseInt(value)
                            field.onChange(yearValue)
                            handleDateUpdate('year', yearValue)
                          }}
                          value={field.value?.toString()}
                          contentProps={{ className: 'max-h-96 min-w-[10rem]' }}
                          data-track='Create Private Person / Birth year'
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className='justify-end flex pt-5'>
                <DatePicker
                  buttonIcon={true}
                  onChange={(date) => handleDateUpdate('full', date)}
                  value={form.getValues('birthDate') ? new Date(form.getValues('birthDate') || '') : undefined}
                  data-track='Create Private Person / Birth date'
                  disabledDate={{ after: new Date() }}
                />
              </div>
            </div>
            <FormField
              control={form.control}
              name='citizenship'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    <Trans>Citizenship</Trans>
                  </FormLabel>
                  <FormControl>
                    <CountryCombobox
                      value={field.value}
                      onChange={(items) => field.onChange(items[0]?.value ?? '')}
                      data-track='Create Private Person / Citizenship'
                      indicatorVariant='show'
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='gender'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    <Trans>Sex</Trans>
                  </FormLabel>
                  <div className='flex gap-2'>
                    {Object.values(Gender).map((gender) => (
                      <Label key={gender} className='flex cursor-pointer items-center gap-1'>
                        <Radio
                          value={gender}
                          checked={field.value === gender}
                          onChange={() => field.onChange(gender)}
                        />
                        <Typography variant='body2'>{extractGender(gender)}</Typography>
                      </Label>
                    ))}
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Divider />
            <FormField
              control={form.control}
              name='address.0.addressLine'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    <Trans>Address</Trans>
                  </FormLabel>
                  <FormControl>
                    <Input {...field} value={field.value ?? ''} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className='grid grid-cols-[0.25fr_0.75fr] gap-x-2'>
              <FormField
                control={form.control}
                name='address.0.zipCode'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <Trans>Zip code</Trans>
                    </FormLabel>
                    <FormControl>
                      <Input {...field} value={field.value ?? ''} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='address.0.city'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <Trans>City</Trans>
                    </FormLabel>
                    <FormControl>
                      <Input {...field} value={field.value ?? ''} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name='address.0.country'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    <Trans>Country</Trans>
                  </FormLabel>
                  <FormControl>
                    <CountryCombobox
                      value={field.value}
                      onChange={(items) => field.onChange(items[0]?.value ?? '')}
                      data-track='Create Private Person / AddressCountry'
                      indicatorVariant='show'
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </form>
      </Form>
    )
  }
)

PrivatePersonForm.displayName = 'CreatePrivatePersonForm'
