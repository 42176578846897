import { anonymizeEmails, objectKeys } from '@strise/ts-utils'
import { chunk } from 'lodash-es'
import mixpanelBrowser from 'mixpanel-browser'
import { type Mixpanel, type MixpanelOperation, type MixpanelTrackData } from '~/lib/mixpanel/typings'

// There is a mismatch between the types and the actual implementation of mixpanel-browser,
// so declaring a custom interface with the `autocapture` attribute to fix the issue
declare module 'mixpanel-browser' {
  interface Config {
    autocapture?:
      | {
          click?: boolean
          input?: boolean
          pageview?: boolean | 'full-url'
          scroll?: boolean
          submit?: boolean
        }
      | false
  }

  interface OverridedMixpanel {
    __loaded?: boolean
  }
}

const loaded = (): boolean => {
  return !!mixpanelBrowser.__loaded
}

const reset = (): void => {
  if (!loaded()) return
  mixpanelBrowser.reset()
}

const register = (props: object): void => {
  if (!loaded()) return
  mixpanelBrowser.register(props)
}

const init = (token: string, apiHost: string): void => {
  mixpanelBrowser.init(token, {
    api_host: apiHost,
    autocapture: false,
    batch_requests: false,
    disable_persistence: true
  })
}

const identify = (distinctId: string, peopleOps: MixpanelOperation | null): void => {
  if (!loaded()) return

  register({
    distinct_id: distinctId,
    $user_id: distinctId
  })

  mixpanelBrowser.identify(distinctId)

  if (!peopleOps) return

  const data = objectKeys(peopleOps).map(
    (op): MixpanelOperation => ({
      [op]: peopleOps[op]
    })
  )
  mixpanelBrowser.people.set(data)
}

const track = (events: MixpanelTrackData[]): void => {
  if (!loaded()) return
  events.forEach((event: MixpanelTrackData) => {
    // Anonymize any potential email addresses we might be sending
    const cleanedProperties = Object.fromEntries(
      Object.entries(event.properties).map(([key, value]) => {
        return [key, typeof value === 'string' ? anonymizeEmails(value) : value]
      })
    )
    mixpanelBrowser.track(event.event, cleanedProperties)
  })
}

const batchTrack = (events: MixpanelTrackData[]): void => {
  // mixpanel can take max 50 at a time
  const eventsChunks = chunk(events, 50)
  eventsChunks.map((eventsChunk) => track(eventsChunk))
}

export const mixpanel: Mixpanel = {
  init,
  reset,
  track,
  batchTrack,
  register,
  identify
}
