import { Trans, t } from '@lingui/macro'
import { extractAddress, formatDate } from '@strise/app-shared'
import { type SetStateFn } from '@strise/react-utils'
import {
  type CreateEntityDispositionInput,
  EntityDispositionKind,
  type EntityDispositionStatusKind
} from '@strise/types'
import {
  Button,
  Chip,
  Divider,
  IconChevronLeft,
  IconWarning,
  TextArea,
  Tooltip,
  Typography,
  cn
} from '@strise/ui-components'
import { intersectionBy } from 'lodash-es'
import type { ReactNode } from 'react'
import { useState } from 'react'
import { Countries } from '~/components/Countries'
import { MatchInfoAnalysisTooltipContent } from '~/components/MatchInfoAnalysis/MatchInfoAnalysisTooltipContent'
import { MissingData } from '~/components/MissingData'
import { PepDispositionDialogActions } from '~/features/PepAndSanctions/PepDispositionDialogActions'
import { PepRolesAndRelations } from '~/features/PepAndSanctions/PepRolesAndRelations'
import { dialogBgColor } from '~/features/PepAndSanctions/dispositionDialogUtils'
import { type PepInfoFragment, type PersonBaseFragment, type PrivatePersonBaseFragment } from '~/graphqlTypes'
import { extractGender } from '~/utils/gender'

interface DispositionComparisonProps {
  comparePepInfo: PepInfoFragment | null
  isAuditTrailOpen: boolean
  pepInfos: PepInfoFragment[]
  person: PersonBaseFragment | PrivatePersonBaseFragment
  setComparePepInfo: ((pepInfo: PepInfoFragment | null) => void) | null
  setIsAuditTrailOpen: SetStateFn<boolean>
  setOpen: SetStateFn<boolean>
}

const getCurrentValues = (person: PersonBaseFragment | PrivatePersonBaseFragment) => {
  if (person.__typename === 'PrivatePerson') {
    return {
      birthDate: person.birthDate ? formatDate(person.birthDate) : null,
      address: person.addresses[0],
      countries: person.citizenship ? [person.citizenship] : [],
      gender: person.gender
    }
  }

  return {
    birthDate: person.birthDateV2,
    address: person.address,
    countries: person.countries,
    gender: person.gender
  }
}

const DispositionDetailsRow = ({
  columnClassName,
  currentValue,
  isMatch,
  matchValue,
  title
}: {
  columnClassName?: string
  currentValue: ReactNode
  isMatch: boolean
  matchValue: ReactNode
  title: string
}): ReactNode => {
  return (
    <div className='flex w-full border-b border-background-default bg-background-paper'>
      <div className={cn('flex w-full gap-2 px-4 py-3', columnClassName)}>
        <Typography className='w-1/3 shrink-0 font-regular text-text-secondary'>{title}</Typography>
        <Typography className='w-full'>{currentValue ?? <MissingData />}</Typography>
      </div>
      <Divider orientation='vertical' className={cn('w-2 shrink-0', dialogBgColor)} />
      <div className={cn('flex w-full gap-2 px-4 py-3', columnClassName)}>
        <Typography className='w-1/3 shrink-0 font-regular text-text-secondary'>{title}</Typography>
        <div className='flex w-full items-center justify-between'>
          <Typography className=''>{matchValue ?? <MissingData />}</Typography>
          {!isMatch && <IconWarning size='md' className='text-semantic-notice-main' />}
        </div>
      </div>
    </div>
  )
}

export const PepDispositionComparison = ({
  comparePepInfo,
  isAuditTrailOpen,
  pepInfos,
  person,
  setComparePepInfo,
  setIsAuditTrailOpen,
  setOpen
}: DispositionComparisonProps): ReactNode => {
  const [comment, setComment] = useState('')

  if (!comparePepInfo || isAuditTrailOpen) return null

  const extractInputs = (status: EntityDispositionStatusKind): CreateEntityDispositionInput[] => {
    return [
      {
        externalId: comparePepInfo.id,
        kind: EntityDispositionKind.Pep,
        status,
        comment
      }
    ]
  }

  const handleCompleted = (): void => {
    setComment('')

    if (setComparePepInfo) {
      setComparePepInfo(null)
    } else {
      setOpen(false)
    }
  }

  const currentValues = getCurrentValues(person)

  return (
    <>
      <div className={cn('flex flex-col')}>
        <div className='flex'>
          {setComparePepInfo && (
            <Button
              variant='ghost'
              startIcon={<IconChevronLeft size='sm' className='mr-1' />}
              onClick={() => setComparePepInfo(null)}
              className='pl-0 pr-2'
              data-track='Pep disposition dialog / Back to list'
            >
              {t`Back to list`}
            </Button>
          )}
        </div>

        <div className='my-8'>
          <div className='flex flex-row justify-between rounded-lg bg-semantic-notice-shade-5 p-4'>
            <div>
              <Typography className='mb-4'>
                <Trans>PEP registry information:</Trans>
              </Typography>
              <PepRolesAndRelations pepInfosToShow={[comparePepInfo]} person={null} />
            </div>
            {comparePepInfo.matchInfoAnalysis && (
              <Tooltip
                arrow
                content={<MatchInfoAnalysisTooltipContent matchInfoAnalysis={comparePepInfo.matchInfoAnalysis} />}
              >
                <Chip
                  variant='outlined'
                  className='rounded-md border hover:border-inherit hover:bg-inherit'
                  palette='secondary'
                  label={t`${comparePepInfo.matchInfoAnalysis.result} match`}
                />
              </Tooltip>
            )}
          </div>
          {comparePepInfo.source && (
            <Typography variant='body2' className='mt-1 text-right text-text-secondary'>
              Source: {comparePepInfo.source}
            </Typography>
          )}
        </div>

        <div className='mb-2 flex'>
          <Typography className='w-1/2' variant='body1'>{t`Current case details`}</Typography>
          <Divider orientation='vertical' className='w-2 shrink-0 bg-transparent' />
          <Typography className='w-1/2' variant='body1'>{t`Possible match details`}</Typography>
        </div>

        <div className='mb-1 flex w-full flex-col'>
          <DispositionDetailsRow
            title={t`Name`}
            currentValue={person.name}
            matchValue={comparePepInfo.name}
            isMatch={person.name === comparePepInfo.name}
          />
          <DispositionDetailsRow
            title={t`DOB`}
            currentValue={currentValues.birthDate}
            matchValue={comparePepInfo.birthDateV2}
            isMatch={
              !!(
                currentValues.birthDate &&
                comparePepInfo.birthDateV2 &&
                currentValues.birthDate === comparePepInfo.birthDateV2
              )
            }
          />
          <DispositionDetailsRow
            title={t`Gender`}
            currentValue={extractGender(currentValues.gender)}
            matchValue={extractGender(comparePepInfo.gender)}
            isMatch={
              !!(currentValues.gender && comparePepInfo.gender && currentValues.gender === comparePepInfo.gender)
            }
          />
          <DispositionDetailsRow
            title={t`Address`}
            currentValue={currentValues.address ? extractAddress(currentValues.address) : null}
            matchValue={extractAddress(comparePepInfo.address)}
            isMatch={
              !!(
                currentValues.address &&
                extractAddress(currentValues.address) === extractAddress(comparePepInfo.address)
              )
            }
          />
          <DispositionDetailsRow
            title={t`Countries`}
            currentValue={currentValues.countries.length ? <Countries countries={currentValues.countries} /> : null}
            matchValue={comparePepInfo.countries.length ? <Countries countries={comparePepInfo.countries} /> : null}
            isMatch={
              !!intersectionBy(currentValues.countries, comparePepInfo.countries, (country) => country.kind).length
            }
            columnClassName='pb-4'
          />
        </div>
        <div className='flex justify-between'>
          <Typography variant='body2' className='self-end text-text-secondary'>
            {t`Source: Strise`}
          </Typography>

          {comparePepInfo.source && (
            <Typography variant='body2' className='self-end text-text-secondary'>
              Source: {comparePepInfo.source}
            </Typography>
          )}
        </div>

        <TextArea
          className='mt-8'
          placeholder={t`Comment (optional)`}
          value={comment}
          autoResize
          onChange={(event) => setComment(event.target.value)}
        />
      </div>
      <PepDispositionDialogActions
        pepInfos={pepInfos}
        extractInputs={extractInputs}
        setIsAuditTrailOpen={setIsAuditTrailOpen}
        onCompleted={handleCompleted}
        person={person}
      />
    </>
  )
}
