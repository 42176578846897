import { t } from '@lingui/macro'
import { ReviewValueStatusKind } from '@strise/types'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { GroupedPeps } from '~/features/PepAndSanctions/GroupedPeps'
import { groupPepsByConfidence } from '~/features/PepAndSanctions/pepDispositionUtils'
import { ReviewItem } from '~/features/Review/ReviewItem'
import { ReviewPepWithDisposition } from '~/features/Review/items/ReviewPepWithDisposition'
import { ReviewPeps } from '~/features/Review/items/ReviewPeps'
import { reviewPepToPep } from '~/features/Review/reviewValueTransformers'
import { type ReviewPepValueFragment } from '~/graphqlTypes'

export const ReviewPepsWithDisposition = ({ items }: { items: ReviewPepValueFragment[] }) => {
  const features = useCurrentUserFeatures()

  if (!features.PEP_DISPOSITIONING) {
    return <ReviewPeps items={items} />
  }

  if (!items.length) {
    return (
      <ReviewItem
        reviewMeta={{
          status: ReviewValueStatusKind.Danger,
          reason: t`No PEPs checked`,
          __typename: 'ReviewValueMeta' as const
        }}
      />
    )
  }

  const transformedItems = items.map((item) => ({
    ...item,
    peps: item.values.map((value) => reviewPepToPep(value))
  }))

  const grouped = groupPepsByConfidence(transformedItems)

  return (
    <GroupedPeps grouped={grouped} renderItem={(item, index) => <ReviewPepWithDisposition key={index} item={item} />} />
  )
}
