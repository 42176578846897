import { t } from '@lingui/macro'
import { type SetStateFn } from '@strise/react-utils'
import { Button, IconChevronLeft } from '@strise/ui-components'
import type { ReactNode } from 'react'
import { AuditTrailTable } from '~/features/AuditTrail/AuditTrailTable'
import { usePersonPepDispositionAuditTrailQuery } from '~/graphqlOperations'
import { type PersonBaseFragment, type PrivatePersonBaseFragment } from '~/graphqlTypes'

interface PepDispositionAuditTrailProps {
  isAuditTrailOpen: boolean
  person: PersonBaseFragment | PrivatePersonBaseFragment
  setIsAuditTrailOpen: SetStateFn<boolean>
}

export const PepDispositionAuditTrail = ({
  isAuditTrailOpen,
  person,
  setIsAuditTrailOpen
}: PepDispositionAuditTrailProps): ReactNode => {
  const isPrivatePerson = person.__typename === 'PrivatePerson'

  const { data, loading } = usePersonPepDispositionAuditTrailQuery({
    variables: { entity: person.id, isPrivatePerson },
    fetchPolicy: 'network-only',
    skip: !isAuditTrailOpen
  })

  if (!isAuditTrailOpen) return null

  const entries = isPrivatePerson
    ? (data?.privatePerson?.pepDispositionAuditTrail ?? [])
    : (data?.person?.pepDispositionAuditTrail ?? [])

  return (
    <div>
      <Button
        onClick={() => setIsAuditTrailOpen(false)}
        startIcon={<IconChevronLeft size='sm' className='mr-1' />}
        data-track='Pep disposition dialog / Go back'
      >
        {t`Go back`}
      </Button>
      <AuditTrailTable entity={person} entries={entries} loading={loading} targetName={t`PEP`} />
    </div>
  )
}
