import { t } from '@lingui/macro'
import { PortfolioExportFieldKind } from '@strise/types'

export interface ReportOption {
  id: PortfolioExportFieldKind
  key: string
  label: string
}

export interface ReportSection {
  options: ReportOption[]
  title: string
}

const getOptionLabels = () => ({
  [PortfolioExportFieldKind.Id]: t`Company ID`,
  [PortfolioExportFieldKind.Name]: t`Company name`,
  [PortfolioExportFieldKind.RolesAndIndividuals]: t`Roles and individuals`,
  [PortfolioExportFieldKind.Countries]: t`Countries`,
  [PortfolioExportFieldKind.Industries]: t`Industry type`,
  [PortfolioExportFieldKind.LegalForm]: t`Legal form`,
  [PortfolioExportFieldKind.Auditor]: t`Auditor`,
  [PortfolioExportFieldKind.AutomatedRiskLevel]: t`Automated risk level`,
  [PortfolioExportFieldKind.RcaCount]: t`RCA`,
  [PortfolioExportFieldKind.SanctionCount]: t`Sanctions`,
  [PortfolioExportFieldKind.Status]: t`Current status`,
  [PortfolioExportFieldKind.PreviousStatus]: t`Previous status`,
  [PortfolioExportFieldKind.StatusChanged]: t`Status changed at`,
  [PortfolioExportFieldKind.PepCount]: t`PEPs`,
  [PortfolioExportFieldKind.AmsCount]: t`AMS`,
  [PortfolioExportFieldKind.FlagCategories]: t`Flags`,
  [PortfolioExportFieldKind.Assignees]: t`Assignees`,
  [PortfolioExportFieldKind.AddedToReviewTime]: t`Review added at`,
  [PortfolioExportFieldKind.CompletedReviewTime]: t`Review completed at`,
  [PortfolioExportFieldKind.Tags]: t`Tags`,
  [PortfolioExportFieldKind.ReviewRiskLevel]: t`Review risk level`,
  [PortfolioExportFieldKind.NextReviewTime]: t`Next upcoming review`,
  [PortfolioExportFieldKind.Comments]: t`Comments`
})

const createReportOption = (id: PortfolioExportFieldKind): ReportOption => ({
  id,
  label: getOptionLabels()[id],
  key: id.toLowerCase()
})

const getSectionConfig = () => ({
  general: {
    title: t`General information`,
    options: [
      PortfolioExportFieldKind.Id,
      PortfolioExportFieldKind.Name,
      PortfolioExportFieldKind.RolesAndIndividuals,
      PortfolioExportFieldKind.Countries,
      PortfolioExportFieldKind.Industries,
      PortfolioExportFieldKind.LegalForm,
      PortfolioExportFieldKind.Auditor
    ]
  },
  risk: {
    title: t`Risk factors`,
    options: [
      PortfolioExportFieldKind.AutomatedRiskLevel,
      PortfolioExportFieldKind.RcaCount,
      PortfolioExportFieldKind.SanctionCount,
      PortfolioExportFieldKind.Status,
      PortfolioExportFieldKind.PreviousStatus,
      PortfolioExportFieldKind.StatusChanged,
      PortfolioExportFieldKind.PepCount,
      PortfolioExportFieldKind.AmsCount,
      PortfolioExportFieldKind.FlagCategories
    ]
  },
  review: {
    title: t`Review details`,
    options: [
      PortfolioExportFieldKind.Assignees,
      PortfolioExportFieldKind.AddedToReviewTime,
      PortfolioExportFieldKind.CompletedReviewTime,
      PortfolioExportFieldKind.Tags,
      PortfolioExportFieldKind.ReviewRiskLevel,
      PortfolioExportFieldKind.NextReviewTime,
      PortfolioExportFieldKind.Comments
    ]
  }
})

export const getReportSections = (): ReportSection[] => {
  const config = getSectionConfig()
  return Object.values(config).map(({ options, title }) => ({
    title,
    options: options.map(createReportOption)
  }))
}

export const getAllReportOptions = (): ReportOption[] => {
  const config = getSectionConfig()
  return Object.values(config).flatMap(({ options }) => options.map(createReportOption))
}

export const getReportOptionLabel = (id: PortfolioExportFieldKind): string => {
  return getOptionLabels()[id] || id
}
