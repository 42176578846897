import { Img } from './Img/Img'
import { t } from '@lingui/macro'
import { type StyleProps } from '@strise/react-utils'
import { cn } from '@strise/ui-components'
import { type ImgHTMLAttributes, type ReactNode, forwardRef } from 'react'

export interface UserAvatarProps extends StyleProps, ImgHTMLAttributes<HTMLImageElement> {
  user: { avatar: string | null; id: string }
}

export const UserAvatar = forwardRef<HTMLImageElement, UserAvatarProps>(
  ({ className, user, ...props }, ref): ReactNode => {
    return (
      <Img
        ref={ref}
        className={cn('rounded-full text-text-secondary', className)}
        src={user.avatar}
        alt={t`user avatar`}
        {...props}
      />
    )
  }
)
