import { Trans, t } from '@lingui/macro'
import { CompanyStatusDot } from '@strise/app-shared'
import { type DivProps, type SetStateFn, setChildState } from '@strise/react-utils'
import { ApplicationSearchReturnType, CompanyStatus, TrackedActivityKind } from '@strise/types'
import { Button, type ComboboxItem, IconButton, IconCross, Typography } from '@strise/ui-components'
import { ConfirmDialog, ModalContent, Table, TableCell, TableHeadCell, TableRow } from '@strise/ui-components-legacy'
import type { ReactNode } from 'react'
import { useState } from 'react'
import { useToggle } from 'usehooks-ts'
import { EntitySearchInputMultiple } from '~/components/Entity/EntitySearchInput'
import { AssigneeSearchSelect } from '~/features/Assignee/SelectCompanyAssignee'
import { useReassignUsersToCompanies } from '~/features/Assignee/assigneeHooks'
import { useSelectCompaniesStatus } from '~/features/CompanyStatus/useSelectCompaniesStatus'
import { reviewableEntityLocationFilters } from '~/features/Search/searchUtils'
import { useEntityMetaQuery } from '~/graphqlOperations'
import type { BaseEntityLikeFragment, CompanyUserConnectionEdgeFragment, SimpleUserFragment } from '~/graphqlTypes'
import { TestIDs } from '~/utils/testIDs'
import { track } from '~/utils/tracking'

const AddToReviewCompanyRow = ({
  assignees,
  companyId,
  setAssignees,
  setSelectedCompanies
}: {
  assignees: CompanyUserConnectionEdgeFragment[]
  companyId: string
  setAssignees: SetStateFn<CompanyUserConnectionEdgeFragment[] | undefined>
  setSelectedCompanies: SetStateFn<BaseEntityLikeFragment[]>
}): ReactNode => {
  const { data, loading } = useEntityMetaQuery({ variables: { id: companyId } })

  const handleDelete = (): void => {
    setSelectedCompanies((prevSelectedCompanies) => prevSelectedCompanies.filter((company) => company.id !== companyId))
  }

  const handleAssigneeChange = (items: Array<ComboboxItem<SimpleUserFragment>>): void => {
    const selectedTeamUsers = items.map((item) => ({
      node: item.value,
      __typename: 'CompanyUserConnectionEdge' as const
    }))
    setAssignees(selectedTeamUsers)
  }

  const entity = data?.entity

  return (
    <TableRow>
      <TableCell>{loading ? '' : entity?.name || t`Unknown name`}</TableCell>
      <TableCell width={350}>
        <AssigneeSearchSelect assignees={assignees} loading={loading} onChange={handleAssigneeChange} />
      </TableCell>
      <TableCell textAlign='right'>
        <IconButton
          className='rounded-full p-[2px]'
          variant='ghost'
          onClick={handleDelete}
          data-track='Review / Add to Review / Remove company'
        >
          <IconCross size='md' />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export const AddToReviewDialog = ({ className, ...props }: DivProps): ReactNode => {
  const [dialogOpen, toggleDialogOpen, setDialogOpen] = useToggle(false)
  const [selectedCompanies, setSelectedCompanies] = useState<BaseEntityLikeFragment[]>([])
  const [selectedCompaniesAssignees, setSelectedCompaniesAssignees] = useState<
    Partial<Record<string, CompanyUserConnectionEdgeFragment[]>>
  >({})
  const { loading: reassignLoading, reassignUser } = useReassignUsersToCompanies()
  const { loading: statusLoading, updateCompanyStatus } = useSelectCompaniesStatus(
    selectedCompanies.map((company) => ({ id: company.id, status: null }))
  )

  const handleClose = (): void => {
    setSelectedCompanies([])
    setSelectedCompaniesAssignees({})
    setDialogOpen(false)
  }

  const updateAssignees = async (): Promise<void> => {
    await Promise.all(
      selectedCompanies.map(async (company): Promise<void> => {
        const companyAssignees = selectedCompaniesAssignees[company.id]?.map((a) => a.node.id)

        if (!companyAssignees) return

        await reassignUser([company.id], companyAssignees)
      })
    )
  }

  const handleConfirm = async (): Promise<void> => {
    await Promise.all([updateCompanyStatus(CompanyStatus.InQualification, null), updateAssignees()])

    selectedCompanies.forEach((company) =>
      track(TrackedActivityKind.StriseCompanyStatusChanged, {
        companyId: company.id,
        status: CompanyStatus.InQualification,
        context: 'Review'
      })
    )

    handleClose()
  }

  const loading = reassignLoading || statusLoading

  return (
    <div className={className} {...props}>
      <Button
        variant='contained'
        palette='secondary'
        className='h-full w-48'
        onClick={toggleDialogOpen}
        data-track='Review / Add to Review / Open dialog'
        data-id={TestIDs.Review.AddToReview.button}
      >
        <Trans>Add to Review</Trans>
      </Button>
      {dialogOpen && (
        <ConfirmDialog
          isOpen
          confirmText={t`Add`}
          cancelText={t`Cancel`}
          onConfirm={handleConfirm}
          onCancel={handleClose}
          loading={loading}
          disabled={!selectedCompanies.length}
          confirmButtonProps={{
            palette: 'secondary',
            'data-id': TestIDs.Review.AddToReview.confirm,
            'data-track': 'Review / Add to Review / Confirm'
          }}
          cancelButtonProps={{
            'data-id': 'add-to-review-confirm-dialog-cancel-button',
            'data-track': 'Review / Add to Review / Cancel'
          }}
          contentMaxWidth={888}
          title={t`Add to Review`}
        >
          <ModalContent pb={20} data-id={TestIDs.Review.AddToReview.dialog}>
            <EntitySearchInputMultiple
              variant='outlined'
              placeholder={t`Start searching by company name or ID`}
              entityKindFilter={ApplicationSearchReturnType.Company}
              entityLocationFilters={reviewableEntityLocationFilters}
              selectedEntities={selectedCompanies}
              onChange={setSelectedCompanies}
              dataTrack='Review / Add to Review / Company select'
              autoFocus
              itemsWrapperProps={{
                'data-id': 'add-to-review-select-company-options-container'
              }}
            />

            {!!selectedCompanies.length && (
              <>
                <Table my={5}>
                  <thead>
                    <TableRow>
                      <TableHeadCell>
                        <Trans>Company</Trans>
                      </TableHeadCell>
                      <TableHeadCell>
                        <Trans>Assign to</Trans>
                      </TableHeadCell>
                      <TableHeadCell />
                    </TableRow>
                  </thead>
                  <tbody>
                    {selectedCompanies.map((company) => {
                      const assignees = selectedCompaniesAssignees[company.id] ?? []
                      const setAssignees = setChildState(setSelectedCompaniesAssignees, company.id)

                      return (
                        <AddToReviewCompanyRow
                          key={company.id}
                          companyId={company.id}
                          setSelectedCompanies={setSelectedCompanies}
                          assignees={assignees}
                          setAssignees={setAssignees}
                        />
                      )
                    })}
                  </tbody>
                </Table>
                <div className='flex items-center'>
                  <Typography>
                    <Trans>Company status will change to</Trans>
                  </Typography>
                  <CompanyStatusDot status={CompanyStatus.InQualification} />
                  <Typography className='font-medium'>
                    <Trans>In Review</Trans>
                  </Typography>
                </div>
              </>
            )}
          </ModalContent>
        </ConfirmDialog>
      )}
    </div>
  )
}
