import { Trans, t } from '@lingui/macro'
import { NationFlag, extractAddress, formatDate, useIsSupervisor } from '@strise/app-shared'
import { type DivProps } from '@strise/react-utils'
import { omniEntityUrl } from '@strise/ts-utils'
import { CountryKind } from '@strise/types'
import {
  Button,
  DataTable,
  Divider,
  ExternalLink,
  Tooltip,
  Typography,
  cn,
  createColumnHelper
} from '@strise/ui-components'
import React, { type ReactNode, memo, useMemo } from 'react'
import { useToggle } from 'usehooks-ts'
import { CopyButton } from '~/components/CopyButton'
import { DataSources } from '~/components/Sidepanel/DataSources'
import { SidepanelCard } from '~/components/Sidepanel/SidepanelCard'
import { TruncatedList } from '~/components/TruncatedList/TruncatedList'
import { type SidepanelCompanyFragment } from '~/graphqlTypes'

const CorporatePurpose = ({
  className,
  company,
  ...props
}: {
  company: SidepanelCompanyFragment
} & DivProps): ReactNode => {
  const [showAll, toggleShowAll] = useToggle(false)

  const purpose = company.corporatePurpose

  if (!purpose) return null

  const truncatedPurpose = purpose.substring(0, 450)

  return (
    <div className={className} {...props}>
      <Typography className='py-4' variant='subtitle1'>{t`Corporate purpose`}</Typography>
      <Typography variant='body1'>{showAll ? purpose : truncatedPurpose}</Typography>
      {truncatedPurpose !== purpose && (
        <Button
          className='px-0'
          size='sm'
          variant='ghost'
          color='primary'
          onClick={toggleShowAll}
          data-track='Corporate purpose / Toggle'
        >
          {showAll ? t`Show less` : t`Show more`}
        </Button>
      )}
    </div>
  )
}

const columnHelper = createColumnHelper<SidepanelCompanyFragment>()

const extractColumns = () => [
  columnHelper.accessor((company) => company.name, {
    id: 'name',
    header: t`Company name`
  }),
  columnHelper.accessor((company) => company.legalForm, {
    id: 'legalForm',
    header: t`Legal form`,
    cell: ({ getValue }) => {
      const legalForm = getValue()
      if (!legalForm) return '-'
      return (
        <Tooltip content={legalForm.code}>
          <span>{legalForm.name}</span>
        </Tooltip>
      )
    }
  }),
  columnHelper.accessor((company) => company.articleOfAssociation, {
    id: 'articleOfAssociation',
    header: t`Article of association`,
    cell: ({ getValue }) => {
      const articleOfAssociation = getValue()
      if (!articleOfAssociation) return null
      return (
        <ExternalLink href={articleOfAssociation} className='text-text-link'>
          <Trans>Article of association</Trans>
        </ExternalLink>
      )
    }
  }),
  columnHelper.accessor((company) => company.organizationNumberV2, {
    id: 'organizationNumber',
    header: t`Organization number`,
    cell: ({ getValue }) => {
      const organizationNumber = getValue()
      if (!organizationNumber) return '-'
      return (
        <div className='flex justify-between items-center'>
          {organizationNumber.registerUrl ? (
            <ExternalLink href={organizationNumber.registerUrl} className='text-text-link'>
              {organizationNumber.formatted}
            </ExternalLink>
          ) : (
            organizationNumber.formatted
          )}
          <CopyButton label='Organization number' value={organizationNumber.value} />
        </div>
      )
    }
  }),
  columnHelper.accessor((company) => company.internationalIdentifiers, {
    id: 'internationalIdentifiers',
    header: t`Company ID`,
    cell: ({ getValue }) => (
      <TruncatedList className='px-0' context='Company international identifiers' items={getValue()} truncateAfter={3}>
        {(item, index, isLast) => (
          <React.Fragment key={item.organizationNumber}>
            <div className='flex justify-between gap-2'>
              <div className={cn('py-3 flex-1 min-w-0', isLast && 'pt-3 pb-2', index === 0 && 'pt-0')}>
                <div className='flex items-center gap-2'>
                  <NationFlag countryIsoCode={item.country?.isoAlpha2Code} className='size-4 shrink-0' />
                  <Tooltip content={item.description}>
                    <span className='truncate'>{item.description}</span>
                  </Tooltip>
                </div>
                <Typography className='text-text-secondary'>{item.organizationNumber}</Typography>
              </div>
              <CopyButton className='py-3' label='Company ID' value={item.organizationNumber} />
            </div>
            {!isLast && <Divider />}
          </React.Fragment>
        )}
      </TruncatedList>
    ),
    meta: {
      cellClassName: 'pb-0'
    }
  }),
  columnHelper.accessor((company) => company.vatNumber, {
    id: 'vatNumber',
    header: t`VAT number`
  }),
  columnHelper.accessor((company) => company.industries, {
    id: 'industries',
    header: t`Industries`,
    cell: ({ getValue }) => getValue().map((industry) => <div key={industry}>{industry}</div>)
  }),
  columnHelper.accessor((company) => company.previousNames, {
    id: 'previousNames',
    header: t`Previous names`,
    cell: ({ getValue }) => getValue().join(', ')
  }),
  columnHelper.accessor((company) => company.otherNames, {
    id: 'otherNames',
    header: t`Associated names`,
    cell: ({ getValue }) => (
      <TruncatedList
        className='px-0'
        context='Company associated names'
        items={getValue().map((name) => ({ name }))}
        truncateAfter={3}
      >
        {(item, index, isLast) => (
          <React.Fragment key={item.name}>
            <div className={cn('py-3', isLast && 'pt-3 pb-2', index === 0 && 'pt-0')}>{item.name}</div>
            {!isLast && <Divider />}
          </React.Fragment>
        )}
      </TruncatedList>
    ),
    meta: {
      cellClassName: 'pb-0'
    }
  }),
  columnHelper.accessor((company) => company.address, {
    id: 'address',
    header: t`Address`,
    cell: ({ getValue }) => {
      const address = getValue()
      if (!address) return '-'
      return extractAddress(address)
    }
  }),
  columnHelper.accessor((company) => company.tradingAddress, {
    id: 'tradingAddress',
    header: t`Trading address`,
    cell: ({ getValue }) => getValue().join(', ')
  }),
  columnHelper.accessor((company) => company.primaryCountry, {
    id: 'primaryCountry',
    header: t`Country`,
    cell: ({ getValue }) => {
      const country = getValue()
      if (!country) return '-'
      return (
        <div className='flex items-center gap-2'>
          <NationFlag className='size-4' countryIsoCode={country.isoAlpha2Code} />
          <span>{country.englishName}</span>
        </div>
      )
    }
  }),
  columnHelper.accessor((company) => company.inception, {
    id: 'inception',
    header: t`Inception`,
    cell: ({ getValue }) => {
      const inception = getValue()
      if (!inception) return '-'
      return formatDate(inception)
    }
  }),
  columnHelper.accessor((company) => company.yearsInOperation, {
    id: 'yearsInOperation',
    header: t`Years in operation`
  }),
  columnHelper.accessor((company) => company.id, {
    id: 'omni',
    header: t`Omni`,
    cell: ({ getValue }) => (
      <ExternalLink href={omniEntityUrl(getValue())} className='text-text-link'>
        Omni link
      </ExternalLink>
    )
  })
]

export const SidepanelCompanyDetailsCard = memo(({ company }: { company: SidepanelCompanyFragment }) => {
  const isSupervisor = useIsSupervisor()

  const columns = useMemo(() => extractColumns(), [company])

  const columnVisibility = {
    legalForm: !company.isGlobalCompany,
    articleOfAssociation: !!company.articleOfAssociation,
    organizationNumber: !company.isGlobalCompany,
    internationalIdentifiers: company.isGlobalCompany,
    vatNumber: company.primaryCountry?.kind === CountryKind.Gb,
    industries: !company.isGlobalCompany,
    previousNames: !!company.previousNames.length,
    otherNames: company.isGlobalCompany,
    address: !!company.address,
    tradingAddress: !!company.tradingAddress.length,
    primaryCountry: company.isGlobalCompany,
    omni: isSupervisor
  }

  return (
    <SidepanelCard title={t`Company information`} loading={!company}>
      <div className='px-4'>
        <DataTable
          columns={columns}
          data={[company]}
          orientation='vertical'
          className='table-fixed w-full'
          headerCellClassName='text-text-secondary text-md !font-book !px-0 w-1/3 max-w-1/3 p-3'
          bodyCellClassName='text-md font-book w-2/3 max-w-2/3 p-3'
          getRowClassName={() => 'hover:bg-white'}
          options={{
            state: {
              columnVisibility
            }
          }}
        />
        <CorporatePurpose company={company} />
      </div>
      <DataSources dataSources={company.dataSources.baseInfo} />
    </SidepanelCard>
  )
})
