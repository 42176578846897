import { t } from '@lingui/macro'
import { usePersistentState } from '@strise/react-utils'
import { Divider, Tabs, TabsContent, TabsList, TabsTrigger } from '@strise/ui-components'
import { memo } from 'react'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { PortfolioCompanyView } from '~/features/Portfolio/components/PortfolioCompany/PortfolioCompanyView'
import { PortfolioPrivatePersonView } from '~/features/Portfolio/components/PortfolioPrivatePerson/PortfolioPrivatePersonView'

export const PortfolioView = memo(() => {
  const [activeTab, setActiveTab] = usePersistentState<string>('entity-type-active-tab', 'companies')
  const features = useCurrentUserFeatures()
  const isPrivatePersonEnabled = features.EXPERIMENTAL_PRIVATE_PERSON_FEATURES

  return (
    <Tabs defaultValue={activeTab} onValueChange={setActiveTab}>
      <TabsList className={isPrivatePersonEnabled ? 'flex justify-center py-2' : 'hidden'}>
        <TabsTrigger value='companies'>{t`Business entities`}</TabsTrigger>
        {isPrivatePersonEnabled && <TabsTrigger value='privatePersons'>{t`Private persons`}</TabsTrigger>}
      </TabsList>
      <TabsContent value='companies'>
        <PortfolioCompanyView />
      </TabsContent>
      {isPrivatePersonEnabled && (
        <TabsContent value='privatePersons'>
          <Divider />
          <PortfolioPrivatePersonView />
        </TabsContent>
      )}
    </Tabs>
  )
})
