import { t } from '@lingui/macro'
import { toast, useCurrentUser } from '@strise/app-shared'
import {
  getBrowserGlobals,
  setChildState,
  useContext,
  useRecursiveQueryParamsReactRouter,
  validateEnum
} from '@strise/react-utils'
import { objectKeys, omitDeepTypename } from '@strise/ts-utils'
import { type ReviewSettingInput, type TeamSanctionSettingsInput } from '@strise/types'
import { isEqual, isNil, xor } from 'lodash-es'
import type { ReactNode } from 'react'
import { useEffect, useMemo, useState } from 'react'
import { useClearApolloClientCache } from '~/apolloClient/useClearApolloClientCache'
import { QUERY_PARAMS } from '~/constants'
import { CurrentUserSettingsContext } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { useIsTeamManager } from '~/contexts/TeamContext/TeamContext'
import { useTeamSettings } from '~/contexts/TeamSettingsContext/TeamSettingsContext'
import { SettingsModalContext } from '~/features/Settings/SettingsModalContext'
import { isDefaultRiskClassSettingsThatHasNeverBeenChanged } from '~/features/Settings/TeamFeatures/utils/riskClassSettingsUtils'
import { hasInvalidCustomCheckbox } from '~/features/Settings/TeamFeatures/utils/teamReviewSettingsUtils'
import { SettingsTab } from '~/features/Settings/UserSettings/components/SettingsTab'
import { handleUpdateUserCache } from '~/features/Settings/UserSettings/utils/userInfo'
import { settingsToInput } from '~/features/Settings/settingsUtils'
import { type TeamSettingsState, type UserSettingsState } from '~/features/Settings/types'
import {
  useUpdateAllUserSettingsMutation,
  useUpdateTeamMutation,
  useUpdateTeamSettingsMutation
} from '~/graphqlOperations'

interface SettingsModalQueryParamsState {
  [QUERY_PARAMS.settingsTab]: SettingsTab | null
}

const defaultState = {
  [QUERY_PARAMS.settingsTab]: null
}

const validations = {
  [QUERY_PARAMS.settingsTab]: validateEnum(SettingsTab)
}

const serializations = {
  [QUERY_PARAMS.settingsTab]: (value: SettingsModalQueryParamsState[typeof QUERY_PARAMS.settingsTab]) =>
    value ?? undefined
}

const defaultTab = SettingsTab.USER_ACCOUNT

export const SettingsModalContextProvider = ({ children }: { children: ReactNode }): ReactNode => {
  const [settingsModalState, setSettingsModalState] = useRecursiveQueryParamsReactRouter<SettingsModalQueryParamsState>(
    defaultState,
    validations,
    serializations
  )

  const settingsTab = settingsModalState[QUERY_PARAMS.settingsTab]
  const setSettingsTab = setChildState(setSettingsModalState, QUERY_PARAMS.settingsTab)

  const [teamSettingsState, setTeamSettingsState] = useState<TeamSettingsState>({})
  const [userSettingsState, setUserSettingsState] = useState<UserSettingsState>({})

  const currentUser = useCurrentUser()
  const { features, settings } = useContext(CurrentUserSettingsContext)

  const clearCache = useClearApolloClientCache()

  const isTeamManager = useIsTeamManager()
  const [tabValidationErrors, setTabValidationErrors] = useState<Partial<Record<SettingsTab, boolean>>>({})

  const { data } = useTeamSettings()

  // Need to use useEffect as we can't trigger the onCompleted in TeamSettingsContextProvider
  useEffect(() => {
    if (data) {
      const sanctions = data.team.teamSettings.sanctionSettings
      setTeamSettingsState((prevState) => ({
        ...prevState,
        sanctions: {
          ...sanctions,
          similarityScoreThreshold: sanctions.similarityScoreThreshold * 100
        },
        flagFilter: {
          period: data.team.teamSettings.flagFilterSettings.period
        },
        reviewCustomCheckboxes: data.team.teamSettings.reviewSettingsV2.customCheckboxes,
        riskClassSettings: data.team.teamSettings.riskClassSettings,
        riskEngineSettings: data.team.teamSettings.riskEngineSettings
      }))
    }
  }, [data])

  const team = data?.team ?? null

  const {
    alerts: alertsState,
    features: featuresState,
    language,
    userInfo,
    ...restUserSettingsState
  } = userSettingsState
  const {
    enabledPdfSections,
    reviewCustomCheckboxes,
    reviewSettingsV2,
    riskClassSettings,
    riskEngineSettings,
    sanctions,
    ...restTeamSettingsState
  } = teamSettingsState

  const hasReviewSettingsChanges =
    !!reviewSettingsV2 &&
    reviewSettingsV2.some((setting) => {
      const teamSetting = team?.teamSettings.reviewSettingsV2.settings.find(
        (s) => s.kind === setting.kind
      ) as ReviewSettingInput

      if (setting.enabled !== teamSetting.enabled) return true

      const teamSettingRiskValues = Object.values(teamSetting)
        .filter((v) => Array.isArray(v))
        .flat()
      const settingRiskValues = Object.values(setting)
        .filter((v) => Array.isArray(v))
        .flat()
      // xor returns an array of values that are not in both arrays, making it possible to compare without order
      const hasChanges = xor(teamSettingRiskValues, settingRiskValues).length > 0
      return hasChanges
    })

  const hasCustomCheckboxesChanges =
    !!reviewCustomCheckboxes && !isEqual(reviewCustomCheckboxes, team?.teamSettings.reviewSettingsV2.customCheckboxes)

  const hasRiskEngineSettingsChanges =
    !!riskEngineSettings && !isEqual(riskEngineSettings, team?.teamSettings.riskEngineSettings)

  const hasPdfSettingsChanges = Boolean(
    enabledPdfSections && enabledPdfSections !== data?.team.teamSettings.reviewSettingsV2.enabledPdfSections
  )

  const handleChangeActiveTab = (tab: SettingsTab): void => {
    setSettingsTab(tab)
  }

  const setIsSettingsOpen = (isOpen: boolean): void => {
    setSettingsTab(() => {
      if (!isOpen) {
        return null
      }

      return defaultTab
    })
  }

  const hasFeaturesSettingsChanges =
    featuresState?.some((feature) => feature.isEnabled !== features[feature.kind]) ?? false

  // helper function to get the correct value from the state
  const getSanctionSettingsStateValues = (
    state: Partial<TeamSanctionSettingsInput> | null | undefined,
    key: keyof TeamSanctionSettingsInput
  ): TeamSanctionSettingsInput[keyof TeamSanctionSettingsInput] | null | undefined => {
    const keyMap: Record<Partial<keyof TeamSanctionSettingsInput>, number | boolean | undefined> = {
      // convert to percentage to compare with data from API
      similarityScoreThreshold: (state?.similarityScoreThreshold ?? 0) / 100,
      filterOnNationality: state?.filterOnNationality,
      filterOnInception: state?.filterOnInception
    }

    return keyMap[key] ?? state?.[key]
  }

  const hasAlertsSettingsChanges = alertsState?.some((feature) => feature.isEnabled !== features[feature.kind]) ?? false

  const hasSanctionChanges = objectKeys<Partial<TeamSanctionSettingsInput>>(sanctions ?? {}).some((key) => {
    const stateValue = getSanctionSettingsStateValues(sanctions, key)
    const settingsValue = team?.teamSettings.sanctionSettings[key]
    return stateValue !== settingsValue
  })

  const hasLanguageChange = useMemo(() => Boolean(language && language !== settings.displayLanguage), [language])

  const hasTriggersChanges =
    !isNil(restTeamSettingsState.reviewTriggers) &&
    !isEqual(restTeamSettingsState.reviewTriggers, team?.teamSettings.reviewTriggerSettings)

  const hasRiskClassSettingsChanges =
    !isDefaultRiskClassSettingsThatHasNeverBeenChanged(riskClassSettings, team?.teamSettings.riskClassSettings) &&
    !isNil(riskClassSettings) &&
    !isEqual(omitDeepTypename(riskClassSettings), omitDeepTypename(team?.teamSettings.riskClassSettings))

  // TODO - we really need react-hook-form and/or clean this up
  const unsavedUserChangesMap: Record<SettingsTab, boolean> = useMemo(
    () => ({
      [SettingsTab.USER_ACCOUNT]: Boolean(objectKeys(userInfo ?? {}).length),
      [SettingsTab.USER_LANGUAGE]: Boolean(language),
      [SettingsTab.USER_FEATURES]: hasFeaturesSettingsChanges,
      [SettingsTab.USER_NOTIFICATIONS]: hasAlertsSettingsChanges,
      [SettingsTab.TEAM_SETTINGS]: false, // Team Setting
      [SettingsTab.TEAM_MEMBERS]: false, // Team Setting
      [SettingsTab.TEAM_REVIEW_SETTINGS]: false, // Team Setting
      [SettingsTab.TEAM_SANCTIONS]: false, // Team Setting
      [SettingsTab.TEAM_TRIGGERS]: false, // Team Setting
      [SettingsTab.TEAM_PDF_SETTINGS]: false, // Team Setting
      [SettingsTab.TEAM_CONNECTED_APPS]: false, // Team Setting
      [SettingsTab.LOGOUT]: false, // No state connected to this tab
      [SettingsTab.TEAM_RISK_ENGINE_SETTINGS]: false // Team Setting
    }),
    [userSettingsState]
  )

  const extractHasTeamSettingsChanges = (): boolean => {
    if (!isNil(restTeamSettingsState.name) && restTeamSettingsState.name !== team?.name) return true
    if (
      !isNil(restTeamSettingsState.emails) &&
      JSON.stringify(restTeamSettingsState.emails) !==
        JSON.stringify(team?.teamSettings.teamEmails.map((teamEmail) => teamEmail.email))
    ) {
      return true
    }
    if (
      !isNil(restTeamSettingsState.onlySendDocumentsToTeamEmails) &&
      restTeamSettingsState.onlySendDocumentsToTeamEmails !== team?.teamSettings.onlySendDocumentsToTeamEmails
    ) {
      return true
    }

    return restTeamSettingsState.flagFilter?.period !== team?.teamSettings.flagFilterSettings.period
  }

  const hasTeamSettingsChanges = extractHasTeamSettingsChanges()

  const unsavedTeamChangesMap: Record<SettingsTab, boolean> = useMemo(
    () => ({
      [SettingsTab.USER_ACCOUNT]: false, // User Setting
      [SettingsTab.USER_LANGUAGE]: false, // User Setting
      [SettingsTab.USER_FEATURES]: false, // User Setting
      [SettingsTab.USER_NOTIFICATIONS]: false, // User Setting
      [SettingsTab.TEAM_SETTINGS]: hasTeamSettingsChanges,
      [SettingsTab.TEAM_MEMBERS]: false, // No state connected to this tab
      [SettingsTab.TEAM_REVIEW_SETTINGS]: hasReviewSettingsChanges || hasCustomCheckboxesChanges,
      [SettingsTab.TEAM_SANCTIONS]: hasSanctionChanges,
      [SettingsTab.TEAM_TRIGGERS]: hasTriggersChanges || hasRiskClassSettingsChanges,
      [SettingsTab.TEAM_PDF_SETTINGS]: Boolean(objectKeys(enabledPdfSections ?? {}).length),
      [SettingsTab.TEAM_CONNECTED_APPS]: false, // No state connected to this tab
      [SettingsTab.LOGOUT]: false, // No state connected to this tab
      [SettingsTab.TEAM_RISK_ENGINE_SETTINGS]: hasRiskEngineSettingsChanges
    }),
    [teamSettingsState]
  )

  const hasUnsavedUserChanges = useMemo(
    () =>
      objectKeys(restUserSettingsState).length > 0 ||
      objectKeys(userInfo ?? {}).length > 0 ||
      hasAlertsSettingsChanges ||
      hasFeaturesSettingsChanges ||
      hasLanguageChange,
    [userSettingsState]
  )

  const hasUnsavedTeamChanges = useMemo(
    () =>
      hasTeamSettingsChanges ||
      hasAlertsSettingsChanges ||
      hasSanctionChanges ||
      hasReviewSettingsChanges ||
      hasCustomCheckboxesChanges ||
      hasPdfSettingsChanges ||
      hasTriggersChanges ||
      hasRiskClassSettingsChanges ||
      hasRiskEngineSettingsChanges,
    [teamSettingsState]
  )

  const [updateAllUserSettings, { called: userCalled, error: errorUser, loading: userMutationLoading }] =
    useUpdateAllUserSettingsMutation({
      onCompleted: () => handleCompletedUserSettings(),
      update: handleUpdateUserCache(currentUser.id)
    })

  const [updateTeamSettings, { called: teamCalled, error: errorTeam, loading: teamMutationLoading }] =
    useUpdateTeamSettingsMutation({
      onCompleted: (d) => {
        const s = d.updateTeamSettingsV2.team.teamSettings.sanctionSettings
        toast.success(t`Team settings updated`)
        if (hasReviewSettingsChanges || hasCustomCheckboxesChanges || hasPdfSettingsChanges) {
          clearCache().then(() => {
            getBrowserGlobals()?.window.location.reload()
          })
        }
        setTeamSettingsState({
          sanctions: {
            ...s,
            similarityScoreThreshold: s.similarityScoreThreshold * 100
          },
          flagFilter: {
            period: d.updateTeamSettingsV2.team.teamSettings.flagFilterSettings.period
          },
          riskClassSettings: d.updateTeamSettingsV2.team.teamSettings.riskClassSettings
        })
      }
    })

  const [updateTeam, { loading: teamNameLoading }] = useUpdateTeamMutation()

  const handleCompletedUserSettings = (): void => {
    toast.success(t`User settings updated`)
    setUserSettingsState({})
    if (hasLanguageChange) {
      clearCache().then(() => {
        getBrowserGlobals()?.window.location.reload()
      })
    }
  }

  useEffect(() => {
    const hasCompleted =
      !userMutationLoading &&
      !teamMutationLoading &&
      !teamNameLoading &&
      !errorUser &&
      !errorTeam &&
      (userCalled || teamCalled)
    if (hasCompleted) {
      setIsSettingsOpen(false)
    }
  }, [userMutationLoading, teamMutationLoading, teamCalled, userCalled])

  const saveUserSettings = async (): Promise<void> => {
    const financialsFilter = userSettingsState.financialFavorites?.financials ?? null
    const settingsInput = settingsToInput(settings)

    await updateAllUserSettings({
      variables: {
        user: currentUser.id,
        settings: {
          ...settingsInput,
          application: {
            ...settingsInput.application,
            displayLanguage: userSettingsState.language || settingsInput.application?.displayLanguage,
            opportunityFinancialHighlightsSelector:
              userSettingsState.financialFavorites?.highlighted ??
              settingsInput.application?.opportunityFinancialHighlightsSelector,
            opportunityFinancialHighlightCount:
              userSettingsState.financialFavorites?.highlightedCount ??
              settingsInput.application?.opportunityFinancialHighlightCount
          },
          opportunities: {
            ...settingsInput.opportunities,
            locations: {
              ...settingsInput.opportunities?.locations,
              locations: userSettingsState.locations?.locations ?? settingsInput.opportunities?.locations?.locations,
              polygon: userSettingsState.locations?.polygon ?? settingsInput.opportunities?.locations?.polygon
            },
            employees: userSettingsState.employees,
            financials: financialsFilter ? { valueFilters: financialsFilter } : settingsInput.opportunities?.financials,
            industries: userSettingsState.industries,
            requireNoAccountant: userSettingsState.accountants?.requireNoAccountant,
            accountants: userSettingsState.accountants?.accountants,
            collateralCreditors: userSettingsState.collateralCreditors,
            realEstateCollateralCreditors: userSettingsState.realEstateCollateralCreditors,
            inception: userSettingsState.inception,
            flagKinds: userSettingsState.flagKinds,
            legalForms: userSettingsState.legalForms
          },
          events: {
            ...settingsInput.events,
            events: {
              ...(userSettingsState.events || settingsInput.events?.events)
            }
          }
        },
        features: [...(userSettingsState.features ?? []), ...(userSettingsState.alerts ?? [])],
        name: userSettingsState.userInfo?.name || currentUser.name
      }
    })
  }

  const saveTeamSettings = (): void => {
    if (teamSettingsState.name) {
      updateTeam({
        variables: {
          name: teamSettingsState.name
        }
      })
    }
    updateTeamSettings({
      variables: {
        input: {
          reviewSettingsV2: {
            settings: teamSettingsState.reviewSettingsV2 ?? [],
            customCheckboxes: teamSettingsState.reviewCustomCheckboxes,
            enabledPdfSections
          },
          emails: teamSettingsState.emails,
          onlySendDocumentsToTeamEmails: teamSettingsState.onlySendDocumentsToTeamEmails,
          reviewTriggerSettings: teamSettingsState.reviewTriggers,
          sanctionSettings: {
            filterOnInception:
              teamSettingsState.sanctions?.filterOnInception ??
              team?.teamSettings.sanctionSettings.filterOnInception ??
              false,
            filterOnNationality:
              teamSettingsState.sanctions?.filterOnNationality ??
              team?.teamSettings.sanctionSettings.filterOnNationality ??
              false,
            similarityScoreThreshold: teamSettingsState.sanctions?.similarityScoreThreshold
              ? teamSettingsState.sanctions.similarityScoreThreshold / 100
              : (team?.teamSettings.sanctionSettings.similarityScoreThreshold ?? 0)
          },
          flagFilter: teamSettingsState.flagFilter,
          riskClassSettings: teamSettingsState.riskClassSettings,
          riskEngineSettings: teamSettingsState.riskEngineSettings
        }
      }
    })
  }

  const handleSaveSettings = (): void => {
    const hasCustomCheckboxErrors = reviewCustomCheckboxes && hasInvalidCustomCheckbox(reviewCustomCheckboxes)

    if (hasCustomCheckboxErrors) {
      setTabValidationErrors((prevState) => ({
        ...prevState,
        [SettingsTab.TEAM_REVIEW_SETTINGS]: true
      }))
      return
    }

    if (isTeamManager && hasUnsavedTeamChanges) {
      saveTeamSettings()
    }
    if (hasUnsavedUserChanges) {
      saveUserSettings()
    }
  }

  const value = useMemo(
    () => ({
      activeTab: settingsTab,
      setActiveTab: handleChangeActiveTab,
      setIsSettingsOpen,
      hasUnsavedTeamChanges,
      hasUnsavedUserChanges,
      handleSaveSettings,
      settingsMutationLoading: teamMutationLoading || userMutationLoading,
      userSettingsState,
      setUserSettingsState,
      teamSettingsState,
      setTeamSettingsState,
      tabValidationErrors,
      setTabValidationErrors,
      unsavedTeamChangesMap,
      unsavedUserChangesMap
    }),
    [
      userSettingsState,
      teamSettingsState,
      teamMutationLoading,
      userMutationLoading,
      settingsTab,
      tabValidationErrors,
      hasUnsavedTeamChanges,
      hasUnsavedUserChanges,
      unsavedTeamChangesMap,
      unsavedUserChangesMap
    ]
  )

  return <SettingsModalContext.Provider value={value}>{children}</SettingsModalContext.Provider>
}
