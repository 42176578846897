import { Trans, t } from '@lingui/macro'
import { toast } from '@strise/app-shared'
import { type CreatePrivatePersonInput, type PrivatePersonUpdateInput } from '@strise/types'
import { Button, Modal } from '@strise/ui-components'
import format from 'date-fns/format'
import { useRef } from 'react'
import { type SubmitHandler } from 'react-hook-form'
import {
  type CreatePrivatePersonFormRef,
  PrivatePersonForm
} from '~/features/PrivatePersons/PrivatePersonForm/PrivatePersonForm'
import { resetPrivatePersonSearchCache } from '~/features/PrivatePersons/privatePerson'
import { useUpdatePrivatePersonMutation } from '~/graphqlOperations'
import { type PrivatePersonBaseFragment } from '~/graphqlTypes'

interface UpdatePrivatePersonDialogProps {
  isOpen: boolean
  onClose: () => void
  privatePerson: PrivatePersonBaseFragment
}

export const UpdatePrivatePersonDialog = ({ isOpen, onClose, privatePerson }: UpdatePrivatePersonDialogProps) => {
  const formRef = useRef<CreatePrivatePersonFormRef>(null)

  const [updatePrivatePerson, { loading }] = useUpdatePrivatePersonMutation({
    onCompleted: () => {
      toast.success(t`Private person updated`)
    },
    update: resetPrivatePersonSearchCache
  })

  // Trigger the form submission
  const handleSubmit = () => formRef.current?.submit()

  // Called on submit if the form is valid
  const onSubmit: SubmitHandler<CreatePrivatePersonInput | PrivatePersonUpdateInput> = async (data) => {
    await updatePrivatePerson({ variables: { input: { ...data, id: privatePerson.id } } })
    onClose()
  }

  const birthDate = privatePerson.birthDate ? new Date(privatePerson.birthDate) : null

  const initalValues = {
    ...privatePerson,
    name: privatePerson.name ?? '',
    country: privatePerson.citizenship?.isoAlpha2Code ?? '',
    citizenship: privatePerson.citizenship?.isoAlpha2Code ?? '',
    birthDateDay: birthDate ? format(birthDate, 'dd') : '',
    birthDateMonth: birthDate ? format(birthDate, 'MM') : '',
    address: privatePerson.addresses.map((address) => ({
      ...address,
      country: address.countryV2?.isoAlpha2Code ?? ''
    }))
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={<Trans>Update private person</Trans>}
      contentClassName='max-w-[640px] min-w-0'
      actions={
        <>
          <Button
            className='rounded-sm'
            variant='contained'
            data-track='Update Private Person / Cancel'
            onClick={onClose}
          >
            <Trans>Cancel</Trans>
          </Button>
          <Button
            className='rounded-sm'
            variant='contained'
            palette='primary'
            data-track='Update Private Person / Update'
            onClick={handleSubmit}
            loading={loading}
          >
            <Trans>Update</Trans>
          </Button>
        </>
      }
    >
      <PrivatePersonForm ref={formRef} handleClose={onClose} initialValues={initalValues} onSubmit={onSubmit} />
    </Modal>
  )
}
