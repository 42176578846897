import { NationFlag } from '@strise/app-shared'
import { type CountryKind, LocationKind } from '@strise/types'
import { Combobox, type ComboboxItem, type ComboboxProps } from '@strise/ui-components'
import type { ReactNode } from 'react'
import { useMemo } from 'react'
import { useCountriesQuery } from '~/graphqlOperations'
import { type CountryOrCollectionFragment } from '~/graphqlTypes'

const isValidCountry = (
  country: CountryOrCollectionFragment
): country is CountryOrCollectionFragment & {
  countriesV2: [{ isoAlpha2Code: string }, ...{ isoAlpha2Code: string }[]]
  name: string
} => {
  return !!country.name && !!country.countriesV2.length && country.kind === LocationKind.Country
}

const getCountryItems = (countries: CountryOrCollectionFragment[]): Array<ComboboxItem<string>> => {
  return countries
    .filter(isValidCountry)
    .map((country) => {
      const code = country.countriesV2[0].isoAlpha2Code
      return {
        id: code,
        value: code,
        label: country.name,
        renderNode: (
          <div className='flex items-center gap-2'>
            <NationFlag countryIsoCode={code} />
            {country.name}
          </div>
        )
      }
    })
    .sort((a, b) => a.label.localeCompare(b.label))
}

export type CountryComboboxProps = Omit<ComboboxProps<string>, 'items' | 'value'> & {
  allowedCountries?: CountryKind[]
  value?: string | null
}

export const CountryCombobox = ({ allowedCountries, value, ...props }: CountryComboboxProps): ReactNode => {
  const { data, loading } = useCountriesQuery({ variables: { includeCollections: false } })
  const countries = data?.countries.edges.map(({ node }) => node) ?? []
  const filteredCountries = allowedCountries
    ? countries.filter((country) => country.countriesV2.some((c) => allowedCountries.includes(c.kind)))
    : countries
  const items = useMemo(() => getCountryItems(filteredCountries), [filteredCountries])

  const selectedValue = items.filter((item) => item.value === value)
  // We use singleSelect here, so we'll have max one item
  const selectedFirstValue = selectedValue[0]

  return (
    <Combobox
      loading={loading}
      items={items}
      singleSelect
      closeOnSelect
      {...props}
      value={selectedValue}
      className='border'
      data-track={props['data-track']}
      customSelectedItemsRenderer={
        <div className='flex items-center gap-2'>
          <NationFlag countryIsoCode={selectedFirstValue?.value} />
          {selectedFirstValue?.label}
        </div>
      }
    />
  )
}
