import type { RefObject } from 'react'
import { createRef, useMemo } from 'react'
import {
  type SupportedSidepanelEntityFragment,
  extractIsSupportedSidepanelEntity
} from '~/components/Sidepanel/utils/sidepanelUtils'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { type SidepanelEntityFragment } from '~/graphqlTypes'
import { SidepanelTab } from '~/utils/urls'

const baseSidepanelTabs: Record<SupportedSidepanelEntityFragment['__typename'], SidepanelTab[]> = {
  Company: [
    SidepanelTab.Company,
    SidepanelTab.RiskSummary,
    SidepanelTab.Credit,
    SidepanelTab.CCJs,
    SidepanelTab.People,
    SidepanelTab.Ownership,
    SidepanelTab.Financials,
    SidepanelTab.Events,
    SidepanelTab.Grow
  ],
  Person: [
    SidepanelTab.Person,
    SidepanelTab.PepsAndSanctions,
    SidepanelTab.Roles,
    SidepanelTab.Ownership,
    SidepanelTab.Events
  ],
  PrivatePerson: []
}

export const useSidepanelTabs = (entity: SidepanelEntityFragment | null | undefined): SidepanelTab[] => {
  const features = useCurrentUserFeatures()

  if (!entity) return []

  const isSupportedSidepanelEntity = extractIsSupportedSidepanelEntity(entity)
  if (!isSupportedSidepanelEntity) return []

  const hideTabMap: Partial<Record<SidepanelTab, boolean>> = {
    [SidepanelTab.RiskSummary]: !features.RISK_SUMMARIES,
    [SidepanelTab.Credit]: !features.CREDIT_DATA,
    [SidepanelTab.Grow]: !features.OPPORTUNITIES || (entity.__typename === 'Company' && entity.isGlobalCompany),
    [SidepanelTab.Financials]: entity.__typename === 'Company' && entity.isGlobalCompany,
    [SidepanelTab.Events]:
      (entity.__typename === 'Company' && entity.isGlobalCompany) ||
      (entity.__typename === 'Person' && entity.isGlobalPerson),
    [SidepanelTab.PepsAndSanctions]: !features.PEP_AND_SANCTIONS_V2
  }

  const filteredSidepanelTabs = baseSidepanelTabs[entity.__typename].filter((tab) => {
    if (!(tab in hideTabMap)) return true

    return !hideTabMap[tab]
  })

  return filteredSidepanelTabs
}

export type TabRefs = Partial<Record<SidepanelTab, RefObject<HTMLDivElement>>>

export const useSidepanelTabRefs = (entity: SidepanelEntityFragment | null | undefined): TabRefs => {
  const sidepanelTabs = useSidepanelTabs(entity)

  const tabRefs: TabRefs = useMemo(() => {
    if (!entity) return {}

    return Object.fromEntries(
      sidepanelTabs.map((tab) => {
        // eslint-disable-next-line @eslint-react/no-create-ref
        return [tab, createRef()]
      })
    )
  }, [entity])

  return tabRefs
}
