import { t } from '@lingui/macro'
import { Card, CardContent, CardDescription, CardTitle, Typography } from '@strise/ui-components'
import type { ReactNode } from 'react'
import { TestIDs } from '~/utils/testIDs'

interface HomeGreetingCardProps {
  team: string
  userName: string
}

export const HomeGreetingCard = ({ team, userName }: HomeGreetingCardProps): ReactNode => {
  const getGreeting = (): string => {
    const currentHour = new Date().getHours()

    if (currentHour < 12) {
      return t`Good morning`
    }
    if (currentHour < 18 && currentHour >= 12) {
      return t`Good afternoon`
    }
    return t`Good evening`
  }
  return (
    <Card className='border-none bg-transparent px-0 md:pt-8' size='sm'>
      <CardContent>
        <CardTitle>
          <Typography variant='h3' data-id={TestIDs.Home.greetingTitle}>
            {getGreeting()}, {userName}
          </Typography>
        </CardTitle>
        <CardDescription>
          <Typography data-id={TestIDs.Home.grettingTeam}>Team</Typography>
        </CardDescription>
        <CardTitle className='py-0'>
          <Typography variant='subtitle1'>{team}</Typography>
        </CardTitle>
      </CardContent>
    </Card>
  )
}
