import { t } from '@lingui/macro'
import { Button, IconExport, Modal, Select, Tooltip } from '@strise/ui-components'
import '@strise/ui-components/src/react-day-picker.css'
import type { ReactNode } from 'react'
import { useState } from 'react'
import { GenerateExportPortfolioDataFields } from '~/components/GenerateExport/GenerateExportPortfolioDataFields'
import { TeamUsageReport } from '~/components/GenerateExport/GenerateExportTeamUsageReport'

type ReportType = 'portfolio_data' | 'team_usage'

interface GenerateExportModalProps {
  teamId: string
  teamName: string
}

export const GenerateExportModal = ({ teamId, teamName }: GenerateExportModalProps): ReactNode => {
  const [selectedReport, setSelectedReport] = useState<ReportType>('portfolio_data')
  const [open, setOpen] = useState(false)

  return (
    <>
      <Tooltip content={t`Export options`}>
        <Button
          variant='outlined'
          palette='tertiary'
          className='rounded-sm'
          data-track='Portfolio Health / Export'
          onClick={() => setOpen(true)}
        >
          {t`Export options`} <IconExport className='size-4 ml-2' />
        </Button>
      </Tooltip>
      <Modal
        isOpen={open}
        onClose={() => setOpen(false)}
        title={t`Export options`}
        actions={
          <Button data-track='Export / Cancel' variant='outlined' palette='tertiary' onClick={() => setOpen(false)}>
            {t`Cancel`}
          </Button>
        }
      >
        <div className='pb-4'>
          <Select
            className='px-4'
            options={[
              { value: 'portfolio_data', children: t`Export portfolio data report` },
              { value: 'team_usage', children: t`Export team usage report` }
            ]}
            placeholder={t`Select report type`}
            value={selectedReport}
            onValueChange={setSelectedReport}
          />
        </div>
        {selectedReport === 'team_usage' && (
          <TeamUsageReport teamId={teamId} teamName={teamName} onClose={() => setOpen(false)} />
        )}
        {selectedReport === 'portfolio_data' && <GenerateExportPortfolioDataFields teamId={teamId} />}
      </Modal>
    </>
  )
}
