import { t } from '@lingui/macro'
import { filterNullishValues } from '@strise/ts-utils'
import { Divider, Typography } from '@strise/ui-components'
import type { ReactNode } from 'react'
import { Fragment } from 'react'
import { DataSources } from '~/components/Sidepanel/DataSources'
import { Detail2 } from '~/components/Sidepanel/Detail2'
import { SidepanelCard } from '~/components/Sidepanel/SidepanelCard'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { GroupedPeps } from '~/features/PepAndSanctions/GroupedPeps'
import { PersonPepCard } from '~/features/PepAndSanctions/PersonPepCard'
import { SanctionCard } from '~/features/PepAndSanctions/SanctionCard'
import { extractPepAndSanctionsFromCompanyNetwork } from '~/features/PepAndSanctions/pepAndSanctionsUtils'
import { groupPepsByConfidence } from '~/features/PepAndSanctions/pepDispositionUtils'
import { useHelpCenterLink } from '~/features/Users/userHooks'
import { type CompanyNetworkFragment, type SidepanelCompanyFragment } from '~/graphqlTypes'
import { TestIDs } from '~/utils/testIDs'

interface SidepanelCompanyPepsAndSanctionsCardProps {
  company: SidepanelCompanyFragment
  companyNetwork: CompanyNetworkFragment | undefined | null
  loading: boolean
}

export const SidepanelCompanyPepsAndSanctionsCard = ({
  company,
  companyNetwork,
  loading
}: SidepanelCompanyPepsAndSanctionsCardProps): ReactNode => {
  const features = useCurrentUserFeatures()
  const helpCenterLink = useHelpCenterLink()

  if (!features.PEP_AND_SANCTIONS_V2) {
    return null
  }

  const pepEntitiesSearched = companyNetwork?.allPersons.length
  // +1 for the company itself
  const sanctionEntitiesSearched = companyNetwork
    ? companyNetwork.allPersons.length + companyNetwork.otherCompanies.length + 1
    : 0
  const { pepEntities, sanctionedEntities } = companyNetwork
    ? extractPepAndSanctionsFromCompanyNetwork(companyNetwork)
    : { pepEntities: [], sanctionedEntities: [] }

  const grouped = groupPepsByConfidence(pepEntities)

  return (
    <SidepanelCard
      title={t`PEPs and Sanctions`}
      id='pep-and-sanctions'
      data-id={TestIDs.SidePanel.PepAndSanctions.root}
      loading={loading}
    >
      <div className='px-4'>
        <Detail2
          title={t`Sanctions`}
          description={t`${sanctionEntitiesSearched} entities screened`}
          dataId={TestIDs.SidePanel.PepAndSanctions.sanctions}
        >
          {sanctionedEntities.length === 0 ? (
            <Typography variant='aLabel' className='text-text-secondary'>
              {t`No sanctions to show`}
            </Typography>
          ) : (
            sanctionedEntities.map(({ associatedCompanies, entity, sanctions }, index) => (
              <Fragment key={entity.id}>
                <SanctionCard
                  entity={entity}
                  sanctions={sanctions}
                  associatedCompanies={associatedCompanies}
                  currentCompanyId={company.id}
                />
                {index < sanctionedEntities.length - 1 && <Divider />}
              </Fragment>
            ))
          )}

          <DataSources
            className='px-0'
            dataSources={company.dataSources.sanctions}
            hideLinks
            learnMoreLink={`${helpCenterLink}/articles/37050-how-strise-sanctions-work`}
          />
        </Detail2>

        <Divider className='col-span-3' />

        <Detail2
          title={t`PEP`}
          description={t`${pepEntitiesSearched} entities screened`}
          dataId={TestIDs.SidePanel.PepAndSanctions.peps}
        >
          {pepEntities.length === 0 && (
            <Typography variant='aLabel' className='text-text-secondary'>
              {t`No PEPs to show`}
            </Typography>
          )}

          {!features.PEP_DISPOSITIONING &&
            pepEntities.map((entity, index) => (
              <Fragment key={entity.person.id}>
                <PersonPepCard
                  pepInfos={entity.peps ?? filterNullishValues([entity.pep])}
                  person={entity.person}
                  associatedCompanies={entity.associatedCompanies}
                  currentCompanyId={company.id}
                />
                {index < pepEntities.length - 1 && <Divider />}
              </Fragment>
            ))}

          {features.PEP_DISPOSITIONING && (
            <GroupedPeps
              grouped={grouped}
              renderItem={(entity) => (
                <Fragment key={entity.person.id}>
                  <PersonPepCard
                    pepInfos={entity.peps ?? filterNullishValues([entity.pep])}
                    person={entity.person}
                    associatedCompanies={entity.associatedCompanies}
                    currentCompanyId={company.id}
                  />
                  <Divider />
                </Fragment>
              )}
            />
          )}

          <DataSources
            className='px-0'
            dataSources={company.dataSources.peps}
            hideLinks
            learnMoreLink={`${helpCenterLink}/articles/20734-what-is-a-pep`}
          />
        </Detail2>
      </div>
    </SidepanelCard>
  )
}
