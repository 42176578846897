import { Trans, t } from '@lingui/macro'
import { StepCard, StepCardContent, StepHeader, toast } from '@strise/app-shared'
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  IconPenBox,
  Radio,
  cn
} from '@strise/ui-components'
import type { ReactNode } from 'react'
import { useState } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { EntityLink } from '~/components/EntityLink/EntityLink'
import { EntityKeyMetaItems } from '~/features/Ownerships/EntityKeyMetaItems'
import { CreatePepForm, type CreatePepInput } from '~/features/PepAndSanctions/CreatePepForm'
import { PepDispositionReset } from '~/features/PepAndSanctions/PepDispositionReset'
import { dialogBgColor } from '~/features/PepAndSanctions/dispositionDialogUtils'
import {
  refreshPepAndSanctionsReviewCache,
  useCreateCustomPep,
  useDeletePepDispositions
} from '~/features/PepAndSanctions/pepDispositionUtils'
import { type PersonBaseFragment, type PrivatePersonBaseFragment } from '~/graphqlTypes'

export const CreateCustomPepDialog = ({
  person
}: {
  person: PersonBaseFragment | PrivatePersonBaseFragment
}): ReactNode => {
  const [isOpen, setIsOpen] = useState(false)

  const [isPepOrRca, setIsPepOrRca] = useState(false)

  const handleCompleted = () => {
    toast.success(t`Confirmed`)
    refreshPepAndSanctionsReviewCache()
    setIsOpen(false)
  }

  const { createCustomPep, createCustomPepLoading } = useCreateCustomPep(handleCompleted)
  const { deleteLoading, handleDelete } = useDeletePepDispositions({ person })

  const disabled = createCustomPepLoading || deleteLoading

  const handleSubmit: SubmitHandler<CreatePepInput> = async (data) => {
    await createCustomPep({
      input: {
        name: person.name ?? 'Unknown',
        isPep: data.type === 'pep',
        isRca: data.type === 'rca',
        archived: false,
        comment: data.comment,
        roles: data.type === 'pep' && data.pepRole ? [{ title: data.pepRole, from: data.pepDate }] : [],
        relations:
          data.type === 'rca' && data.rcaPepRole
            ? [
                {
                  name: data.rcaPepName ?? 'Unknown',
                  relationType: data.rcaRelation,
                  roles: data.rcaPepRole
                    ? [
                        {
                          title: data.rcaPepRole,
                          from: data.rcaPepDate
                        }
                      ]
                    : []
                }
              ]
            : []
      },
      person
    })
  }

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(value) => {
        setIsOpen(value)
      }}
    >
      <DialogTrigger>
        <Button
          variant='ghost'
          palette='primary'
          data-track='Open PEP disposition dialog'
          size='sm'
          className='w-fit self-end'
          startIcon={<IconPenBox size='sm' className='mr-1' />}
        >
          <Trans>Verify</Trans>
        </Button>
      </DialogTrigger>
      <DialogContent
        onPointerDownOutside={(e) => e.preventDefault()}
        className={cn('overflow-auto min-w-[752px]', dialogBgColor)}
      >
        <DialogHeader>
          <DialogTitle>
            <Trans>PEP Verification</Trans>
          </DialogTitle>
        </DialogHeader>

        <div className='flex flex-col gap-3 bg-background-paper p-4'>
          <EntityLink entity={person} withIcon noTooltip noLink />
          <EntityKeyMetaItems className='flex gap-2' entity={person} expanded itemProps={{ className: 'w-full' }} />
        </div>

        <StepCard>
          <StepHeader currentStepIndex={null} stepIndex={0} step={{ title: 'Disposition' }} />
          <StepCardContent className='flex flex-col space-y-2'>
            <Radio
              id='not-pep'
              checked={!isPepOrRca}
              onChange={() => setIsPepOrRca(false)}
              label={t`Is not a PEP`}
              data-track='PEP disposition dialog / Is not a PEP'
            />

            <Radio
              id='pep'
              checked={isPepOrRca}
              onChange={() => setIsPepOrRca(true)}
              label={t`Is a PEP or RCA`}
              data-track='PEP disposition dialog / Is a PEP or RCA'
            />
          </StepCardContent>
        </StepCard>

        <StepCard>
          <StepHeader currentStepIndex={null} stepIndex={1} step={{ title: 'PEP information' }} />
          <StepCardContent>
            <CreatePepForm isPepOrRca={isPepOrRca} onSubmit={handleSubmit} />
          </StepCardContent>
        </StepCard>

        <DialogFooter className='flex w-full gap-x-4'>
          <PepDispositionReset deleteLoading={deleteLoading} disabled={disabled} onDelete={handleDelete} />

          <Button
            disabled={disabled}
            className='w-full'
            variant='contained'
            onClick={() => setIsOpen(false)}
            data-track='Create PEP / Cancel'
          >
            <Trans>Cancel</Trans>
          </Button>

          <Button
            loading={createCustomPepLoading}
            disabled={disabled}
            className='w-full'
            type='submit'
            variant='contained'
            palette='primary'
            data-track='Create PEP / Confirm'
            form='pep-form'
          >
            <Trans>Confirm</Trans>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
