import { Button, IconChevronDownSmall } from '@strise/ui-components'
import { IconChevronRightSmall } from '@strise/ui-components/src/icons/general/IconChevronRightSmall'
import { type ReactNode } from 'react'

const ExpandButton = ({
  collapseLabel,
  dataTrack,
  expandLabel,
  isExpanded,
  setIsExpanded
}: {
  collapseLabel: string
  dataTrack: string
  expandLabel: string
  isExpanded: boolean
  setIsExpanded: (expanded: boolean) => void
}): ReactNode => {
  return (
    <Button
      variant='ghost'
      palette='secondary'
      className='p-0 h-auto underline hover:bg-transparent focus:bg-transparent active:bg-transparent'
      onClick={() => setIsExpanded(!isExpanded)}
      data-track={dataTrack}
      endIcon={isExpanded ? <IconChevronDownSmall /> : <IconChevronRightSmall />}
    >
      {isExpanded ? collapseLabel : expandLabel}
    </Button>
  )
}

export default ExpandButton
