import { type DivProps, type StyleProps } from '@strise/react-utils'
import { IconBusinessPerson, IconCompany, IconPerson } from '@strise/ui-components'
import type { FC, ReactNode } from 'react'
import { type EntityLikeMetaFragment, type ShareholderFragment } from '~/graphqlTypes'

const entityToIcon: Partial<
  Record<EntityLikeMetaFragment['__typename'] | ShareholderFragment['__typename'], FC<StyleProps>>
> = {
  Company: IconCompany,
  Person: IconBusinessPerson,
  PrivatePerson: IconPerson
}

const iconSizeClassNames = {
  sm: { primary: 'h-4 w-4', secondary: 'h-3 w-3  -bottom-[2px] -right-[2px]' },
  md: { primary: 'h-6 w-6', secondary: 'h-4 w-4  -bottom-1 -right-1' },
  lg: { primary: 'h-8 w-8', secondary: 'h-5 w-5  -bottom-2 -right-2' }
}

export interface EntityIconProps extends DivProps {
  entity: Pick<EntityLikeMetaFragment, '__typename'> | Pick<ShareholderFragment, '__typename'>
  size?: 'sm' | 'md' | 'lg'
}

export const EntityIcon = ({ className, entity, size = 'md', ...props }: EntityIconProps): ReactNode => {
  const Icon = entityToIcon[entity.__typename]

  if (!Icon) return null

  return (
    <div className={className} {...props}>
      <div className='relative'>
        <Icon className={iconSizeClassNames[size].primary} />
      </div>
    </div>
  )
}
