import { NationFlags } from '@strise/app-shared'
import { useContext } from '@strise/react-utils'
import { ellipsis } from '@strise/ts-utils'
import { Typography, cn } from '@strise/ui-components'
import { type ReactNode } from 'react'
import { EntityLink } from '~/components/EntityLink/EntityLink'
import { SidepanelContext } from '~/components/Sidepanel/SidepanelContext/SidepanelContext'
import ConnectOwnerLink from '~/features/Ownerships/ConnectOwnerLink'
import DefaultTag from '~/features/Ownerships/ControlCard/DefaultTag'
import OwnerControlAdditionalInformation from '~/features/Ownerships/ControlCard/OwnerControlAdditionalInformation'
import { type CompanyControlEntityFragment } from '~/graphqlTypes'
import { CustomMeta } from '~/utils/apiTable/CustomMeta'
import { useIsMergeableEntity } from '~/utils/entity'

const maxNameLength = 30

const BaseOwnerControlDetails = ({
  className,
  isBeneficialOwner,
  keyTags,
  keyTagsSimple,
  owner
}: {
  className?: string,
  isBeneficialOwner?: boolean,
  keyTags?: { label: string; value: string }[],
  keyTagsSimple?: { label: string; value: string }[],
  owner: CompanyControlEntityFragment
}): ReactNode => {
  const { above25 } = useContext(SidepanelContext)

  const isMergeable =
    useIsMergeableEntity(owner.entity) && owner.ownershipMax && owner.ownershipMax > (above25 ? 25 : 5)
  const isSlim = owner.entity.__typename === 'Company' && owner.entity.isSlim

  const trimmedName = owner.entity.name ? ellipsis(owner.entity.name, maxNameLength) : null

  return (
    <div
      className={cn(
        'flex flex-col gap-1 rounded-lg',
        { 'bg-yellow-5': isMergeable && isBeneficialOwner },
        { 'py-4': isBeneficialOwner },
        className
      )}
    >
      <div className='flex justify-between'>
        <div className='flex items-center justify-start gap-1 relative'>
          {keyTagsSimple?.map((tag, i) => <DefaultTag key={i} value={tag.value} />)}
          <EntityLink
            entity={owner.entity}
            noLink={isSlim}
            iconWrapperProps={{ size: 'sm' }}
            withIcon={isSlim}
            className='relative'
            maxLength={maxNameLength}
          >
            {isSlim && owner.entity.__typename === 'Company' && (
              <NationFlags
                className='absolute size-3.5 -bottom-0 -left-4 bg-background-paper rounded'
                countryIsoCodes={[owner.entity.primaryCountry?.kind ?? '']}
              />
            )}
            {trimmedName}
          </EntityLink>
        </div>
        <div className='flex gap-1 items-center justify-end'>
          {owner.customMeta && <CustomMeta customMeta={owner.customMeta} className='' />}
          {isMergeable && <ConnectOwnerLink entity={owner.entity} className='h-fit' />}
        </div>
      </div>
      {owner.reasons.length > 0 && <Typography>{owner.reasons.join(' & ')}</Typography>}

      {keyTags && (
        <div className='flex gap-2 flex-wrap pb-1'>
          {keyTags.map((tag) => (
            <DefaultTag key={tag.label} label={tag.label} value={tag.value} />
          ))}
        </div>
      )}
      <OwnerControlAdditionalInformation owner={owner} additionalInformation={owner.additionalInformation} />
    </div>
  )
}

export default BaseOwnerControlDetails
