import { type MessageDescriptor, i18n } from '@lingui/core'
import { Trans, defineMessage, t } from '@lingui/macro'
import { type SetStateFn, useDependencyState } from '@strise/react-utils'
import { filterNullishValues, objectEntries } from '@strise/ts-utils'
import {
  type CreateEntityDispositionInput,
  EntityDispositionKind,
  type EntityDispositionStatusKind
} from '@strise/types'
import {
  Button,
  DataTable,
  IconButton,
  IconGarbage,
  Tabs,
  TabsList,
  TabsTrigger,
  TextArea,
  Tooltip,
  Typography,
  cn,
  createColumnHelper
} from '@strise/ui-components'
import { uniq } from 'lodash-es'
import type { ReactNode } from 'react'
import { useMemo, useState } from 'react'
import { Countries } from '~/components/Countries'
import { EntityLink } from '~/components/EntityLink/EntityLink'
import { MatchInfoAnalysisTooltipContent } from '~/components/MatchInfoAnalysis/MatchInfoAnalysisTooltipContent'
import { MissingData } from '~/components/MissingData'
import { EntityKeyMetaItems } from '~/features/Ownerships/EntityKeyMetaItems'
import { DeleteCustomPepDialog } from '~/features/PepAndSanctions/DeleteCustomPepDialog'
import { PepDispositionDialogActions } from '~/features/PepAndSanctions/PepDispositionDialogActions'
import { PepInfoStatusIcon } from '~/features/PepAndSanctions/PepInfoStatusIcon'
import { extractPepType } from '~/features/PepAndSanctions/dispositionDialogUtils'
import {
  systemSuggestedFalsePepPredicate,
  systemSuggestedTruePepPredicate,
  userConfirmedFalsePepPredicate,
  userConfirmedTruePepPredicate
} from '~/features/PepAndSanctions/pepDispositionUtils'
import { type PepInfoFragment, type PersonBaseFragment, type PrivatePersonBaseFragment } from '~/graphqlTypes'
import { extractGender } from '~/utils/gender'

type Tab =
  | 'SEE_ALL'
  | 'SYSTEM_SUGGESTED_TRUE'
  | 'SYSTEM_SUGGESTED_FALSE'
  | 'USER_CONFIRMED_FALSE'
  | 'USER_CONFIRMED_TRUE'

const tabsMap: Record<Tab, { label: MessageDescriptor; predicate: (pepInfo: PepInfoFragment) => boolean }> = {
  SEE_ALL: {
    label: defineMessage({ message: 'See all' }),
    predicate: (_: PepInfoFragment) => true
  },
  SYSTEM_SUGGESTED_TRUE: {
    label: defineMessage({ message: 'Suggested true' }),
    predicate: systemSuggestedTruePepPredicate
  },
  USER_CONFIRMED_TRUE: {
    label: defineMessage({ message: 'Confirmed true' }),
    predicate: userConfirmedTruePepPredicate
  },
  SYSTEM_SUGGESTED_FALSE: {
    label: defineMessage({ message: 'Suggested false' }),
    predicate: systemSuggestedFalsePepPredicate
  },
  USER_CONFIRMED_FALSE: {
    label: defineMessage({ message: 'Confirmed false' }),
    predicate: userConfirmedFalsePepPredicate
  }
}

interface PepDispositionListProps {
  comparePepInfo: PepInfoFragment | null
  isAuditTrailOpen: boolean
  pepInfos: PepInfoFragment[]
  person: PersonBaseFragment | PrivatePersonBaseFragment
  setComparePepInfo: (pepInfo: PepInfoFragment | null) => void
  setIsAuditTrailOpen: SetStateFn<boolean>
}

const columnHelper = createColumnHelper<PepInfoFragment>()

const extractColumns = (
  person: PersonBaseFragment | PrivatePersonBaseFragment,
  setComparePepInfo: (pepInfo: PepInfoFragment | null) => void
) => {
  return [
    columnHelper.accessor((info) => info.name, {
      cell: ({ getValue, row }) => (
        <Tooltip content={t`Open comparison view`}>
          <Button
            variant='ghost'
            className='h-auto p-0 hover:bg-inherit hover:underline'
            onClick={() => setComparePepInfo(row.original)}
            data-track='Pep disposition dialog / Open comparison'
          >
            {getValue()}
          </Button>
        </Tooltip>
      ),
      header: t`Name`,
      meta: { cellClassName: 'pl-0', headerClassName: 'pl-0' }
    }),
    columnHelper.accessor((row) => row, {
      cell: ({ getValue }) => <PepInfoStatusIcon pepInfo={getValue()} />,
      header: t`Status`,
      enableHiding: false,
      meta: { cellClassName: 'align-middle' }
    }),
    columnHelper.accessor((info) => info.matchInfoAnalysis, {
      cell: ({ getValue }) => {
        const matchInfoAnalysis = getValue()

        if (!matchInfoAnalysis) return <MissingData />

        return (
          <Tooltip arrow content={<MatchInfoAnalysisTooltipContent matchInfoAnalysis={matchInfoAnalysis} />}>
            <span>{matchInfoAnalysis.result}</span>
          </Tooltip>
        )
      },
      header: t`Match %`
    }),
    columnHelper.accessor((info) => info.birthDateV2, {
      cell: ({ getValue }) => getValue() ?? <MissingData />,
      header: t`DOB`
    }),
    columnHelper.accessor((info) => info.gender, {
      cell: ({ getValue }) => extractGender(getValue()) ?? <MissingData />,
      header: t`Gender`
    }),
    columnHelper.accessor((row) => extractPepType(row), { header: t`Type`, enableHiding: false }),
    columnHelper.accessor((info) => info.countries, {
      cell: ({ getValue }) => (getValue().length ? <Countries countries={getValue()} /> : <MissingData />),
      header: t`Countries`
    }),
    columnHelper.accessor((row) => row, {
      cell: ({ getValue }) => {
        const pepInfo = getValue()

        if (!pepInfo.customMeta) return null

        return (
          <DeleteCustomPepDialog pepInfo={pepInfo} person={person}>
            <IconButton data-track='PEP Disposition / Delete custom PEP'>
              <IconGarbage size='xs' />
            </IconButton>
          </DeleteCustomPepDialog>
        )
      },
      header: '',
      id: 'deleteCustomPep'
    })
  ]
}

export const PepDispositionList = ({
  comparePepInfo,
  isAuditTrailOpen,
  pepInfos,
  person,
  setComparePepInfo,
  setIsAuditTrailOpen
}: PepDispositionListProps): ReactNode => {
  const [comment, setComment] = useState('')
  const [activeTab, setActiveTab] = useState<Tab>(() => {
    if (pepInfos.some(systemSuggestedTruePepPredicate)) {
      return 'SYSTEM_SUGGESTED_TRUE'
    }

    if (pepInfos.some(userConfirmedTruePepPredicate)) {
      return 'USER_CONFIRMED_TRUE'
    }

    if (pepInfos.some(systemSuggestedFalsePepPredicate)) {
      return 'SYSTEM_SUGGESTED_FALSE'
    }

    if (pepInfos.some(userConfirmedFalsePepPredicate)) {
      return 'USER_CONFIRMED_FALSE'
    }

    return 'SYSTEM_SUGGESTED_TRUE'
  })

  const activePredicate = tabsMap[activeTab].predicate
  const filteredRows = pepInfos.filter(activePredicate)

  const sources = uniq(filterNullishValues(pepInfos.map((info) => info.source)))

  // Reset state on tab change
  const [selectedRowsMap, setSelectedRowsMap] = useDependencyState<Record<string, boolean>>({}, [activeTab])

  // Avoid re-rendering of columns
  const columns = useMemo(() => extractColumns(person, setComparePepInfo), [])

  // If the comparison dialog is open, don't render the list dialog
  if (comparePepInfo || isAuditTrailOpen) return null

  const handleTabChange = (value: string): void => {
    setActiveTab(value as Tab)
  }

  const extractInputs = (status: EntityDispositionStatusKind): CreateEntityDispositionInput[] => {
    const selectedIds = Object.entries(selectedRowsMap)
      .filter(([_, checked]) => checked)
      .map(([id, _]) => {
        return id
      })

    return selectedIds.map((id) => {
      return {
        externalId: id,
        kind: EntityDispositionKind.Pep,
        status,
        comment
      }
    })
  }

  const handleCompleted = (): void => {
    // Switch to show all if the last result(s) are dispositioned
    setActiveTab(filteredRows.length ? activeTab : 'SEE_ALL')

    setSelectedRowsMap({})
    setComment('')
  }

  return (
    <>
      <div className={cn('flex flex-col gap-4 mt-8')}>
        <div className='flex flex-col gap-3 bg-background-paper p-4'>
          <EntityLink entity={person} withIcon noTooltip noLink />
          <EntityKeyMetaItems className='flex gap-2' entity={person} expanded itemProps={{ className: 'w-full' }} />
        </div>
        <div>
          <Typography variant='subtitle2' className='mb-2'>{t`Possible matches`}</Typography>
          <Typography
            variant='body2'
            className='text-text-secondary'
          >{t`Disposition the results in the list below or click name for more information`}</Typography>
        </div>
        <div>
          <Tabs palette='primary' value={activeTab} onValueChange={handleTabChange}>
            <TabsList className='w-full'>
              {objectEntries(tabsMap).map(([tab, tabData]) => {
                return (
                  <TabsTrigger className='w-full text-sm' key={tab} value={tab}>
                    {i18n._(tabData.label)} ({pepInfos.filter(tabData.predicate).length})
                  </TabsTrigger>
                )
              })}
            </TabsList>
          </Tabs>
          <div className='min-h-[274px]'>
            <DataTable
              className='bg-background-paper'
              headerRowClassName='hover:bg-inherit'
              getRowClassName={() => 'hover:bg-inherit'}
              headerCellClassName='py-2 text-text-secondary sticky top-0 bg-background-paper z-[1]'
              bodyCellClassName='py-2'
              wrapperClassName='overflow-auto max-h-[250px]'
              emptyStateText={t`No results`}
              columns={columns}
              data={filteredRows}
              options={{
                onRowSelectionChange: setSelectedRowsMap,
                state: {
                  rowSelection: selectedRowsMap
                },
                getRowId: (row: PepInfoFragment) => row.id
              }}
            />
            {!!sources.length && (
              <Typography variant='body2' className='mt-2 text-text-secondary'>
                {sources.length === 1 ? <Trans>Source</Trans> : <Trans>Sources</Trans>}: {sources.join(', ')}
              </Typography>
            )}
          </div>
        </div>
        <TextArea
          className='mt-8'
          placeholder={t`Comment (optional)`}
          value={comment}
          autoResize
          onChange={(event) => setComment(event.target.value)}
        />
      </div>
      <PepDispositionDialogActions
        pepInfos={pepInfos}
        extractInputs={extractInputs}
        person={person}
        onCompleted={handleCompleted}
        setIsAuditTrailOpen={setIsAuditTrailOpen}
      />
    </>
  )
}
