import { IconCrossSmall } from '../../icons/general'
import { cn } from '../../utils/className'
import { Button, type ButtonProps, IconButton } from '../Button'
import { Tooltip } from '../Tooltip'
import { Typography, type TypographyProps } from '../Typography'
import { type DivProps } from '@strise/react-utils'
import { ellipsis } from '@strise/ts-utils'
import { orderBy } from 'lodash-es'
import type { MouseEvent, ReactElement, ReactNode } from 'react'
import { cloneElement, forwardRef } from 'react'

export interface ChipProps extends Omit<ButtonProps, 'label'> {
  /** Optional delete icon element that overrides the default one. */
  deleteIcon?: ReactElement
  /** Optional props for the delete icon button. */
  deleteIconProps?: ButtonProps
  /** Optional icon element to be displayed alongside the chip label. */
  icon?: ReactElement
  /** The content of the chip. */
  label?: ReactNode
  /** Optional callback function that is called when the delete icon is clicked. Delete icon button is not displayed if undefined */
  onDelete?: (event: MouseEvent) => void
  // TODO - rename to typographyProps (might require a bigger refactor due to props spreading across the app)
  /** Props to be applied to the Typography component. */
  textProps?: TypographyProps
}

export const Chip = forwardRef<HTMLButtonElement, ChipProps>(
  (
    {
      className,
      deleteIcon: deleteIconProp,
      deleteIconProps,
      icon,
      label,
      onDelete,
      palette = 'tertiary',
      size = 'sm',
      textProps,
      variant = 'contained',
      ...props
    },
    ref
  ) => {
    const isClickable = Boolean(props.onClick)

    const handleDelete = (event: MouseEvent): void => {
      event.stopPropagation()
      if (onDelete) {
        onDelete(event)
      }
    }

    const deleteIcon = deleteIconProp ? (
      // eslint-disable-next-line @eslint-react/no-clone-element
      cloneElement(deleteIconProp, {
        onClick: handleDelete,
        ...deleteIconProps
      })
    ) : (
      <DeleteIconButton onClick={handleDelete} variant={variant} palette={palette} size={size} {...deleteIconProps} />
    )

    const content = label && (
      <Typography
        component='span'
        className={`min-w-0 truncate whitespace-nowrap ${icon ? 'ml-2' : 'ml-1'} ${onDelete ? 'mr-2' : 'mr-1'}`}
        variant={size === 'sm' ? 'aLabelSmall' : 'aLabel'}
        {...textProps}
      >
        {label}
      </Typography>
    )

    return (
      <Button
        size='sm'
        className={cn(
          'px-1',
          size === 'sm' ? 'h-6 rounded-xl' : 'h-8 rounded-2xl',
          isClickable
            ? 'cursor-pointer'
            : 'cursor-default hover:border-disabled hover:bg-disabled active:border-disabled active:bg-disabled',
          className
        )}
        ref={ref}
        asChild={!isClickable}
        startIcon={icon}
        endIcon={onDelete ? deleteIcon : undefined}
        variant={variant}
        palette={palette}
        {...props}
      >
        {isClickable ? content : <div>{content}</div>}
      </Button>
    )
  }
)
Chip.displayName = 'Chip'

const DeleteIconButton = ({ className, palette, size, variant, ...props }: ButtonProps): ReactNode => {
  return (
    <IconButton
      className={cn(
        'pointer-events-auto relative cursor-pointer overflow-hidden rounded-full border-none bg-transparent after:absolute after:inset-0 after:bg-current after:opacity-0 after:transition-opacity after:duration-200 after:ease-in after:content-[\'""\'] hover:after:opacity-20 active:after:opacity-40',
        size === 'sm' ? 'size-4' : 'size-6',
        className
      )}
      palette={palette}
      variant={variant}
      asChild
      {...props}
    >
      <div>
        <IconCrossSmall className='shrink-0' />
      </div>
    </IconButton>
  )
}

export interface StringOnlyChipProps extends Omit<ChipProps, 'label'> {
  /** The label. Overriding it to be required and only accept strings. */
  label: string
  /** Wraps the Chip in a Tooltip with the content if defined */
  tooltipContent?: string
}

interface MaxLabelLengthProps {
  /** Maximum allowed length of an individual chip label. Labels longer than this will be truncated. Must */
  maxLabelLength: number
  /** Maximum combined length of all chip labels that can be displayed. Additional chips are not displayed but counted. */
  maxTotalLabelLength: number
}

interface ChipsBaseProps {
  /** Array of chip objects, each with a label property. */
  chips: StringOnlyChipProps[]
  /** Optional props to limit the length of chip labels. */
  maxLabelLengthProps?: MaxLabelLengthProps
}

export interface ChipsProps extends ChipsBaseProps, DivProps {}

/**
 * Renders a collection of Chip components, with optional label length constraints. Chips are displayed
 * based on the provided label length properties, ensuring the total length of displayed chips does not
 * exceed the specified maximum. Additional chips beyond the allowed length are represented by a single
 * chip indicating the number of omitted chips.
 */
export const Chips = forwardRef<HTMLDivElement, ChipsProps>(
  ({ chips, className, maxLabelLengthProps, ...props }, ref): ReactNode => {
    if (!chips.length) return null

    const sortedChips = orderBy(chips, (chip) => chip.label.length)

    const { restOfChipsCount, visibleChips } = accumulateChips({
      chips: sortedChips,
      maxLabelLengthProps
    })

    return (
      <div className={cn('flex h-full flex-wrap items-center', className)} ref={ref} {...props}>
        <div className='flex flex-wrap items-center gap-1'>
          {visibleChips.map(({ tooltipContent, ...chip }, index) =>
            tooltipContent ? (
              <Tooltip content={tooltipContent} key={index}>
                <Chip {...chip} />
              </Tooltip>
            ) : (
              <Chip {...chip} key={index} />
            )
          )}
          {restOfChipsCount > 0 && (
            <Tooltip
              content={
                <div className='flex max-w-xs flex-wrap gap-1'>
                  {sortedChips.slice(visibleChips.length).map((chip, index) => (
                    <Chip {...chip} key={`${chip.label}${index}`} label={chip.label} className='w-fit' size='sm' />
                  ))}
                </div>
              }
            >
              <Chip size='sm' {...chips[0]} label={`+${restOfChipsCount}`} />
            </Tooltip>
          )}
        </div>
      </div>
    )
  }
)

Chips.displayName = 'Chips'

/**
 * Represents the state of the chip accumulation process during the reduction of chips array.
 * It keeps track of the current state of visible chips and whether the maximum allowed total length has been exceeded.
 */
interface AccumulateChipsState {
  /** Indicates whether the accumulated total length of chip labels has exceeded the maximum allowed length. */
  hasExceededLength: boolean
  /** The count of chips that are not displayed because adding them would exceed the maximum total label length. */
  restOfChipsCount: number
  /** The cumulative length of chip labels that are currently visible, including adjustments for truncation and additional widths. */
  totalLength: number
  /** An array of chips that are currently visible within the constraints of the maximum total label length. */
  visibleChips: StringOnlyChipProps[]
}

interface AccumulateChipsProps extends ChipsBaseProps {
  /** Label length padding to account for ellipsis and general chip width */
  chipAndEllipsisPadding?: number
  /** Minimum length for a chip to be meaningful to truncate */
  minimumLengthThreshold?: number
}

/**
 * Accumulates chips based on their label lengths, ensuring the total length does not exceed a specified maximum.
 * Labels are truncated as necessary to fit within the maxTotalLabelLength. The function calculates an adjusted
 * max label length based on the number of chips and the max total label length. Special consideration is given to
 * the last chip, which may be truncated further to fit within the length constraints, provided it does not go
 * below a minimum threshold.
 */
const accumulateChips = ({
  chipAndEllipsisPadding = 3,
  chips,
  maxLabelLengthProps,
  minimumLengthThreshold = 8
}: AccumulateChipsProps): AccumulateChipsState => {
  if (!maxLabelLengthProps) {
    return { visibleChips: chips, totalLength: 0, restOfChipsCount: 0, hasExceededLength: false }
  }

  // Adjust the maximum label length based on the number of chips and the total allowed length, to optimize the space available
  const adjustedMaxLabelLength =
    chips.length > 0
      ? Math.max(maxLabelLengthProps.maxLabelLength, Math.floor(maxLabelLengthProps.maxTotalLabelLength / chips.length))
      : maxLabelLengthProps.maxLabelLength

  return chips.reduce<AccumulateChipsState>(
    (chipsState, chip, index) => {
      if (chipsState.hasExceededLength) {
        return { ...chipsState, restOfChipsCount: chipsState.restOfChipsCount + 1 }
      }

      const paddedTotalLength = chipsState.totalLength + chipAndEllipsisPadding

      const isLastChip = index === chips.length - 1
      const remainingLength = maxLabelLengthProps.maxTotalLabelLength - paddedTotalLength

      // If the last chip cannot be meaningfully displayed, count it as not displayed
      if (isLastChip && remainingLength < minimumLengthThreshold) {
        return { ...chipsState, restOfChipsCount: chipsState.restOfChipsCount + 1 }
      }

      // Determine the final label length for truncation
      const finalLabelLength = isLastChip ? Math.min(remainingLength, chip.label.length) : adjustedMaxLabelLength
      const truncatedLabel = ellipsis(chip.label, finalLabelLength)
      const isTruncated = chip.label.length > finalLabelLength
      const newTotalLength = paddedTotalLength + truncatedLabel.length

      // Add the chip to visible or increase the count of not displayed chips based on the total length
      return newTotalLength <= maxLabelLengthProps.maxTotalLabelLength
        ? {
            ...chipsState,
            visibleChips: [
              ...chipsState.visibleChips,
              { ...chip, label: truncatedLabel, tooltipContent: isTruncated ? chip.label : undefined }
            ],
            totalLength: newTotalLength
          }
        : {
            ...chipsState,
            hasExceededLength: true,
            restOfChipsCount: chipsState.restOfChipsCount + 1
          }
    },
    { visibleChips: [], totalLength: 0, restOfChipsCount: 0, hasExceededLength: false }
  )
}

export default Chips
