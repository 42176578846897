import { zodResolver } from '@hookform/resolvers/zod'
import { Trans, t } from '@lingui/macro'
import {
  DatePicker,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Label,
  Radio,
  TextArea
} from '@strise/ui-components'
import { type ReactNode } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { z } from 'zod'

const getCreatePepInputSchema = (isPepOrRca: boolean) =>
  z
    .object({
      type: z.enum(['pep', 'rca']).optional(),
      pepRole: z.string().optional(),
      pepDate: z.string().optional(),
      rcaRelation: z.string().optional(),
      rcaPepName: z.string().optional(),
      rcaPepRole: z.string().optional(),
      rcaPepDate: z.string().optional(),
      comment: z.string().optional()
    })
    .refine((data) => !isPepOrRca || data.type != null, {
      message: t`Please select either PEP or RCA`,
      path: ['type']
    })

export type CreatePepInput = z.infer<ReturnType<typeof getCreatePepInputSchema>>

interface CreatePepFormProps {
  isPepOrRca: boolean
  onSubmit: SubmitHandler<CreatePepInput>
}

const formFieldStyles = {
  wrapper: 'flex items-center gap-4',
  label: 'w-[120px] shrink-0 font-medium',
  radioGroup: 'flex items-center space-x-2'
} as const

export const CreatePepForm = ({ isPepOrRca, onSubmit }: CreatePepFormProps): ReactNode => {
  const CreatePepInputSchema = getCreatePepInputSchema(isPepOrRca)

  const form = useForm<CreatePepInput>({
    resolver: zodResolver(CreatePepInputSchema),
    defaultValues: {
      type: undefined,
      pepRole: '',
      pepDate: '',
      rcaRelation: '',
      rcaPepName: '',
      rcaPepRole: '',
      rcaPepDate: '',
      comment: ''
    }
  })

  const selectedType = form.watch('type')

  const renderTypeField = () => (
    <FormField
      control={form.control}
      name='type'
      render={({ field }) => (
        <div className={formFieldStyles.wrapper}>
          <Label className={formFieldStyles.label}>
            <Trans>Type</Trans>
          </Label>
          <FormItem className='flex-1'>
            <FormControl>
              <div className={formFieldStyles.radioGroup}>
                <Radio
                  id='type-pep'
                  value='pep'
                  label={t`PEP`}
                  checked={field.value === 'pep'}
                  onChange={() => field.onChange('pep')}
                />
                <Radio
                  id='type-rca'
                  value='rca'
                  label={t`RCA`}
                  checked={field.value === 'rca'}
                  onChange={() => field.onChange('rca')}
                />
              </div>
            </FormControl>
            <FormMessage />
          </FormItem>
        </div>
      )}
    />
  )

  const renderTextField = (name: keyof CreatePepInput, label: string) => (
    <FormField
      key={name}
      control={form.control}
      name={name}
      render={({ field }) => (
        <div className={formFieldStyles.wrapper}>
          <Label className={formFieldStyles.label}>{label}</Label>
          <FormItem className='flex-1'>
            <FormControl>{name === 'comment' ? <TextArea {...field} autoResize /> : <Input {...field} />}</FormControl>
            <FormMessage />
          </FormItem>
        </div>
      )}
    />
  )

  const renderDateField = (name: keyof CreatePepInput, label: string) => (
    <FormField
      key={name}
      control={form.control}
      name={name}
      render={({ field }) => (
        <div className={formFieldStyles.wrapper}>
          <Label className={formFieldStyles.label}>{label}</Label>
          <FormItem className='flex-1'>
            <FormControl>
              <DatePicker
                buttonIcon={false}
                placeholder={t`Select date`}
                className='border h-10 px-2'
                value={field.value ? new Date(field.value) : undefined}
                onChange={(date) => field.onChange(date?.toISOString().split('T')[0] ?? '')}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        </div>
      )}
    />
  )

  return (
    <Form {...form}>
      <form id='pep-form' onSubmit={form.handleSubmit(onSubmit)} className='bg-background-paper space-y-4'>
        {isPepOrRca && renderTypeField()}

        {selectedType === 'pep' && (
          <>
            {renderTextField('pepRole', t`Role or Reason`)}
            {renderDateField('pepDate', t`Date`)}
          </>
        )}

        {selectedType === 'rca' && (
          <>
            {renderTextField('rcaRelation', t`Relation`)}
            {renderTextField('rcaPepName', t`Who is the PEP`)}
            {renderTextField('rcaPepRole', t`PEP Role or Reason`)}
            {renderDateField('rcaPepDate', t`Date`)}
          </>
        )}

        {renderTextField('comment', t`Comment`)}
      </form>
    </Form>
  )
}
