import { Trans, t } from '@lingui/macro'
import { formatDate } from '@strise/app-shared'
import { Typography } from '@strise/ui-components'
import { intervalToDuration, parseISO } from 'date-fns'
import type { ReactNode } from 'react'
import type { PepRoleFragment } from '~/graphqlTypes'
import { TestIDs } from '~/utils/testIDs'

const RoleDurationText = ({ role }: { role: PepRoleFragment }) => {
  const isPresent = !role.to

  const from = role.from ?? ''
  const to = role.to ?? ''

  if (!from && !to) return null

  const startDate = from ? formatDate(from, { day: false }) : t`Unknown`
  const endDate = isPresent ? t`Present` : formatDate(to, { day: false })
  const rolePeriod = `${startDate} ${endDate && `- ${endDate}`}`

  const parsedStartDate = from ? parseISO(from) : null
  const parsedEndDate = to ? parseISO(to) : new Date()

  const roleDuration = parsedStartDate ? intervalToDuration({ start: parsedStartDate, end: parsedEndDate }) : null

  const years = roleDuration?.years ? `${roleDuration.years} ${t`yr`}` : null
  const months = roleDuration?.months ? `${roleDuration.months} ${t`m`}` : null
  const days = roleDuration?.days ? `${roleDuration.days} ${t`d`}` : null

  const duration = [years, months, days].filter(Boolean).join(' ')

  return (
    <div className='flex flex-col items-end text-right'>
      <Typography>{rolePeriod}</Typography>
      <Typography className='text-text-secondary' variant='aLabelSmall'>
        {duration}
      </Typography>
    </div>
  )
}

export const PepRole = ({ role }: { role: PepRoleFragment }): ReactNode => {
  return (
    <div className='flex w-full items-center justify-between gap-1'>
      <div>
        <Typography variant='aLabel'>{role.title}</Typography>
        <div className='flex items-center gap-1'>
          <Typography className='text-text-secondary' variant='aLabelSmall' data-id={TestIDs.Pep.reason}>
            <Trans>PEP reason: {role.roleCategory}</Trans>
          </Typography>
        </div>
      </div>
      <RoleDurationText role={role} />
    </div>
  )
}
