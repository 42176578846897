import { useLazyQuery } from '@apollo/client/index.js'
import { t } from '@lingui/macro'
import { triggerBase64Download } from '@strise/react-utils/src/utils/file'
import { Button, Dialog, DialogContent, DialogFooter, DialogTitle, Typography } from '@strise/ui-components'
import { type ReactNode, useState } from 'react'
import CompareSection from '~/components/RegistryCheck/CompareSection'
import DocumentDownloadSection from '~/components/RegistryCheck/DocumentDownloadSection'
import REGISTRY_DOCUMENT_DOWNLOAD from '~/graphql/companies/queries/registryDocumentDownload.graphql'
import { useRegistryCheckMetaQuery } from '~/graphqlOperations'
import {
  type BaseReviewCompanyFragment,
  type RegistryCheckMetaQuery,
  type RegistryDocumentDownloadQuery
} from '~/graphqlTypes'

export type RegistryDocumentType = RegistryCheckMetaQuery['company']['registryDocuments'][number]

const RegistryCheckDialog = ({
  entity,
  setOpen
}: {
  entity: BaseReviewCompanyFragment
  setOpen: (open: boolean) => void
}): ReactNode => {
  const { data, loading } = useRegistryCheckMetaQuery({
    variables: {
      entity: entity.id
    }
  })

  const [selectedDocuments, setSelectedDocuments] = useState<RegistryDocumentType[]>([])

  const [getDocument, { loading: documentLoading }] =
    useLazyQuery<RegistryDocumentDownloadQuery>(REGISTRY_DOCUMENT_DOWNLOAD)

  return (
    <Dialog open={true} onOpenChange={setOpen}>
      <DialogContent className='w-[48rem] bg-secondary-shade-5 flex flex-col gap-3 pb-0'>
        <DialogTitle>
          <Typography variant='h4' className='pb-2'>{t`Verify Information`}</Typography>
        </DialogTitle>

        <div className='flex flex-col gap-3 overflow-y-auto px-4'>
          <CompareSection loading={loading} registryCheck={data?.company.registryCheck ?? []} />
          <DocumentDownloadSection
            registryDocuments={data?.company.registryDocuments ?? []}
            registryDocumentsLoading={loading}
            selectedDocuments={selectedDocuments}
            setSelectedDocuments={setSelectedDocuments}
          />
        </div>

        <DialogFooter className='bg-secondary-shade-5 p-4'>
          <Button
            variant='ghost'
            palette='secondary'
            onClick={() => setOpen(false)}
            data-track='Registry Check / Cancel'
          >
            {t`Cancel`}
          </Button>
          <Button
            variant='contained'
            palette='primary'
            disabled={selectedDocuments.length === 0}
            loading={documentLoading}
            onClick={async () => {
              await Promise.all(
                selectedDocuments.map(async (document) => {
                  const { data: documentData } = await getDocument({
                    variables: {
                      entity: entity.id,
                      meta: {
                        documentId: document.id,
                        companyId: document.companyId,
                        source: document.source
                      }
                    }
                  })

                  if (documentData?.company.downloadDocument) {
                    triggerBase64Download(documentData.company.downloadDocument, document.name, 'application/pdf')
                  }
                })
              )
            }}
            data-track='Registry Check / Request Document'
          >
            {t`Request Document`}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default RegistryCheckDialog
