import { t } from '@lingui/macro'
import { extractAddress } from '@strise/app-shared'
import { DataTable, createColumnHelper } from '@strise/ui-components'
import { useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { CopyButton } from '~/components/CopyButton'
import { SidepanelCard } from '~/components/Sidepanel/SidepanelCard'
import { type TabRefs } from '~/components/Sidepanel/SidepanelTabs/sidepanelTabsUtils'
import { SidepanelAmsCard } from '~/features/Ams/SidepanelAmsCard'
import { SidepanelPrivatePersonPepsAndSanctionsCard } from '~/features/PepAndSanctions/SidepanelPrivatePersonPepsAndSanctionsCard'
import { SidepanelPersonHeaderCard } from '~/features/SidepanelPersonHeaderCard'
import { SidepanelSimilarEntitiesCard } from '~/features/SimilarEntities/SidepanelSimilarEntitiesCard'
import { type SidepanelPrivatePersonFragment } from '~/graphqlTypes'
import { SidepanelTab } from '~/utils/urls'

const columnHelper = createColumnHelper<SidepanelPrivatePersonFragment>()

const extractColumns = () => [
  columnHelper.accessor((privatePerson) => privatePerson.nin, {
    id: 'personalNumber',
    header: t`Personal number`,
    cell: ({ getValue }) => {
      const nin = getValue()
      if (!nin) return '-'
      return (
        <div className='flex justify-between items-center'>
          {nin}
          <CopyButton label={t`Personal number`} value={nin} />
        </div>
      )
    }
  }),
  columnHelper.accessor((privatePerson) => privatePerson.addresses, {
    id: 'address',
    header: t`Address`,
    cell: ({ getValue }) => {
      const addresses = getValue()
      if (!addresses.length) return '-'
      return addresses.map(extractAddress).map((address, index) => <div key={index}>{address}</div>)
    }
  })
]

export const SidepanelPrivatePersonLayout = ({
  privatePerson,
  tabRefs
}: {
  privatePerson: SidepanelPrivatePersonFragment
  tabRefs: TabRefs
}) => {
  const columns = useMemo(() => extractColumns(), [privatePerson])

  const columnVisibility = {
    personalNumber: !!privatePerson.nin,
    address: !!privatePerson.addresses
  }

  return (
    <>
      <Helmet>
        <title>{privatePerson.name}</title>
      </Helmet>
      <SidepanelPersonHeaderCard ref={tabRefs[SidepanelTab.Person]} person={privatePerson} />
      <SidepanelSimilarEntitiesCard entity={privatePerson} entityEdges={privatePerson.matchingBusinessPersons.edges} />
      <SidepanelCard title={t`Person information`} loading={!privatePerson}>
        <div className='px-4'>
          <DataTable
            columns={columns}
            data={[privatePerson]}
            orientation='vertical'
            className='table-fixed w-full'
            headerCellClassName='text-text-secondary text-md !font-book px-0 w-1/3 max-w-1/3 p-3'
            bodyCellClassName='text-md font-book w-2/3 max-w-2/3 p-3'
            getRowClassName={() => 'hover:bg-white'}
            options={{
              state: {
                columnVisibility
              }
            }}
          />
        </div>
      </SidepanelCard>
      <SidepanelPrivatePersonPepsAndSanctionsCard person={privatePerson} />
      <SidepanelAmsCard entity={privatePerson} />
    </>
  )
}
