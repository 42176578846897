import type { ReactNode } from 'react'
import ReactDatePicker, { type ReactDatePickerProps } from 'react-datepicker'

export interface DatePickerProps extends Omit<ReactDatePickerProps, 'selectsRange'> {
  selectsRange?: boolean
}

/**
 * @deprecated Use DatePicker from ui-components instead
 */
export const DatePicker = (props: DatePickerProps): ReactNode => {
  return (
    <ReactDatePicker
      showPopperArrow={false}
      calendarClassName={
        props.showMonthYearPicker ? 'system-date-picker' : 'system-date-picker system-date-picker--wide'
      }
      popperPlacement='bottom-end'
      {...props}
    />
  )
}
