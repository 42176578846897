import { type DivProps } from '@strise/react-utils'
import type { ReactNode } from 'react'
import { TransformedTableSection } from '~/components/TransformedTable/TransformedTableSection'
import { TruncatedList } from '~/components/TruncatedList/TruncatedList'
import { type TransformedApiTableRow } from '~/utils/apiTable/apiTableUtils'

/**
 * @deprecated Prefer to use separate components tailored to the data you want to display.
 */
export const TransformedTableRow = ({
  className,
  context,
  itemProps,
  paginationProps,
  row,
  sectionProps,
  ...props
}: {
  context: string
  itemProps?: DivProps
  paginationProps?: DivProps
  row: TransformedApiTableRow
  sectionProps?: DivProps
} & DivProps): ReactNode => {
  if (row.hide) return null

  return (
    <div className={className} {...props}>
      <TruncatedList
        className='w-full'
        items={row.sections}
        truncateAfter={row.paginationThreshold}
        context={context}
        paginationProps={{ ...paginationProps, className: 'pl-2' }}
      >
        {(item, index, isLast) => {
          return (
            <TransformedTableSection
              rowKey={row.key}
              section={item}
              key={index}
              hideDivider={isLast}
              itemProps={itemProps}
              {...sectionProps}
            />
          )
        }}
      </TruncatedList>
    </div>
  )
}
