import { Trans } from '@lingui/macro'
import { PrivatePersonReviewStatusKind } from '@strise/types'
import { Button } from '@strise/ui-components'
import type { ReactNode } from 'react'
import { forwardRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { EntityLink } from '~/components/EntityLink/EntityLink'
import { SidepanelCard } from '~/components/Sidepanel/SidepanelCard'
import { SidepanelEntityKeyMetaDetails } from '~/components/Sidepanel/SidepanelEntityKeyMetaDetails'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { useUpdatePrivatePersonReviewStatus } from '~/features/PrivatePersons/privatePerson'
import { SidepanelGlobalEntityBanner } from '~/features/SidepanelGlobalEntityBanner'
import { type SidepanelPersonFragment, type SidepanelPrivatePersonFragment } from '~/graphqlTypes'
import { useIsMobile } from '~/utils/hooks'
import { ContentViews, SidepanelTab } from '~/utils/urls'

interface SidepanelPersonHeaderCardProps {
  person: SidepanelPersonFragment | SidepanelPrivatePersonFragment
}

export const SidepanelPersonHeaderCard = forwardRef<HTMLDivElement, SidepanelPersonHeaderCardProps>(
  ({ person }, ref): ReactNode => {
    const features = useCurrentUserFeatures()
    const isMobile = useIsMobile()
    const location = useLocation()
    const navigate = useNavigate()
    const globalv2Person = person.__typename == 'Person' && person.isGlobalPerson
    const isPrivatePerson = person.__typename == 'PrivatePerson'
    const showGlobalEntityBanner = !isMobile && globalv2Person

    const { updateReviewStatus, updateReviewStatusLoading } = useUpdatePrivatePersonReviewStatus()

    const handleAddToReview = async (): Promise<void> => {
      await updateReviewStatus(person.id, PrivatePersonReviewStatusKind.InReview)
      navigate(`/${ContentViews.Review}${location.search}`)
    }

    return (
      <>
        {showGlobalEntityBanner && <SidepanelGlobalEntityBanner v2={globalv2Person} />}
        <SidepanelCard className='relative' ref={ref} tab={SidepanelTab.Person}>
          <div className='flex flex-col gap-4 p-4'>
            <div className='flex justify-between'>
              <EntityLink
                variant='h3'
                entity={person}
                iconWrapperProps={{
                  className: 'p-2 pl-0 mr-1'
                }}
                withIcon
                noTooltip
                noLink
              />
              {isPrivatePerson && features.REVIEW && features.EXPERIMENTAL_PRIVATE_PERSON_FEATURES && (
                <Button
                  variant='contained'
                  palette='primary'
                  className='px-4'
                  onClick={handleAddToReview}
                  data-track='Private Person / Add to Review'
                  loading={updateReviewStatusLoading}
                >
                  <Trans>Add to Review</Trans>
                </Button>
              )}
            </div>
            <div>
              <SidepanelEntityKeyMetaDetails entity={person} />
            </div>
          </div>
        </SidepanelCard>
      </>
    )
  }
)
