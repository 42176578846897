import { Trans, t } from '@lingui/macro'
import { IconVerified, Tooltip, Typography } from '@strise/ui-components'
import type { ReactNode } from 'react'
import { PepDispositionMeta } from '~/features/PepAndSanctions/PepDispositionMeta'
import type { PepInfoFragment, PersonBaseFragment, PrivatePersonBaseFragment } from '~/graphqlTypes'

interface PepDispositionBadgeProps {
  pepInfo: PepInfoFragment | null
  person: PersonBaseFragment | PrivatePersonBaseFragment | null
}

const PepDispositionBadgeTooltipContent = ({ pepInfo }: { pepInfo: PepInfoFragment | null }): ReactNode => {
  if (!pepInfo) return t`No results`

  if (!pepInfo.disposition) return t`Not user confirmed yet`

  return <PepDispositionMeta disposition={pepInfo.disposition} />
}

export const PepDispositionBadge = ({ pepInfo, person }: PepDispositionBadgeProps): ReactNode => {
  if (!person) return null
  if (!pepInfo?.disposition) return null

  return (
    <Tooltip arrow content={<PepDispositionBadgeTooltipContent pepInfo={pepInfo} />}>
      <div className='inline-flex items-center gap-1 mt-2'>
        <IconVerified size='sm' />
        <Typography variant='aLabelSmallBold'>
          <Trans>Confirmed</Trans>
        </Typography>
      </div>
    </Tooltip>
  )
}
