import { Trans } from '@lingui/macro'
import { type SetStateFn } from '@strise/react-utils'
import { ButtonGroup, Typography, cn } from '@strise/ui-components'
import type { ReactNode } from 'react'

export const UPPER_THRESHOLD = 25
export const LOWER_THRESHOLD = 10

export const BeneficialOwnerThresholdToggle = ({
  className,
  setThreshold,
  threshold
}: {
  className?: string
  setThreshold: SetStateFn<number>
  threshold: number
}): ReactNode => {
  const baseProps = {
    className: 'px-5 w-8 h-6 flex justify-center'
  }

  const beneficialOwnerThresholdOptions = [
    {
      value: LOWER_THRESHOLD,
      text: `${LOWER_THRESHOLD}%`,
      buttonProps: {
        ...baseProps,
        'data-track': `Ownership & Control / Toggle beneficial owner threshold / ${LOWER_THRESHOLD}`,
        palette: threshold === LOWER_THRESHOLD ? ('primary' as const) : null
      }
    },
    {
      value: UPPER_THRESHOLD,
      text: `${UPPER_THRESHOLD}%`,
      buttonProps: {
        ...baseProps,
        'data-track': `Ownership & Control / Toggle beneficial owner threshold / ${UPPER_THRESHOLD}`,
        palette: threshold === UPPER_THRESHOLD ? ('primary' as const) : null
      }
    }
  ]

  const handleThresholdChange = (value: number): void => setThreshold(value)

  return (
    <div className={cn('flex items-center', className)}>
      <Typography className='mr-1 text-text-primary' variant='body2'>
        <Trans>Show beneficial owners above</Trans>
      </Typography>
      <ButtonGroup<number>
        options={beneficialOwnerThresholdOptions}
        value={threshold}
        onClick={handleThresholdChange}
      />
    </div>
  )
}
