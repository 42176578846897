import { Trans } from '@lingui/macro'
import { useSizeLimitedPersistentState } from '@strise/react-utils'
import { IconPenBox, Typography } from '@strise/ui-components'
import { type ReactNode, forwardRef, useEffect, useRef, useState } from 'react'
import { STORAGE_KEYS } from '~/constants'
import { FlyIcon } from '~/features/Review/FlyIcon'
import { ReviewCardAnchor } from '~/features/Review/ReviewCardAnchor'
import { ReviewCardFooter } from '~/features/Review/ReviewCardFooter'
import { ReviewCardHeader } from '~/features/Review/ReviewCardHeader'
import { ReviewCompanyCardContent } from '~/features/Review/ReviewCompanyCardContent'
import { ReviewCompanyCardSummaryContent } from '~/features/Review/ReviewCompanyCardSummaryContent'
import { ReviewCompanyStartButton } from '~/features/Review/ReviewCompanyStartButton'
import { ReviewCardContentView, type ReviewState, defaultReviewState } from '~/features/Review/reviewUtils'
import { useReviewCompany } from '~/features/Review/useReviewCompany'
import { type BaseReviewCompanyFragment, type TeamReviewSettingsV2Fragment } from '~/graphqlTypes'
import { TestIDs } from '~/utils/testIDs'

const getCardView = (
  hasExistingReview: boolean,
  isReviewOpened: boolean,
  isReviewMinimized: boolean
): ReviewCardContentView => {
  if (isReviewMinimized) {
    return ReviewCardContentView.MINIMIZED
  }

  if (isReviewOpened) {
    return ReviewCardContentView.IN_REVIEW
  }

  if (hasExistingReview) {
    return ReviewCardContentView.AFTER_REVIEW
  }

  return ReviewCardContentView.BEFORE_REVIEW
}

interface ReviewCompanyCardProps {
  baseCompany: BaseReviewCompanyFragment
  filterHeight: number | undefined
  teamId: string
  teamReviewSettings: TeamReviewSettingsV2Fragment['reviewSettingsV2']
}

export const ReviewCompanyCard = forwardRef<HTMLDivElement, ReviewCompanyCardProps>(
  ({ baseCompany, filterHeight, teamId, teamReviewSettings }, ref): ReactNode => {
    const stateKey = `${STORAGE_KEYS.reviewState2}-${baseCompany.id}-${teamId}`
    const [reviewState, setReviewState, resetReviewState] = useSizeLimitedPersistentState<ReviewState>(
      stateKey,
      defaultReviewState,
      {
        maxSize: 20,
        daysToLive: 7
      }
    )

    const existingReviews = baseCompany.reviews.edges
    const existingReview = existingReviews.length ? existingReviews[0]?.node : null

    const cardView = getCardView(!!existingReview, reviewState.opened, !!reviewState.minimized)

    const [isReviewFinished, setIsReviewFinished] = useState<boolean>(false)

    const cardAnchorRef = useRef<HTMLDivElement>(null)

    const { alerts, errorSections, fetchReviewCompany, loadingSections, reviewCompany } = useReviewCompany(
      baseCompany,
      reviewState,
      setReviewState
    )

    useEffect(() => {
      // Fire fetchReviewCompany on first render if the card is opened
      if (!reviewState.opened) return
      if (reviewState.minimized) return

      fetchReviewCompany()
    }, [])

    return (
      <div className='my-4 min-w-[700px]' ref={ref} data-id={TestIDs.Review.Card.root(baseCompany.name)}>
        <ReviewCardAnchor ref={cardAnchorRef} />
        <ReviewCardHeader
          company={baseCompany}
          filterHeight={filterHeight}
          reviewState={reviewState}
          setReviewState={setReviewState}
        />
        <div className='border-t-2 border-solid border-tertiary-main bg-background-paper py-4  min-h-[100px] flex'>
          {cardView === ReviewCardContentView.BEFORE_REVIEW && (
            <div className='px-4  self-end w-full'>
              <div className='flex gap-2 items-end justify-between'>
                <div>
                  <div className='mb-1'>
                    <FlyIcon />
                  </div>
                  <Typography className='text-text-secondary'>
                    <Trans>This entity has not been reviewed yet.</Trans>
                  </Typography>
                </div>
                <ReviewCompanyStartButton
                  fetchReviewCompany={fetchReviewCompany}
                  data-track='Review / Start Review'
                  data-id={TestIDs.Review.Card.startReviewButton}
                  className='mt-0'
                >
                  <Trans>Start Review</Trans>
                </ReviewCompanyStartButton>
              </div>
            </div>
          )}

          {cardView === ReviewCardContentView.MINIMIZED && (
            <div className='flex justify-between px-4 items-end self-end w-full'>
              <div className='flex gap-2 items-center'>
                <IconPenBox size='sm' className='text-text-secondary' />
                <Typography className='text-text-secondary'>
                  <Trans>Review in progress.</Trans>
                </Typography>
              </div>

              <ReviewCompanyStartButton
                fetchReviewCompany={fetchReviewCompany}
                data-track='Review / Start Review'
                data-id={TestIDs.Review.Card.startReviewButton}
                className='mt-0'
              >
                <Trans>Continue Review</Trans>
              </ReviewCompanyStartButton>
            </div>
          )}

          {cardView === ReviewCardContentView.IN_REVIEW && (
            <ReviewCompanyCardContent
              baseCompany={baseCompany}
              cardAnchorRef={cardAnchorRef}
              reviewCompanyData={reviewCompany}
              loadingSections={loadingSections}
              errorSections={errorSections}
              teamReviewSettings={teamReviewSettings}
              reviewState={reviewState}
              setReviewState={setReviewState}
              resetReviewState={resetReviewState}
              setIsReviewFinished={setIsReviewFinished}
              alerts={alerts}
            />
          )}

          {cardView === ReviewCardContentView.AFTER_REVIEW && (
            <ReviewCompanyCardSummaryContent review={existingReview ?? null} fetchReviewCompany={fetchReviewCompany} />
          )}
        </div>
        <ReviewCardFooter company={baseCompany} isReviewFinished={isReviewFinished} />
      </div>
    )
  }
)
