import { NationFlag } from '@strise/app-shared'
import type { StyleProps } from '@strise/react-utils'
import { isInObject } from '@strise/ts-utils'
import { type IconComponent, IconMap } from '@strise/ui-components'
import { orderBy } from 'lodash-es'
import type { ReactNode } from 'react'
import { forwardRef, useMemo } from 'react'
import {
  type BaseEntityLikeFragment,
  type CustomMetaFragment,
  type TableFragment,
  type TableRowFragment,
  type TableRowValueFragment
} from '~/graphqlTypes'
import { TableLabelContent, TableValueContent } from '~/utils/apiTable/TableValueContent'
import {
  type TransformedApiTable,
  type TransformedApiTableItem,
  type TransformedApiTableRow,
  type TransformedApiTableSection,
  type handleEditRowFn,
  mkTableValueLabel
} from '~/utils/apiTable/apiTableUtils'
import { copyString } from '~/utils/hooks'

export const extractIcon = (iconId: string | null | undefined): IconComponent | undefined => {
  if (!iconId) return

  if (iconId.startsWith('Flag')) {
    // @ts-expect-error - Returns Image instead of Icon
    return forwardRef<HTMLImageElement, StyleProps>(
      (props, ref): ReactNode => <NationFlag countryIsoCode={iconId.slice(-2)} ref={ref} {...props} />
    )
  }

  const iconKey = `Icon${iconId}`

  if (!isInObject(IconMap, iconKey)) return

  return IconMap[iconKey]
}

const extractCustomMeta = (value: TableRowValueFragment): CustomMetaFragment | null | undefined => {
  switch (value.__typename) {
    case 'TableRowValueRoleWithEntity': {
      return orderBy(value.roleMetas, (roleMeta) => roleMeta.customMeta?.lastModifiedAt, 'desc')
        .map((roleMeta) => roleMeta.customMeta)
        .at(0)
    }
    case 'TableRowValueOwnership': {
      return value.customMeta
    }
    case 'TableRowValueRole': {
      return value.roleMeta.customMeta
    }
    default: {
      return null
    }
  }
}

const extractTableValues = (
  rowKey: string,
  entity: BaseEntityLikeFragment,
  values: TableRowValueFragment[],
  editMode?: boolean,
  handleEdit?: handleEditRowFn
): TransformedApiTableItem[] => {
  return values.map((value) => {
    // Make sure this is memoized
    const IconComponent = extractIcon(value.iconId)
    const content = <TableValueContent entity={entity} value={value} editMode={editMode} handleEdit={handleEdit} />
    const label = mkTableValueLabel(value)

    return {
      href: value.link,
      content,
      label,
      handleCopy: value.copyValue ? copyString(value.copyValue) : undefined,
      dataTrack: `${entity.__typename} / ${rowKey} copied`,
      Icon: IconComponent,
      tooltipContent: value.tooltip,
      hideBorder: 'detailedRoles' in value && value.detailedRoles,
      customMeta: extractCustomMeta(value),
      containerClassName: 'px-2 py-1'
    }
  })
}

const extractTableRows = (
  entity: BaseEntityLikeFragment,
  rows: TableRowFragment[],
  editMode?: boolean,
  handleEdit?: handleEditRowFn
): TransformedApiTableRow[] => {
  return rows.map((row) => {
    return {
      label: <TableLabelContent label={row.label} handleEdit={handleEdit} editMode={editMode} />,
      key: row.key,
      kind: row.kind,
      showEmpty: true,
      paginationThreshold: row.paginationThreshold,
      sections: extractTableRowSections(row, entity, editMode, handleEdit)
    }
  })
}

const extractTableRowSections = (
  row: TableRowFragment,
  entity: BaseEntityLikeFragment,
  editMode?: boolean,
  handleEdit?: handleEditRowFn
): TransformedApiTableSection[] => {
  return row.sections.map((rowSection) => {
    const items = extractTableValues(row.key, entity, rowSection.values, editMode, handleEdit)

    return {
      // Important to do the check here and not inside the component, as we need to know if we should render or not
      label: rowSection.label ? <TableLabelContent label={rowSection.label} /> : null,
      inlineLabel: rowSection.values[0]?.__typename === 'TableRowValueRelatedEntity',
      items,
      paginationThreshold: rowSection.paginationThreshold
    }
  })
}

const extractTransformedTable = (
  entity: BaseEntityLikeFragment,
  table: TableFragment,
  editMode?: boolean,
  handleEdit?: handleEditRowFn
): TransformedApiTable => {
  return {
    title: table.title,
    description: table.description,
    rows: extractTableRows(entity, table.rows, editMode, handleEdit),
    dataSources: table.dataSources,
    lastModifiedAt: table.lastModifiedAt ? new Date(table.lastModifiedAt) : null
  }
}

/**
 * @deprecated Prefer to use separate components tailored to the data you want to display.
 */
export const useTransformedTable = (
  entity: BaseEntityLikeFragment,
  table: TableFragment | null | undefined,
  editMode?: boolean,
  handleEdit?: handleEditRowFn
): TransformedApiTable | null => {
  return useMemo(() => {
    if (!table) return null

    return extractTransformedTable(entity, table, editMode, handleEdit)
  }, [JSON.stringify(entity), JSON.stringify(table), editMode])
}
